<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Pending Service Requests</h3>
    </div>
  </div>
</div>

<div *ngIf="requestPartialGetDTOs == undefined" class="d-flex justify-content-center">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div *ngIf="requestPartialGetDTOs != undefined">
  <div class="row">
    <div class="col-12">
      <div class="bg-light p-4 pt-4 pb-4 mb-5 rounded">
        <input class="form-control" id="myInput" type="text" placeholder="Search..">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      <div class="table-wrapper">
        <table id="myTable" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Date</th>
              <th scope="col">User Name</th>
              <th scope="col">User Email</th>
              <!-- <th scope="col">AOI</th> -->
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let requestPartialGetDTO of requestPartialGetDTOs; index as index">
              <tr>
                <th scope="row">{{index+1}}</th>
                <td>{{requestPartialGetDTO.requestDate | date: 'd/M/y, h:mm a'}}</td>
                <td>{{requestPartialGetDTO.user?.person![0].name}} {{requestPartialGetDTO.user?.person![0].surname}}</td>
                <td>{{requestPartialGetDTO.user?.person![0].email}}</td>
                <!-- <td>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item"
                      *ngFor="let requestPartialGetDTORequestedService of requestPartialGetDTO.requestedServices; index as indexj">
                      {{requestPartialGetDTORequestedService.waterBody?.nameText}}</li>
                  </ul>
                </td> -->
                <td><button type="button" class="btn btn-primary"
                    (click)="onClickManageRequest(requestPartialGetDTO.idRequest)">Manage</button></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>