import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyAlertConfigurationDetailsModalComponent } from '../modals/my-alert-configuration-details-modal/my-alert-configuration-details-modal.component';
import { KeycloakService } from 'keycloak-angular';
import { AlertConfigPartialGetDTO, AlertConfigurationAPIsService } from 'src/app/services/restapiservices';
import { AlertConfigCompleteGetDTO } from 'src/app/services/restapiservices';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-my-alert-configurations-component',
  templateUrl: './my-alert-configurations-component.component.html',
  styleUrls: ['./my-alert-configurations-component.component.scss']
})
export class MyAlertConfigurationsComponentComponent implements OnInit {

  alertConfigPartialGetDTOs!: AlertConfigPartialGetDTO[];
  public keycloakProfile: KeycloakProfile = {};
  token: string = '';

  constructor(private keycloakService: KeycloakService, private alertConfigurationAPIsService: AlertConfigurationAPIsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.keycloakService.getToken().then(token => {
      this.token = token;
      this.alertConfigurationAPIsService.getRequestedServicePartialByUserApiV1AlertConfigByuserPartialGet(token).subscribe((data: AlertConfigCompleteGetDTO[]) => {
        this.alertConfigPartialGetDTOs = data.sort((a, b) => new Date(b.dateRequest!).getTime() - new Date(a.dateRequest!).getTime())
      })
    });
  }

  openModal(idAlertConfig: number | undefined) {

    if (idAlertConfig == undefined) {
      alert("Undefined...")
      return;
    }

    console.log("id    " + idAlertConfig)

    const modalRef = this.modalService.open(MyAlertConfigurationDetailsModalComponent);
    modalRef.componentInstance.idAlertConfig = idAlertConfig;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  onClickDelete(idAlertConfig: number | undefined) {
    if (idAlertConfig) {
      if(confirm("Are you sure?")) {
        this.alertConfigurationAPIsService.deleteAlertConfigByUserAndIdAlertConfigApiV1AlertConfigByuserIdAlertConfigDelete(idAlertConfig, this.token).subscribe(data => {
          console.log(data)
          this.alertConfigPartialGetDTOs = this.alertConfigPartialGetDTOs.filter(function(item) { 
            return item.idAlertConfig !== idAlertConfig;  
         });
        })
      }
    }
  }


}
