import { Component, OnInit, OnDestroy, enableProdMode } from '@angular/core';
// import { NgcCookieConsentService, NgcInitializingEvent, NgcInitializationErrorEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent  } from 'ngx-cookieconsent';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs';
import { faCircleInfo, faArrowRight, faCog, faUserCircle, faUser,
         faExclamationTriangle, faShareSquare, faSignIn, faSignOut,
         faTh, faThLarge, faFileText, faTimes, faTrash, faSearch,
         faEye, faPlus, faExternalLink, faExpand, faFile, faFilePdf, faSquarePollVertical, faFileLines,
         faCommenting, faCommentDots, faCommentAlt, faInfo, faInfoCircle,
         faTachometer, faDatabase, faBook, faMap, faGlobe, faFolderOpen, faSliders,
         faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;

  constructor(private readonly keycloak: KeycloakService, private ccService: NgcCookieConsentService) {}
  // constructor(private ccService: NgcCookieConsentService){}
  title = 'service-management-portal';
  faCircleInfo = faCircleInfo;
  faArrowRight = faArrowRight;
  faCog = faCog;
  faFileLines = faFileLines;
  faSquarePollVertical = faSquarePollVertical;
  faUserCircle = faUserCircle;
  faUser = faUser;
  faExclamationTriangle = faExclamationTriangle;
  faSliders = faSliders;
  faShareSquare = faShareSquare;
  faSignIn = faSignIn;
  faSignOut = faSignOut;
  faTh = faTh;
  faThLarge = faThLarge;
  faFileText = faFileText;
  faFilePdf = faFilePdf;
  faTimes = faTimes;
  faTrash = faTrash;
  faSearch = faSearch;
  faEye = faEye;
  faPlus = faPlus;
  faExternalLink = faExternalLink;
  faExpand = faExpand;
  faFile = faFile;
  faTachometer = faTachometer;
  faDatabase = faDatabase;
  faBook = faBook;
  faMap = faMap;
  faGlobe = faGlobe;
  faFolderOpen = faFolderOpen;
  faCommenting = faCommenting;
  faCommentDots = faCommentDots;
  faCommentAlt = faCommentAlt;
  faInfo = faInfo;
  faInfoCircle = faInfoCircle;
  faBullhorn = faBullhorn;
  
  public async ngOnInit() {
    enableProdMode();

    this.isLoggedIn = await this.keycloak.isLoggedIn();

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
    }
  }

  public login() {
    this.keycloak.login();
  }

  public logout() {
    this.keycloak.logout();
  }
}


// export class AppComponent implements OnInit, OnDestroy {
//   title = 'service-management-portal';

//   //keep refs to subscriptions to be able to unsubscribe later
//   // private popupOpenSubscription!: Subscription;
//   // private popupCloseSubscription!: Subscription;
//   // private initializingSubscription!: Subscription;
//   // private initializedSubscription!: Subscription;
//   // private initializationErrorSubscription!: Subscription;
//   // private statusChangeSubscription!: Subscription;
//   // private revokeChoiceSubscription!: Subscription;
//   // private noCookieLawSubscription!: Subscription;

//   constructor(private ccService: NgcCookieConsentService){}

//   // ngOnInit() {
//   //   // subscribe to cookieconsent observables to react to main events
//   //   this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
//   //     () => {
//   //       // you can use this.ccService.getConfig() to do stuff...
//   //     });

//   //   this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
//   //     () => {
//   //       // you can use this.ccService.getConfig() to do stuff...
//   //     });

//   //   this.initializingSubscription = this.ccService.initializing$.subscribe(
//   //     (event: NgcInitializingEvent) => {
//   //       // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
//   //       console.log(`initializing: ${JSON.stringify(event)}`);
//   //     });
    
//   //   this.initializedSubscription = this.ccService.initialized$.subscribe(
//   //     () => {
//   //       // the cookieconsent has been successfully initialized.
//   //       // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
//   //       console.log(`initialized: ${JSON.stringify(event)}`);
//   //     });

//   //   this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
//   //     (event: NgcInitializationErrorEvent) => {
//   //       // the cookieconsent has failed to initialize... 
//   //       console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
//   //     });

//   //   this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
//   //     (event: NgcStatusChangeEvent) => {
//   //       // you can use this.ccService.getConfig() to do stuff...
//   //     });

//   //   this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
//   //     () => {
//   //       // you can use this.ccService.getConfig() to do stuff...
//   //     });

//   //     this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
//   //     (event: NgcNoCookieLawEvent) => {
//   //       // you can use this.ccService.getConfig() to do stuff...
//   //     });
//   }

// //   ngOnDestroy() {
// //     // unsubscribe to cookieconsent observables to prevent memory leaks
// //     this.popupOpenSubscription.unsubscribe();
// //     this.popupCloseSubscription.unsubscribe();
// //     this.initializingSubscription.unsubscribe();
// //     this.initializedSubscription.unsubscribe();
// //     this.initializationErrorSubscription.unsubscribe();
// //     this.statusChangeSubscription.unsubscribe();
// //     this.revokeChoiceSubscription.unsubscribe();
// //     this.noCookieLawSubscription.unsubscribe();
// //   }
// // }