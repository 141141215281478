import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { ListenOptions } from 'net';
import { UserAPIsService, UserInfoKeycloakAccessDTO, UserInfoKeycloakDTO } from 'src/app/services/restapiservices';
@Component({
  selector: 'app-user-pending-request-list-modal',
  templateUrl: './user-pending-request-list-modal.component.html',
  styleUrls: ['./user-pending-request-list-modal.component.scss']
})
export class UserPendingRequestListModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private keycloakService: KeycloakService, private userAPIsService: UserAPIsService, private router: Router) { }

  token: string | undefined;
  userInfoKeycloakDTOs: UserInfoKeycloakDTO[] | undefined;

  ngOnInit(): void {
    this.keycloakService.getToken().then(token => {
      this.token = token
      this.keycloakService.updateToken().then(token => {
        console.log(token)
      })
      this.userAPIsService.getAllUserFromKeycloakApiV1UserKeycloakWithPendingRequestGet(token).subscribe(data => {
        this.userInfoKeycloakDTOs = data;
      })
    })
  }

  closeModal() {
    this.activeModal.dismiss()
    this.activeModal.close('Modal Closed');
  }

  onClickManagePendingRequestOfUser(idUser: any) {
    console.log(idUser)
    this.closeModal()
    this.router.navigateByUrl('administration-manage-service-request-of-user/' + idUser)

  }
}
