<div class="row">
    <div class="col-12 col-md-8 mx-auto mt-0 mb-5 text-center">
      <img class="img-big" src="../../../assets/img/geoss/geoss-logo.png" alt="Geoss">
      <p class="lead">
        GEOSS is a set of coordinated, independent Earth observation, information and processing systems that interact and provide access to diverse information for a broad range of users in both public and private sectors.
        This ‘system of systems’, through its GEOSS Platform, proactively links together existing and planned observing systems around the world and support the need for the development of new systems where gaps currently exist. It will promote common technical standards so that data from the thousands of different instruments can be combined into coherent data sets.
        The WQeMS Platform has been federated with the GEOSS Platform, so that all the open datasets generated by the platform are made available on the GEOSS ecosystem. Click on the button to reach the Portal and visualize WQeMS data.      </p>
      <p>
        <a target="_blank" href="https://www.geoportal.org/?m:activeLayerTileId=osm&f:dataSource=dab" class="btn btn-outline-primary my-2">
          Go to GEOSS Portal <fa-icon icon="arrow-right"></fa-icon>
        </a>
      </p>
    </div>
  </div>
  