<div class="modal-content">
  <div *ngIf="alertConfigPartialGetDTOs == undefined" class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div class="modal-body">
    <div class="table-wrapper">
      <table *ngIf="alertConfigPartialGetDTOs != undefined" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Creation Date</th>
            <th scope="col">Details</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let alertConfigPartialGetDTO of alertConfigPartialGetDTOs; index as index">
            <tr>
              <th scope="row">{{index+1}}</th>
              <td>{{alertConfigPartialGetDTO.title}}</td>
              <td>{{alertConfigPartialGetDTO.dateRequest | date:'d/M/y, h:mm a'}}</td>
              <td class="text-center">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong"
                  (click)="openModal(alertConfigPartialGetDTO.idAlertConfig)">
                  <fa-icon icon="search"></fa-icon> Details
                </button>
              </td>
              <td class="text-center">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-danger"
                      (click)="onClickDelete(alertConfigPartialGetDTO.idAlertConfig)">
                      <fa-icon icon="trash"></fa-icon> Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

  </div>
</div>