  <div class="row">
    <div class="col-12">
      <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
        <h3>My Alerts</h3>
        <p class="text-center lead mt-4 mb-4">
          On this page you can view the list of alerts generated by the platform based on your configurations.
        </p>
        <p class="text-center">
          <a class="btn btn-lg btn-secondary" href="alert-configuration" role="button">
            Create a new alert configuration <fa-icon icon="arrow-right"></fa-icon>
          </a>
        </p>
      </div>
    </div>
  </div>
  

  <div class="row">
    <div class="col-12">
        <div class="modal-content">
            <div *ngIf="alertGetDTOs == undefined" class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

            
          
            <div class="modal-body" *ngIf="alertGetDTOs != undefined">
                <input
                mdbInput
                placeholder="Search"
                type="text"
                class="form-control"
                id="search-input"
                (input)="search()"/>
                <hr>
              <div class="table-wrapper">
                <table #table id="table" *ngIf="alertGetDTOs != undefined" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <!-- <th scope="col">#</th> -->
                      <th scope="col">Date</th>
                      <th scope="col">Water Body</th>
                      <th scope="col">Caused By</th>
                      <th scope="col">Reasons</th>
                      <!-- <th scope="col">Alert Config</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let alertGetDTO of alertGetDTOs; index as index">
                      <tr>
                        <!-- <th scope="row">{{index+1}}</th> -->
                        <td>{{alertGetDTO.date | date:'d/M/y, h:mm a'}}</td>
                        <td>{{alertGetDTO.alertConfig?.waterBody?.nameText}}</td>
                        <td *ngIf="alertGetDTO.caused == 'Data Product'; else crowd">
                          EO Measurement
                        </td>
                        <ng-template #crowd>
                          <td>Crowdsourcing</td>
                        </ng-template>
                        <td *ngIf="alertGetDTO.reasons != undefined">
                            <div *ngFor="let reason of alertGetDTO.reasons">
                              <p *ngIf="reason.alertConditionMaxValue != undefined && reason.alertConditionMinValue != undefined">The condition you set has happened. The measured value of {{reason.nameMeasureType}} is {{reason.valueMeasure}} {{reason.unitOfMeasure}} less than {{reason.alertConditionMaxValue}} {{reason.unitOfMeasure}} and greater than {{reason.alertConditionMinValue}} {{reason.unitOfMeasure}}.</p>
                              <p *ngIf="reason.alertConditionMaxValue != undefined && reason.alertConditionMinValue == undefined">The condition you set has happened. The measured value of {{reason.nameMeasureType}} is {{reason.valueMeasure}} {{reason.unitOfMeasure}} less than {{reason.alertConditionMaxValue}} {{reason.unitOfMeasure}}.</p>
                              <p *ngIf="reason.alertConditionMaxValue == undefined && reason.alertConditionMinValue != undefined">The condition you set has happened. The measured value of {{reason.nameMeasureType}} is {{reason.valueMeasure}} {{reason.unitOfMeasure}} greater than {{reason.alertConditionMinValue}} {{reason.unitOfMeasure}}.</p>

                            </div>
                        </td>
                        <td *ngIf="alertGetDTO.reasons == undefined">{{alertGetDTO.reasonsText}}</td>
                        <!-- <td><button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong"
                            (click)="openModal(alertGetDTO.alertConfig?.idAlertConfig)">
                            <fa-icon icon="search"></fa-icon> Details
                          </button></td> -->
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
          
            </div>
          </div>
    </div>
  </div>