<header>
  <app-headers></app-headers>
</header>

<div id="main-content" class="container">

    <div id="content">
      <router-outlet></router-outlet>
    </div>

</div>

<footer>
  <div class="footer mt-auto">
    <app-footers></app-footers>
  </div>
</footer>

