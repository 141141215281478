import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
  providers: [NgbCarouselConfig]
})
export class TrainingComponent implements OnInit {

  constructor(config: NgbCarouselConfig) {
    config.interval = 0;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationIndicators = true;
  }

  images = [1].map((n) => `../../assets/img/training_shot_${n}.png`);
  

  ngOnInit(): void {
  }
}
