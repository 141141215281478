import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from 'src/app/components/modals/info-modal/info-modal.component';
import { InfoModalsService } from 'src/app/services/interface/info-modals.service';

@Component({
  selector: 'app-account-dev',
  templateUrl: './account-dev.component.html',
  styleUrls: ['./account-dev.component.css'],
  providers: [NgbCarouselConfig]
})
export class AccountDevComponent implements OnInit {

  constructor(config: NgbCarouselConfig, public infoModalService: InfoModalsService) {
    config.interval = 0;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationIndicators = true;
  }


  images = [1, 2, 3].map((n) => `../../assets/img/catalogue_shot_${n}.png`);

  ngOnInit(): void {
  }
  

  
}
