<h2>Cookie Policy</h2>
<br>
<p>
    This Cookie Policy is an integral part of the <a href="privacy-policy">Privacy Policy</a> available here.
</p>
<br>
<div class="cookies-section">
    <h4>What are cookies?</h4>
    <p>
        Cookies are little text files that websites visited by a user record on your computer or mobile device and which are then 
        retransmitted to those same sites on the user's next visit. 
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#whatAreCookies" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="whatAreCookies">
        <div class="card card-body">
            Thanks to cookies, a website can remember a user’s actions and preference (for example, login data, their chosen language, font size, 
            other display settings, etc.) so that they do not need be chosen again the next time the user visits this website again or one of its other pages.<br><br>
            Find out more by reading the cookies section of the Data Protection Authority's <a href="https://www.garanteprivacy.it/web/garante-privacy-en/home_en" target="_blank">website</a>
        </div>
    </div>
</div>
<div class="cookies-section">
    <h4>What cookies does the website use?</h4>
    <p>
        Session cookies, and other third-party cookies or analytics tools.
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#whatCookies" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="whatCookies">
        <div class="card card-body">
            <ul>
                <li>Session cookies, also known as "technical navigation cookies", are cookies that are strictly necessary for the operation of the website 
                    or to let it use the content and services requested.</li>
            </ul>
            <br>
            All cookies can be deselected apart from third-party cookies, for which you will have to directly refer to relevant (de)selection procedures for 
            these cookies as specified in the following link: <a href="chrome://settings/cookies">chrome://settings/cookies</a> 
        </div>
    </div>
</div>
<div class="cookies-section">
    <h4>What cookies are present on the website?</h4>
    <p>
        Engineering’s own session cookies. The data collected does not allow for the personal identification of the user as this data is anonymised. 
        The data collected does not permit the identification of users and is not crosschecked with other information relating to the same individual. 
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#whatCookiesOn" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="whatCookiesOn">
        <div class="card card-body">
            Below is a detailed list of the cookies sent by Engineering through the WQeMS Web Portal:<br><br>
            <table class="cookies-table">
                <tbody>
                    <tr style="height: 43px;">
                        <td style="height: 43px;"><strong>Type of cookie and owner</strong></td>
                        <td style="height: 43px;"><strong>Technical name of cookie</strong>&nbsp;</td>
                        <td style="height: 43px;"><strong>Function and purpose</strong></td>
                        <td style="height: 43px;"><strong>Duration</strong></td>
                    </tr>
                    <tr style="height: 43px;">
                        <td style="height: 43px;">Session Engineering</td>
                        <td style="height: 43px;">KEYCLOAK_IDENTITY</td>
                        <td style="height: 43px;">User login &ndash; keep the user logged in through various sessions</td>
                        <td style="height: 43px;">Length of the session</td>
                    </tr>
                    <tr style="height: 43px;">
                        <td style="height: 43px;">Session Engineering</td>
                        <td style="height: 43px;">KEYCLOAK_IDENTITY_LEGACY&nbsp;</td>
                        <td style="height: 43px;">User login &ndash; keep the user logged in through various sessions&nbsp;</td>
                        <td style="height: 43px;">Length of the session&nbsp;</td>
                    </tr>
                    <tr style="height: 43px;">
                        <td style="height: 43px;">Session Engineering</td>
                        <td style="height: 43px;">KEYCLOAK_SESSION_LEGACY</td>
                        <td style="height: 43px;">User login &ndash; keep the user logged in through various sessions&nbsp;</td>
                        <td style="height: 43px;">Length of the session&nbsp;</td>
                    </tr>
                    <tr style="height: 43px;">
                        <td style="height: 43px;">Session Engineering</td>
                        <td style="height: 43px;">KEYCLOAK_SESSION</td>
                        <td style="height: 43px;">User login &ndash; keep the user logged in through various sessions&nbsp;</td>
                        <td style="height: 43px;">Length of the session</td>
                    </tr>
                    <tr style="height: 43px;">
                        <td style="height: 43px;">Session Engineering</td>
                        <td style="height: 43px;">AUTH_SESSION_ID_LEGACY</td>
                        <td style="height: 43px;">User login &ndash; keep the user logged in through various sessions&nbsp;</td>
                        <td style="height: 43px;">Length of the session</td>
                    </tr>
                    <tr style="height: 43px;">
                        <td style="height: 43px;">Session Engineering</td>
                        <td style="height: 43px;">AUTH_SESSION_ID</td>
                        <td style="height: 43px;">User login &ndash; keep the user logged in through various sessions&nbsp;</td>
                        <td style="height: 43px;">Length of the session&nbsp;</td>
                    </tr>
                    <tr style="height: 43.5px;">
                        <td style="height: 43.5px;">Session Engineering</td>
                        <td style="height: 43.5px;">KEYCLOAK_REMEMBER_ME</td>
                        <td style="height: 43.5px;">User login &ndash; keep the user logged in through various sessions</td>
                        <td style="height: 43.5px;">Length of the session&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="cookies-section">
    <h4>Cookie settings</h4>
    <p>
        Session cookies can be blocked. However, not authorising certain cookies may prevent you from using the website.
        <br><br>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#cookieSettings" aria-expanded="false" aria-controls="collapseExample">
        READ MORE
        </button>
    </p>
    <div class="collapse" id="cookieSettings">
        <div class="card card-body">
            You can (partially or fully) block or delete session and preferences cookies via specific features in your browser. However, we have 
            to inform you that not authorising session cookies may make it impossible for you to access the website, view its content and use its services. 
            Prohibiting preferences cookies may result in some services or certain features of the website not being available or not functioning properly 
            and you may have to change or manually enter information or preference every time you visit the website.<br><br>
            The choices made regarding cookies will also be recorded via a special cookie. However, this cookie may not function correctly under certain 
            circumstances: in this case, we recommended deleting any unwanted cookies and also preventing their use in your browser’s settings.
        </div>
    </div>
</div>