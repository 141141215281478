import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {

  public isAdministrator: boolean = false;
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;

  constructor(private readonly keycloakService: KeycloakService) {}

  public async ngOnInit() {
    console.log("On init")
    let roles = this.keycloakService.getUserRoles();
    console.log(roles);
    if (roles != undefined && roles.includes("ROLE_ADMINISTRATOR")) {
      this.isAdministrator = true;
      // console.log(this.isAdministrator)
    } 
    this.isLoggedIn = await this.keycloakService.isLoggedIn();

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloakService.loadUserProfile();
    }
  }
    // this.keycloakService.getToken().then((data: any) => {
    //   console.log(data);
    //   this.isLoggedIn = data;
    // });
    // if (this.isLoggedIn) {
    //   this.keycloakService.loadUserProfile().then((data: any) => {
    //     console.log(data);
    //     this.userProfile = data;
    //   });
    // }

  
  onClickSignOut() {
    this.keycloakService.logout(location.origin)
  }

  onClickLogin() {
    this.keycloakService.login({redirectUri: window.location.origin})
  }

}
