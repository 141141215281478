import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FootersComponent } from './components/footers/footers.component';
import { HeadersComponent } from './components/headers/headers.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { APP_INITIALIZER } from '@angular/core';
import { AlertAPIsService, RequestAPIsService, RequestedReportAPIsService, RequestedServiceAPIsService, SocialAlertTypeAPIsService, UserAPIsService, WaterBodyAPIsService } from './services/restapiservices';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { BASE_PATH } from './services/restapiservices';
import { RequestServiceComponent } from './pages/request-service/request-service.component';
import { AlertConfigurationComponent } from './pages/alert-configuration/alert-configuration.component';
import { AlertConfigurationAPIsService } from './services/restapiservices';
import { FormsModule } from '@angular/forms';
import { RequestReportComponent } from './pages/request-report/request-report.component';
import { CommonModule } from '@angular/common';
import { AdministrationDashboardComponent } from './pages/administration-dashboard/administration-dashboard.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AboutCopernicusServicesComponent } from './pages/about-copernicus-services/about-copernicus-services.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { MyRequestedServicesComponent } from './pages/my-requested-services/my-requested-services.component';
import { MyAlertConfigurationsComponent } from './pages/my-alert-configurations/my-alert-configurations.component';
import { UserHomeComponent } from './pages/user-home/user-home.component';
import { MyAlertConfigurationDetailsModalComponent } from './components/modals/my-alert-configuration-details-modal/my-alert-configuration-details-modal.component';
import { MyAlertConfigurationsComponentComponent } from './components/my-alert-configurations-component/my-alert-configurations-component.component';
import { MyRequestedServiceDetailsModalComponent } from './components/modals/my-requested-service-details-modal/my-requested-service-details-modal.component';
import { MyRequestedServicesComponentComponent } from './components/my-requested-services-component/my-requested-services-component.component';
import { UserPendingRequestListModalComponent } from './components/modals/user-pending-request-list-modal/user-pending-request-list-modal.component';
import { AdministrationRequestsListComponent } from './pages/administration-requests-list/administration-requests-list.component';
import { AdministrationRequestComponent } from './pages/administration-request/administration-request.component';
import { AdministrationRequestedServiceComponent } from './pages/administration-requested-service/administration-requested-service.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faCircleInfo, faArrowRight, faCog, faUserCircle, faUser,
  faExclamationTriangle, faSliders, faShareSquare, faSignIn, faSignOut,
  faTh, faThLarge, faFileText, faTimes, faTrash, faSearch,
  faEye, faPlus, faExternalLink, faExpand, faFile,
  faCommenting, faCommentDots, faCommentAlt, faInfo, faInfoCircle,
  faTachometer, faDatabase, faBook, faMap, faGlobe, faFolderOpen,
  faBullhorn
} from '@fortawesome/free-solid-svg-icons';
import { CatalogueComponent } from './pages/catalogue/catalogue.component';
import { DataAccessComponent } from './pages/data-access/data-access.component';
import { TrainingComponent } from './pages/training/training.component';
import { MapAndDataNavigatorComponent } from './pages/map-and-data-navigator/map-and-data-navigator.component';
import { WebServicesComponent } from './pages/web-services/web-services.component';
import { FtpAccessComponent } from './pages/ftp-access/ftp-access.component';
import { FileServerComponent } from './pages/file-server/file-server.component';
import { CrowdsourcingMobileAppComponent } from './pages/crowdsourcing-mobile-app/crowdsourcing-mobile-app.component';
import { CrowdsourcingDashboardComponent } from './pages/crowdsourcing-dashboard/crowdsourcing-dashboard.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { AlertsComponent } from './pages/alerts/alerts.component';
import { LandWaterTransitionZoneComponent } from './pages/land-water-transition-zone/land-water-transition-zone.component';
import { BloomEventComponent } from './pages/bloom-event/bloom-event.component';
import { ExtremeEventComponent } from './pages/extreme-event/extreme-event.component';
import { WaterQualityComponent } from './pages/water-quality/water-quality.component';
import { AccountDevComponent } from './pages/account-dev/account-dev.component';
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component';
import { AboutGeossServicesComponent } from './pages/about-geoss-services/about-geoss-services.component';
import { MeasureTypeAPIsService } from './services/restapiservices/api/measureTypeAPIs.service';
import { MyAccountDevComponent } from './pages/my-account-dev/my-account-dev.component';
import { TokenService } from './services/my-keycloak/token.service';
import { ServiceSchemasComponent } from './pages/service-schemas/service-schemas.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { MyAlertsComponent } from './pages/my-alerts/my-alerts.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'portal-wqems.iti.gr'
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      "background": "#48a3a2",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  theme: 'classic',
  position: 'bottom',
  type: 'opt-out',
  // content: {
  //   "message": "This website uses cookies to ensure you get the best experience on our website.",
  //   "dismiss": "Got it!",
  //   "deny": "Refuse cookies",
  //   "link": "Learn more",
  //   "href": "https://cookiesandyou.com",
  //   "policy": "Cookie Policy"
  // },
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank" rel="noopener">{{tosLink}}</a>
    </span>
    `,
  },
  content: {
    message: 'By using our site, you acknowledge that you have read and understand our ',

    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: 'cookie-policy',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'privacy-policy',

    tosLink: 'Terms and Conditions',
    tosHref: 'terms-and-conditions',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    FootersComponent,
    HeadersComponent,
    RequestServiceComponent,
    AlertConfigurationComponent,
    RequestReportComponent,
    AdministrationDashboardComponent,
    NotFoundComponent,
    AboutCopernicusServicesComponent,
    ForbiddenComponent,
    MyRequestedServicesComponent,
    MyAlertConfigurationsComponent,
    UserHomeComponent,
    MyAlertConfigurationDetailsModalComponent,
    MyAlertConfigurationsComponentComponent,
    MyRequestedServiceDetailsModalComponent,
    MyRequestedServicesComponentComponent,
    UserPendingRequestListModalComponent,
    AdministrationRequestsListComponent,
    AdministrationRequestComponent,
    AdministrationRequestedServiceComponent,
    CatalogueComponent,
    DataAccessComponent,
    TrainingComponent,
    MapAndDataNavigatorComponent,
    WebServicesComponent,
    FtpAccessComponent,
    FileServerComponent,
    CrowdsourcingMobileAppComponent,
    CrowdsourcingDashboardComponent,
    ReportsComponent,
    AlertsComponent,
    LandWaterTransitionZoneComponent,
    BloomEventComponent,
    ExtremeEventComponent,
    WaterQualityComponent,
    AccountDevComponent,
    InfoModalComponent,
    AboutGeossServicesComponent,
    MyAccountDevComponent,
    ServiceSchemasComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    MyAlertsComponent
  ],
  exports: [
    FootersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    KeycloakAngularModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    FontAwesomeModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService],
  },
  { provide: BASE_PATH, useValue: 'https://api-wqems.iti.gr' },
    MeasureTypeAPIsService,
    RequestedServiceAPIsService,
    HttpClient,
    AlertConfigurationAPIsService,
    RequestedReportAPIsService,
    UserAPIsService,
    AlertAPIsService,
    WaterBodyAPIsService,
    SocialAlertTypeAPIsService,
    TokenService,
    RequestAPIsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faCircleInfo);
    library.addIcons(faArrowRight);
    library.addIcons(faCog);
    library.addIcons(faUserCircle);
    library.addIcons(faUser);
    library.addIcons(faExclamationTriangle);
    library.addIcons(faSliders);
    library.addIcons(faShareSquare);
    library.addIcons(faSignIn);
    library.addIcons(faSignOut);
    library.addIcons(faTh);
    library.addIcons(faThLarge);
    library.addIcons(faFileText);
    library.addIcons(faTimes);
    library.addIcons(faTrash);
    library.addIcons(faSearch);
    library.addIcons(faEye);
    library.addIcons(faPlus);
    library.addIcons(faExternalLink);
    library.addIcons(faExpand);
    library.addIcons(faFile);
    library.addIcons(faTachometer);
    library.addIcons(faDatabase);
    library.addIcons(faBook);
    library.addIcons(faMap);
    library.addIcons(faGlobe);
    library.addIcons(faFolderOpen);
    library.addIcons(faCommenting);
    library.addIcons(faCommentDots);
    library.addIcons(faCommentAlt);
    library.addIcons(faInfo);
    library.addIcons(faInfoCircle);
    library.addIcons(faBullhorn);
  }
}
