<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Alerts</h3>
            <p class="text-start lead">
                WQeMS system enable users to define rules on a specific Water Body in order to <strong>receive notifications when an water-related issue occurs</strong>. In particular, the system  matches statistical values (such as the average turbidity value or other parameters) over a water body and rules with thresholds specified by the users. If the values expressed by the users in the alert configuration exceed the detected values, then a notification is generated.
                Specifically, by accessing the service, the user can select a water body from those for which a continuous monitoring is active for at least one service. Then, indicating one or more rules on the statistical values generated by the continuous monitoring active service on that Water Body. Whenever a new data product for that service is generated, if even one of the rules defined occurs, an alert notification is sent. In addition to this, the user can also decide to receive a notification when an event identified by the crowd occurs (see Crowdsourcing Mobile App and Dashboard). One or more notification methods can be set, choosing among HTTP request to a specified API and/or email.
            </p>          
        </div>
    </div>
</div>


<!--
<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/1ozGKlOzEVc" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
</div>

<hr>
-->

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="alert-configuration" class="btn btn-secondary btn-lg pt-2 pb-2">
            Go to the Alerts Configuration<fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>