import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-schemas',
  templateUrl: './service-schemas.component.html',
  styleUrls: ['./service-schemas.component.css']
})
export class ServiceSchemasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
