<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Alerts</h3>
      <p class="text-center lead mt-4 mb-4">
        On this page you can view, manage and delete your alert configurations. Each configuration refers to a single AOI and one or multiple parameters monitored.
      </p>
      <p class="text-center">
        <a class="btn btn-lg btn-secondary" href="alert-configuration" role="button">
          Create a new alert configuration <fa-icon icon="arrow-right"></fa-icon>
        </a>
      </p>
    </div>
  </div>
</div>
<!-- 
<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h4>Your Alert Configurations</h4>
    </div>
  </div>
</div> -->

<div class="row">
  <div class="col-12">
    <app-my-alert-configurations-component></app-my-alert-configurations-component>
  </div>
</div>
