import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { AlertConfigCompleteGetDTO, AlertConfigurationAPIsService } from 'src/app/services/restapiservices';
import * as L from 'leaflet';
import { data } from 'jquery';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-alert-configuration-details-modal',
  templateUrl: './my-alert-configuration-details-modal.component.html',
  styleUrls: ['./my-alert-configuration-details-modal.component.scss']
})
export class MyAlertConfigurationDetailsModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private alertConfigurationAPIsService: AlertConfigurationAPIsService, private keycloakService: KeycloakService, private route: Router) { }

 

  @Input() public idAlertConfig: any | undefined;
  private map: any;
  public alertConfigCompleteGetDTO: AlertConfigCompleteGetDTO | undefined;
  public token: string = '';


 
  private initMap(): void {
    this.map = L.map('map', {
      center: [ this.alertConfigCompleteGetDTO?.waterBody?.lon as number, this.alertConfigCompleteGetDTO?.waterBody?.lat as number],
      zoom: 12
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
    this.map.attributionControl.setPrefix(false);
  }

  ngOnInit(): void {
    console.log(this.idAlertConfig)
    this.keycloakService.getToken().then(token => {
      this.token = token;
      this.alertConfigurationAPIsService.getRequestedServiceCompleteByUserAndIdAlertConfigApiV1AlertConfigByuserCompleteIdAlertConfigGet(this.idAlertConfig, token).subscribe((data: AlertConfigCompleteGetDTO) => {
        this.alertConfigCompleteGetDTO = data;
        console.log(data)
        this.initMap();
        
       
      })
    });    
  }

  closeModal() {
    this.activeModal.dismiss()
    this.activeModal.close('Modal Closed');
  }

  onClickDeleteAlertConfig() {
    this.alertConfigurationAPIsService.deleteAlertConfigByUserAndIdAlertConfigApiV1AlertConfigByuserIdAlertConfigDelete(this.idAlertConfig, this.token).subscribe(data => {
      alert("Deleted.")
      this.activeModal.close('Modal Closed')
      this.route.navigateByUrl('my-alert-configurations')
    }, error => {
      console.log(error)
    })
  }

}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
