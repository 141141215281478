import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bloom-event',
  templateUrl: './bloom-event.component.html',
  styleUrls: ['./bloom-event.component.css'],
  providers: [NgbCarouselConfig]
})
export class BloomEventComponent implements OnInit {

  constructor(config: NgbCarouselConfig) {
    config.interval = 0;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationIndicators = true;
  }

  images = [1, 2].map((n) => `https://wqems.eu/assets/images/services/bloom_${n}.png`);

  ngOnInit(): void {
  }

}
