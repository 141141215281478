import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AlertConfigurationAPIsService, RequestAPIsService, RequestCompletGetDTO, RequestedServiceProductTypePartialGetDTO, RequestPartialGetDTO } from 'src/app/services/restapiservices';
import { AlertConfigCompleteGetDTO } from 'src/app/services/restapiservices';
import { KeycloakProfile } from 'keycloak-js';
import { RequestedServiceProductTypeCompleteGetDTO } from 'src/app/services/restapiservices';
import {NgbModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RequestedServiceAPIsService } from 'src/app/services/restapiservices';
import { MyRequestedServiceDetailsModalComponent } from '../modals/my-requested-service-details-modal/my-requested-service-details-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-requested-services-component',
  templateUrl: './my-requested-services-component.component.html',
  styleUrls: ['./my-requested-services-component.component.scss']
})
export class MyRequestedServicesComponentComponent implements OnInit {

  public keycloakProfile: KeycloakProfile = {};

  // public requestPartialGetDTOs: RequestPartialGetDTO[] = [];
  requestPartialGetDTOs!: RequestPartialGetDTO[];

  constructor(private keycloakService: KeycloakService, private requestAPIsService: RequestAPIsService,  private modalService: NgbModal) { }

  ngOnInit(): void {
    this.keycloakService.getToken().then(token => {
      this.keycloakService.loadUserProfile().then(data => {
        this.keycloakProfile = data;
        this.requestAPIsService.getRequestedServicePartialByUserApiV1RequestByUserPartialGet(token).subscribe((data: RequestPartialGetDTO[]) => {
          this.requestPartialGetDTOs = data.sort((a, b) => new Date(b.requestDate!).getTime() - new Date(a.requestDate!).getTime())
          console.log(data)
        })
      })
      
    });
  }

  openModal(requestPartialGetDTOrequestedService: any, elaborated: any) {
    if (requestPartialGetDTOrequestedService.idRequestedService == undefined) {
      alert("Undefined...")
      return;
    }

    console.log("id    " + requestPartialGetDTOrequestedService.idRequestedService)

    const modalRef = this.modalService.open(MyRequestedServiceDetailsModalComponent, {size: 'modal-lg'});
    modalRef.componentInstance.idRequestedService = requestPartialGetDTOrequestedService.idRequestedService;
    console.log(requestPartialGetDTOrequestedService)
    modalRef.componentInstance.elaborated = elaborated;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

}
