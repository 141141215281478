export * from './alertAPIs.service';
import { AlertAPIsService } from './alertAPIs.service';
export * from './alertConfigurationAPIs.service';
import { AlertConfigurationAPIsService } from './alertConfigurationAPIs.service';
export * from './processingChainsTestAPIs.service';
import { ProcessingChainsTestAPIsService } from './processingChainsTestAPIs.service';
export * from './productAPIs.service';
import { ProductAPIsService } from './productAPIs.service';
export * from './requestAPIs.service';
import { RequestAPIsService } from './requestAPIs.service';
export * from './requestedReportAPIs.service';
import { RequestedReportAPIsService } from './requestedReportAPIs.service';
export * from './requestedServiceAPIs.service';
import { RequestedServiceAPIsService } from './requestedServiceAPIs.service';
export * from './socialAlertAPIs.service';
import { SocialAlertAPIsService } from './socialAlertAPIs.service';
export * from './socialAlertTypeAPIs.service';
import { SocialAlertTypeAPIsService } from './socialAlertTypeAPIs.service';
export * from './userAPIs.service';
import { UserAPIsService } from './userAPIs.service';
export * from './waterBodyAPIs.service';
import { WaterBodyAPIsService } from './waterBodyAPIs.service';
export const APIS = [AlertAPIsService, AlertConfigurationAPIsService, ProcessingChainsTestAPIsService, ProductAPIsService, RequestAPIsService, RequestedReportAPIsService, RequestedServiceAPIsService, SocialAlertAPIsService, SocialAlertTypeAPIsService, UserAPIsService, WaterBodyAPIsService];
