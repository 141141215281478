import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { TokenService } from 'src/app/services/my-keycloak/token.service';
import { UserAPIsService, UserClientCompleteGetDTO } from 'src/app/services/restapiservices';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-my-account-dev',
  templateUrl: './my-account-dev.component.html',
  styleUrls: ['./my-account-dev.component.css']
})
export class MyAccountDevComponent implements OnInit {

  userClientCompleteGetDTO?: UserClientCompleteGetDTO;
  typeFieldClientSecret: string = 'password';
  token: string = ''

  constructor(private userService: UserAPIsService, private keycloakService: KeycloakService, private tokenService: TokenService) { }

  async ngOnInit(): Promise<void> {
    await this.keycloakService.getToken().then(async token => {
     await this.userService.getUserClientByUsernameApiV1UserClientGet(token).subscribe(data => {
        this.userClientCompleteGetDTO = data;
        console.log(data)
      }, error => {
        console.error(error)
      })
    })
    
  }

  onClickShowHide() {
    if (this.typeFieldClientSecret == 'password') {
      this.typeFieldClientSecret = 'text'
    } else {
      this.typeFieldClientSecret = 'password'
    }
  }

  onClickGetToken() {
    this.tokenService.getTokenByClientIdAndClientSecret(this.userClientCompleteGetDTO?.idClient as string, this.userClientCompleteGetDTO?.clientSecret as string).subscribe(data => {
      console.log(data)
    }, error => {
      console.error(error)
    })

  }

}
