<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>FTP Access</h3>
            <p class="text-start lead">
                The File Transfer Protocol over SSL (FTPS) directory is a remote directory which provide access to the WQeMS generated data 
                products through the FTPS protocol. To access the remote directory, the user can use one of the several clients available 
                (e.g. FileZilla). In the FTP client the user will be asked to enter the machine's host. 
                Enter the IP address <strong>152.228.211.92</strong>. In the username and password fields, however, use the same credentials used to 
                log in to the system. Once connected, the user will be able to see and navigate all the folders containing the data products 
                for which the user has access to. The folders are organized hierarchically, specifically according to a grouping that includes 
                service, subservice, water body, and data product (raster and metadata files).
                </p>
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="https://filezilla-project.org/" class="btn btn-secondary btn-lg pt-2 pb-2" target="_blank">
            Go to the FileZilla <fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>