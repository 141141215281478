<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-4 rounded">
      <h3>Report Generation</h3>
      <p class="text-center lead mt-4 mb-4">
        Fill in the form to request a report for a specific water body / AOI and specific parameters and dates. A PDF will be generated, including all statistical information related to and sent to the email address specified in the form.
      </p>
    </div>
  </div>
</div>

<div class="row mb-4">
  <div class="col-12 rounded">
    <div id="map" class="rounded">
      <div *ngIf="servicesWaterBodyRolesGetDTOs == undefined" class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="servicesWaterBodyRolesGetDTOs != undefined">
  <form role='form' name='requestReportForm' novalidate="">

  <div class="row">
    <div class="col-12">
      <div class="bg-light p-4 pt-2 pb-2 mb-4 rounded">
        <div class="mb-3 pb-4">
          <label for="exampleFormControlSelect1" class="form-label">Select the water body to report:</label>
          <select #selectWaterBodyName name="selectWaterBodyName" class="form-select"
              [(ngModel)]="servicesWaterBodyRolesGetDTOSelectedWaterBody" id="exampleFormControlSelect1" required=""
              (change)="onChangeWaterBodySelected($event)">
              <option [ngValue]="servicesWaterBodyRolesGetDTO"
                *ngFor="let servicesWaterBodyRolesGetDTO of servicesWaterBodyRolesGetDTOs; index as index">
                {{servicesWaterBodyRolesGetDTO.waterBodyNameText}}</option>
            </select>
        </div>

        <!-- <div class="mb-3">
          <label for="exampleFormControlSelect2" class="form-label">Select one or more parameters to include in the report</label>
          <select name="selectedServicesName" [(ngModel)]="insertRequestedReportDTO.serviceNames" multiple
              class="form-select" id="exampleFormControlSelect2" required="True">
              <option value="{{selectableService.serviceName}}"
                *ngFor="let selectableService of selectableServices; index as index">
                {{selectableService.humanReadableServiceName}}</option>
            </select>
        </div> -->
        <div *ngIf="selectWaterBodyName.value.length > 0">
          <div class="form-group pb-4" >
            <label for="exampleFormControlSelect2" class="form-label">Select one or more parameters to include in the report:</label>
            <div name="selectedServicesName">
              <div *ngFor="let selectableService of selectableServices; index as index" class="checkbox form-check form-switch">
                    <input class="form-check-input" type="checkbox" value="{{selectableService.serviceName}}" name="{{selectableService.serviceName}}"  (change) ="updateSelectedService($event)" />
                    <label class="form-check-label" for="{{selectableService.serviceName}}">{{selectableService.humanReadableServiceName}}</label>
              </div>
            </div>
          </div>

          <div class="pb-4">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="startDateInput" class="form-label">Start date:</label>
                <input name="startDateName" [(ngModel)]="insertRequestedReportDTO.startDate" id="startDateInput"
                      type="date" class="form-control" required="True">
              </div>
              <div class="col-md-6 mb-3">
                <label for="endDateInput" class="form-label">End date:</label>
                <input name="endDateName" [(ngModel)]="insertRequestedReportDTO.endDate" id="endDateInput" type="date"
                      class="form-control" min="{{insertRequestedReportDTO.startDate}}" required="True">
              </div>
            </div>
          </div>

          <div class="mb-3 pb-4">
            <label for="exampleFormControlInput1" class="form-label">Email address to send the report to:</label>
            <input name="emailName" ng-model="email" [(ngModel)]="insertRequestedReportDTO.email" type="email"
                class="form-control" id="exampleFormControlInput1" required="True" placeholder="email@email.com" value="{{ userProfile?.email }}">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
      <button ng-disabled="requestReportForm.$invalid" (click)="onClickConfirm()" type="submit" class="btn btn-secondary btn-lg pt-2 pb-2">
        Submit <fa-icon icon="arrow-right" class="right"></fa-icon>
      </button>
    </div>
  </div>

  </form>
</div>
