<div class="container">
    <br>
    <h3 *ngIf="requestedServiceProductTypeCompleteGetDTO != undefined">Manage Requested Services for: {{requestedServiceProductTypeCompleteGetDTO!.waterBody!.nameText}}</h3>
    <br>
    <div id="map" class="text-center">    
      <div *ngIf="requestedServiceProductTypeCompleteGetDTO == undefined" class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
      <div *ngIf="requestedServiceProductTypeCompleteGetDTO != undefined">
        <form>        
          <hr>
          <h5>Water Body Information</h5>
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text" id="inputGroup-sizing-sm">Local ID</span>
            <input name="waterBodyLocalIdName" [(ngModel)]="requestedServiceProductTypeCompleteGetDTO.waterBody!.localId" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
          </div>
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text" id="inputGroup-sizing-sm">Water Body Name</span>
            <input  name="waterBodyNameTextName" [(ngModel)]="requestedServiceProductTypeCompleteGetDTO.waterBody!.nameText" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
          </div>
        </form>

        <hr>

        <h4>Services</h4>
        
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Serivce Name</th>
              <th scope="col">Type of Request</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Accepted</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let requestedServiceProductType of requestedServiceProductTypeCompleteGetDTO!.requestedServiceProductType; index as index">
              <th scope="row">{{index + 1}}</th>
              <td>{{requestedServiceProductType.productType!.humanReadableServiceName}}</td>
              <td>{{requestedServiceProductType.typeRequest}}</td>
              <td>{{requestedServiceProductType.startDate | date: 'd/M/y'}}</td>
              <td>{{requestedServiceProductType.endDate | date: 'd/M/y'}}</td>
              <td>
                  <div class="form-check">
                  <input #acceotService [(ngModel)]="requestedServiceProductType.accepted" (change)="onAcceptService(requestedServiceProductType!.productType!.idProductType, acceotService)" class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                  <label class="form-check-label" for="flexCheckChecked">
                    Accept
                  </label>
                </div>
                </td>
            </tr>
          
          </tbody>
        </table>
        <div class="text-center">
          <button type="button" class="btn btn-primary btn-lg btn-block" (click)="onClickConfirm()">Confirm</button>
        </div>
      </div>  



  </div>