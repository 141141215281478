<div class="row">
  <script src="//code.jquery.com/jquery-1.11.0.min.js"></script>

  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Bloom Event Detection</h3>

      <div class="accordion" id="accordion1">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingOne">
            <button class="accordion-button bg-primary" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Service Description
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              The Bloom Events Detection service covers for the emergence of potentially harmful algal blooms in open
              surface water reservoirs used for drinking water production. Satellite-based data of the reservoirs can be
              used to identify and track evolving algal blooms. They can cause problems by potentially influencing taste
              and odour of the water, as well as the concentration of toxin producing species, such as cyanobacteria
              microcystins.
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion2">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingTwo">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Service Results
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              The latest update included the visualization of the automated water quality measurements done in Lake
              Pien-Saimaa in Finland. The instruments installed on an in situ measurement platform estimate
              Chlorophyll-a (Chl-a), temperature and turbidity. Below is an example showing a turbidity map and the
              timeseries of automated Chl-a measurements.
              EO and in situ water quality information will be provided to professional users and the general public
              through WQeMS and SYKE’s TARKKA service. SYKE is developing the next version (TARKKA+) and its beta
              version is now available. More content and functionalities are being added weekly.
              After receiving in situ data for the German pilot case the results of a study with relevant satellite
              images was conducted with eoHAB from EOMAP and are shown below. Quitzdorf reservoir showed no indication
              of a harmful algal bloom on 2016-05-09 (up), but there was a strong indication for an algal bloom on
              2018-05-14 (down). Observations from the satellite images agree with the registered in situ data.
              While the early date shows barely any cyanobacterial cell count, the cell counts for the second date are
              very different. While for the earlier date, the cell count is less than 1 Mio. cells per liter, the bloom
              date shows around 200 Mio. cells per liter. Therefore, it confirms the data already visualized in the
              satellite imagery. Additional dates will be checked for a comparison of satellite imagery and in situ
              data.
              The application of hyperspectral imagery is still in the testing phase, but first results are promising.
              Additional spectral bands allow for a more detailed observation of the spectrum, which helps the
              physics-based algorithms applied by EOMAP.
              For the hyperspectral imagery, the Italian PRISMA satellite is employed. Due to the early stage of this
              satellite mission, no regular image captures over the same area could be accessed. Additionally, no
              imagery of pilot case regions with a visible algal bloom could be detected so far, since the number of
              images for each region is still limited, and these bloom events are highly dynamic events.
              EOMAP is monitoring closely PRISMA’s acquisitions versus real events and chances of capturing an algal
              bloom event in the time of hyperspectral imagery acquisition increase. Furthermore, the EnMAP satellite is
              in the process of calibration after it was launched earlier this year. Hopefully, this satellite increases
              the frequency of spaceborne hyperspectral data takes.
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion3">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingThree">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Gain for the user
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              The service allows anyone to get access to water quality information in a convenient manner. In the
              Finnish use case, the latest values in May indicate increasing trend in algal biomass. This is expected as
              the algal bloom season was approaching.
              <br><br>
              In the German use case, the focus now lies on validating past events, such as the one shown before, before
              looking into the future in the form of the operational (monitoring) service for the water authorities.
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion4">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingThree">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              Service Specifications
            </button>
          </h2>
          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              <b>Algal Blooms Detection Product (*)</b>
              <br><br>
              <b>Type</b>: Fast Processes - Monitoring/Emergency (**)
              <br>
              <b>Time coverage/frequency</b>: daily ~ weekly 
              <br>
              <b>Availability</b>: systematic (depending on operational system configuration) or on demand 
              <br><br>
              <i><u>Input parameters</u></i>
              <br>
              <i>Area</i>: AoI in the form of shapefile or coordinates
              <br>
              <i>Time</i>: Selected date(s) or given period of time 
              <br><br>
              <i><u>Input data</u></i>
              <br>
              <i>EO satellite data</i>: Sentinel 3A/B; Sentinel-2A/B; Landsat 5/7/8/9; (PlanetDoves and WorldView-3 tested during the project, with an additional cost)
              <br><br>
              <i>Spatial resolution</i>: 300 m - 10 m (PlanetDoves: 3 m, WorldView-3: 2 m, both tested during the project)
              <br><br>
              <i>Temporal Resolution</i>:
              <br>
              <ul>
                <li><i>Sentinel-2: 2/3 - 5 days (depending on overflight paths)</i></li> 
                <li><i>Landsat 5: 1984 until 2012, every 16 days</i></li> 
                <li><i>Landsat 7: 1999 until 2021, every 16 days</i></li> 
                <li><i>Landsat 8: since 2013, every 16 days</i></li> 
                <li><i>Landsat 9: since 2021, every 16 days</i></li> 
                <li><i>Sentinel-3: since 2016, up to daily</i></li> 
              </ul>
              <br>
              <i>Additional data to use (in case of service expansion and user request, at the relative cost – ref. to the service offer):</i>
              <br>
              <ul>
                <li><i>WorldView-2,3 data, since 2009, up to daily </i></li> 
                <li><i>PlanetSuperDoves, since 2021, up to daily</i></li> 
              </ul>
              <br>
              <i><u>Output product</u></i>
              <br>
              <i>Format</i>: AoI in the form of shapefile or coordinates
              <br>
              <i>Spatial resolution</i>: 300 m -10 m
              <br>
              <i>Size [bytes]</i>: Depends on the size of the AoI, the selected sensor and its spatial resolution  
              <br>
              [<i>Product</i>: Harmful Algal Bloom indicator (HAB), sensitive for Phycocyanin and Phycoerythrin (***)]
              <br><br>
              <i>Short description</i>:
              <br>
              <ul><li><b>Harmful Algae Bloom Indicator (HAB)</b> is used for the indication of cyanobacteria and sensitive to the appearance of Phycocyanin and Phycoerythrin. The product does not provide a quantitative measure, but identifies reflectance and absorption discrepancies between the 550nm and 650nm wavelength bands, which indicate the appearance of the Cyanobacteria-related pigments. It is classified into four likelihood classes: no HAB, unlikely, likely and very likely.</li></ul>
              Connected to the Harmful Algal Bloom indicator is also the parameter Chlorophyll-a, as it is also a pigment present in cyanobacteria, and can be used as an indicator of algal bloom events. However, not all bloom events are necessarily harmful algal blooms.
              <br><br>
              <ul><li><b>Chlorophyll-a (CHL)</b>in (µg/l): EOMAP offers a harmonized, standardized, Chlorophyll-a indicator for a range of sensors. This is based on the derived information of in-water organic absorption, in-water turbidity and spectral characteristics of each water body. Chlorophyll values vary over 4 magnitudes, for marine waters or clear lakes typical concentrations between 0.01 and 10 µg/l, while for eutrophic lakes concentrations can reach 100 µg/l and more.</li></ul>
              <br>
              <i><u>Metadata information including (e.g.)</u></i>:
              <ul>
                <li>cloud coverage (as found in metadata of the product)</li>
                <li>quality assessment of the provided product (Total Quality, QUT)</li>
                <li>product description, keywords, essential variables</li>
                <li>extent of processed image</li>
                <li>Date and time</li>
                <li>Data source (satellite)</li>
                <li>Service Provider</li>                
              </ul>            
              <br>
              <i><u>Dissemination</u></i>:
              <ul>
                <li>FTP</li>
                <li>OGC Web Services & APIs</li>
              </ul>
              <br>
              <i><u>Timeliness</u></i>:
              <ul>
                <li>Upon availability of input data from DIAS provider (normally a few hours after sensing). </li>
              </ul>
              <br>
              <i><u>Literature</u></i>:<br>
              Dörnhöfer, K., Klinger, P., Heege, T., Oppelt, N. (2017): Multi-sensor satellite and in situ monitoring of phytoplankton development in a eutrophic-mesotrophic lake. Science of the Total Environment 612C (2018) pp. 1200-1214 DOI information: 10.1016/j.scitotenv.2017.08.219 
              <br><br>
              Bresciani M., Giardino C., Stroppiana D., Dessena M.A., Buscarinu P., Cabras L, Schenk K., Heege T., Bernert H., Bazdanis G. & Tzimas A. (2019): Monitoring water quality in two dammed reservoirs from multispectral satellite data, European Journal of Remote Sensing, 10 pages, DOI: 10.1080/22797254.2019.1686956 
              <br><br><br>
              (*) Products are free during the project; on payment after or free as CEMS service; products can be also accessed and handled as WQeMS services.  
              <br>
              (**) The service is classified with reference to the type of process (Fast/Slow) and the scope, namely, Monitoring/Emergency . 
              <br>
              (***) EOMAP processing. 
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 mb-5 rounded">

      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">

          <ngb-carousel *ngIf="images">
            <ng-template ngbSlide *ngFor="let image of images">
              <div class="picsum-img-wrapper">
                <img [src]="image" alt="WQeMS">
              </div>
            </ng-template>
          </ngb-carousel>

        </div>
      </div>

    </div>
  </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
  <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
    <a href="request-service/" type="button" class="btn btn-secondary btn-lg pt-2 pb-2">
      Request a Service <fa-icon icon="arrow-right" class="right"></fa-icon>
    </a>
  </div>
</div>