
<div id="modal-info" class="modal-content custom modal-ku">
    <div class="modal-header">
      <h5 class="modal-title">{{title}}</h5>
    </div>
    <div class="modal-body">
      <p>{{message}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
          (click)="closeModal()">
          Close
      </button>
    </div>
  </div>
  
    