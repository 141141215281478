<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Crowdsourcing Dashboard</h3>
            <p class="text-start lead">
                The Crowdsourcing Dashboard is a web application that <strong>visualize on a map the alerts</strong> as pop-ups. The alerts are generated from three crowdsourcing sources: Twitter, the <a href="crowdsourcing-mobile-app/">Crowdsourcing Mobile App</a> and the citizen observation system of Finnish Environment Agency (SYKE) collected from CitObs (citizen observation database) API. In the dedicated dashboard, by specifying a period of interest, the language, the type of event and possibly one or more sources from which to take the generated alerts (Twitter Alerts, CitObs Alerts and Crowdsourcing Mobile App), all corresponding alerts will be displayed on the map and details are provided. A <a href="https://socialmedia-server-m4d.iti.gr/WQeMS/WQeMS_Crowdsourcing_Alert_Dashboard_USER_MANUAL.pdf"  target="_blank" download>user manual</a> is provided
            </p>
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xxl-10">

                    <ngb-carousel *ngIf="images">
                        <ng-template ngbSlide *ngFor="let image of images">
                            <div class="picsum-img-wrapper">
                                <img [src]="image" alt="WQeMS">
                            </div>
                        </ng-template>
                    </ngb-carousel>

                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="http://m4d-apps.iti.gr:8007"  target="_blank" class="btn btn-secondary btn-lg pt-2 pb-2">
            Go to the Crowdsourcing Dashboard <fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>