import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { RequestedServiceAcceptPutAcceptedRequestDTO, RequestedServiceAcceptPutDTO, RequestedServiceAPIsService, RequestedServiceProductTypeCompleteGetDTO } from 'src/app/services/restapiservices';
import * as L from 'leaflet';
import * as shp from 'shpjs';
import 'leaflet-draw';
import { ReturnStatement } from '@angular/compiler';

@Component({
  selector: 'app-administration-requested-service',
  templateUrl: './administration-requested-service.component.html',
  styleUrls: ['./administration-requested-service.component.scss']
})
export class AdministrationRequestedServiceComponent implements OnInit, AfterViewInit {

  constructor(private keycloakService: KeycloakService, private activatedRoute: ActivatedRoute, private requestedServiceAPIsService: RequestedServiceAPIsService, private router: Router) { }

  private idRequestedService: number | undefined;
  private token: string | undefined;
  private map: any;
  drawnItems: any;
  public requestedServiceProductTypeCompleteGetDTO: RequestedServiceProductTypeCompleteGetDTO | undefined;
  public requestedServiceAcceptPutAcceptedRequestDTOs:RequestedServiceAcceptPutAcceptedRequestDTO[] = []


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.idRequestedService = params['idRequestedService'];
      this.keycloakService.getToken().then(token => {
        this.token = token;  
        this.requestedServiceAPIsService.getRequestedServiceCompleteByIdRequestedServiceApiV1RequestedServiceByIdRequestedServiceIdRequestedServiceCompleteGet(this.idRequestedService as number, token).subscribe(data => {
            console.log(data)
            this.initMap()
            this.requestedServiceProductTypeCompleteGetDTO = data;
            this.drawnItems = new L.FeatureGroup();
            this.changeMapCenter(this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.lat as number, this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.lon as number)
            const leafletGeoJSON = new L.GeoJSON(this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.geometry as any, {});
            this.drawnItems.addLayer(leafletGeoJSON)
            this.map.addLayer(this.drawnItems)    
          })
       
      })
    });
  }

  ngAfterViewInit(): void {
    // this.initMap()
    
  }
  private initMap(): void {
    this.map = L.map('map', {
      center: [ 39.8282, -98.5795 ],
      zoom: 12,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    

    tiles.addTo(this.map);

    this.map.attributionControl.setPrefix(false);

    this.drawnItems = new L.FeatureGroup();
    this.map.addLayer(this.drawnItems);

    var drawControl = new L.Control.Draw({

      draw: {
        rectangle: false,
        marker: false,
        circle: false,
        polyline: false,
        circlemarker: false,
    },
    });
     this.map.addControl(drawControl);

     let t = this;

     this.map.on(L.Draw.Event.CREATED, function (event: any) {
      var layer = event.layer;
      t.map.removeLayer(t.drawnItems)
      t.drawnItems = new L.FeatureGroup();
      t.drawnItems.addLayer(layer);
      t.map.addLayer(t.drawnItems)
  });
  }

  



  changeMapCenter(lat: number, lon: number) {
    this.map.setZoom(12);
    this.map.panTo(new L.LatLng(lon, lat));
  }

  public onAcceptService(idProductType: any, event:any) {
    console.log(`New service selected for ${idProductType}. Is ${event.checked}`);
    this.requestedServiceAcceptPutAcceptedRequestDTOs.push({
      idProductType: idProductType,
      accepted: event.checked,
      idRequestedService: this.requestedServiceProductTypeCompleteGetDTO?.idRequestedService
    })
  }


  onClickConfirm() {
    if (confirm("Are you sure?")) {
      if (this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.localId == '' || this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.nameText == '' ) {
        alert("Local Id and Name Text must not be empty")
        return;
      }
      let requestedServiceAcceptPutDTO: RequestedServiceAcceptPutDTO = {
        waterBody: {
          waterBodyGeometry: this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.geometry,
          idWaterBody: this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.idWaterBody,
          localId: this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.localId,
          nameText: this.requestedServiceProductTypeCompleteGetDTO?.waterBody?.nameText
        },
        acceptedRequestes: this.requestedServiceAcceptPutAcceptedRequestDTOs
      }
      this.requestedServiceAPIsService.acceptRequestedServicesApiV1RequestedServiceAcceptPut([requestedServiceAcceptPutDTO], this.token as string).subscribe(data => {
        alert("Success")
        this.router.navigateByUrl('administration-requests-list')
      }, error => {
        alert(error.details)
      })
    }


  }

  

}
