/**
 * WQeMS: RestAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RequestedServiceProductTypeItemDTO } from './requestedServiceProductTypeItemDTO';
import { WaterBodyGeometryGeoJSONModel } from './waterBodyGeometryGeoJSONModel';

export interface InsertRequestedServiceDTO { 
    localId?: string;
    geometry?: WaterBodyGeometryGeoJSONModel;
    waterBodyNameText?: string;
    services?: Array<RequestedServiceProductTypeItemDTO>;
}