<div class="modal-content">

    <div *ngIf="userInfoKeycloakDTOs == undefined" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div class="modal-header">
        <h5 class="modal-title">Users</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
    </div>

    <div class="modal-body">
        <div id="map"></div>

        <h4>Select an User</h4>

        <div class="table-wrapper">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Id</th>
                        <th scope="col">Email</th>
                        <th scope="col">Manage</th>
                    </tr>
                </thead>
                <tbody class="align-middle">
                    <ng-container *ngFor="let userInfoKeycloakDTO of userInfoKeycloakDTOs; index as index">
                        <tr>
                            <th scope="row">{{index+1}}</th>
                            <td>{{userInfoKeycloakDTO.id}}</td>
                            <td>{{userInfoKeycloakDTO.username}}</td>
                            <td class="align-middle">
                                <button type="button" (click)="onClickManagePendingRequestOfUser(userInfoKeycloakDTO.id)"
                                    class="btn align-middle btn-success mt-5 w-100">Manage</button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <div class="row justify-content-start">
            <div class="col-12 col-md-6">
                <button type="button" class="btn btn-primary pt-2 pb-2 w-100 mt-4 mb-4" data-bs-dismiss="modal"
                        (click)="closeModal()">
                    <fa-icon icon="times" class="left"></fa-icon> Close
                </button>
            </div>
        </div>

    </div>
</div>