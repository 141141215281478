<div class="row">
    <script src="//code.jquery.com/jquery-1.11.0.min.js"></script>
  
    <div class="col-12">
      <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
        <h3>Service Schemas</h3>
  
  
        <div class="accordion" id="accordion1">
          <div class="accordion-item">
            <h2 class="accordion-header m-0" id="headingOne">
              <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Basic service
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body bg-white">
               <ul>
                <li>Area size: max 100 x 100 km</li>
                <li>Up to 4 geospatial services x 4 products for each per month.</li>
                <li>1 service line for free (4 products per month) if the 4 geospatial services are selected, including the alert service. (see geospatial mapping services above) (cost per service line tbd)</li>
                <li>Alert service, from variables’ threshold monitoring and/or crowd sourcing (unlimited crowd alerts for free), continuously available, max 4 geospatial maps per service line per month.</li>
                <li>Reporting (up to 4 reports per service per month).</li>
               </ul>
              </div>
            </div>
          </div>
        </div>
  
        <div class="accordion" id="accordion2">
            <div class="accordion-item">
              <h2 class="accordion-header m-0" id="headingTwo">
                <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Extended service
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body bg-white">
                Following elements may be additionally selected:
                <ul>
                    <li>Maximum number of data products (available spaceborne data acquisitions), which can be provided over an area in a given period (cost per service line/product tbd)</li>
                    <li>Data product calibration, provided the needed in-situ data are available (paid if not self-managed)</li>
                    <li>Area extension (larger than 100x100 km) (paid for end users) (different sensors may be used)</li>
                    <li>Hosting and Technical support (e.g., for additional development, refinements, customisations, integration in the user’s DSS) (paid)</li>
                    <li>Additional VHR data usage, depending on the service line and data availability (paid)</li>
                    <li>Self-managed processing of a new area, threshold testing and report adaptation (tbd runs for free)</li>
                    <li>Additional communication channels to alerts users (e.g. SMS, … tbd)</li>
                </ul>
                </div>
              </div>
            </div>
        </div>

        <div class="accordion" id="accordion3">
            <div class="accordion-item">
              <h2 class="accordion-header m-0" id="headingThree">
                <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  On-demand (/fast service) (one-off request)
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body bg-white">
                To be confirmed on a case-by-case basis; provided, the required satellite images are available. Registered customers can put a one-off request for 1-2 new area (1 product delivery free).
                <br>
                Training Service
                <ul>
                    <li>Self-learning through WQeMS training material (free for registered users with some service package enabled)</li>
                    <li>On-site training service by expert trainers (paid - available fixed and flexible training packages)</li>
                    <li>Online training service by expert trainers (paid - available fixed and flexible training packages)</li>
                    <li>One-off training for a specific item (paid - depending on item/time agreed)</li>
                </ul>

                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordion1">
            <div class="accordion-item">
              <h2 class="accordion-header m-0" id="headingFour">
                <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Training Service
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body bg-white">
                 <ul>
                    <li>Self-learning through WQeMS training material (free for registered users with some service package enabled)</li>
                    <li>On-site training service by expert trainers (paid – available fixed and flexible training packages)</li>
                    <li>Online training service by expert trainers (paid - available fixed and flexible training packages)</li>
                    <li>One-off training for a specific item (paid - depending on item/time agreed)</li>
                 </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordion1">
            <div class="accordion-item">
              <h2 class="accordion-header m-0" id="headingFive">
                <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Service Level Agreement (SLA*)
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body bg-white">
                    Forthcoming
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordion1">
            <div class="accordion-item">
              <h2 class="accordion-header m-0" id="headingSix">
                <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  Product Disclaimer
                </button>
              </h2>
              <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body bg-white">
                    The information and data delivered are provided “as is” and users bear all responsibility and liability for their use of data, and for any loss of business or profits, or for any indirect, incidental or consequential damages arising out of any use of, or inability to use, the data, even if WQeMS consortium was previously advised of the possibility of such damages, or for any other claim by you or any other person. WQeMS consortium makes no representations or warranties of any kind, express or implied, including implied warranties of fitness for a particular purpose or merchantability, or with respect to the accuracy of or the absence or the presence or defects or errors in data, databases of other information. The designations employed in the data do not imply the expression of any opinion whatsoever on the part of WQeMS consortium concerning the legal or development status of any country, territory, city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries. For more information please contact wqems_dissemination@iti.gr
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordion1">
            <div class="accordion-item">
              <h2 class="accordion-header m-0" id="headingSeven">
                <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                  Fast Service
                </button>
              </h2>
              <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body bg-white">
                    24 h following the request, provided satellite images are available.
                </div>
              </div>
            </div>
          </div>

          <div class="accordion" id="accordion1">
            <div class="accordion-item">
              <h2 class="accordion-header m-0" id="headingEight">
                <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  Service setup
                </button>
              </h2>
              <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                data-bs-parent="#accordionExample">
                <div class="accordion-body bg-white">
                    As standard procedure to request services, users select the area, the time period and services through a dedicated interface (forthcoming). The procedure for setting up the services over a new area may take a few days for confirmation (product generation & validation). The service delivery (to commercial customers) implies the signature of a contract, including SLA, product disclaimers and data usage policy. On-demand requests for fast service can be handled on-the-fly at the a standard price (tbd) provided there is the available data.
                </div>
              </div>
            </div>
          </div>


  
        
      </div>
    </div>
  </div>