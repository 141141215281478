<div class="container">
    <br>
    <div class="text-center">
        <div *ngIf="requestPartialGetDTO == undefined" class="spinner-border text-primary " role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div *ngIf="requestPartialGetDTO != undefined">
        <h3>Manage Service Request by {{requestPartialGetDTO!.user!.person![0].name}} {{requestPartialGetDTO!.user!.person![0].surname}} on {{requestPartialGetDTO!.requestDate | date: 'd/M/y, h:mm a'}} </h3>
        <br>
        <div class="p-5 mb-4 bg-light rounded-3">
            <div class="input-group mb-3">
            <h4>Requested Areas of Interest (AOIs)</h4>
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <!-- <th scope="col">Date</th> -->
                    <th scope="col">AOI</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                
                <tbody>
                <tr class="align-middle" *ngFor="let requestPartialGetDTOrequestedServices of requestPartialGetDTO?.requestedServices; index as index">
                    <th>{{index + 1}}</th>
                    <!-- <td scope="row">{{requestPartialGetDTOrequestedServices.requestedServiceDate | date: 'd/M/y, h:mm a'}}</td> -->
                    <td>{{requestPartialGetDTOrequestedServices.waterBody?.nameText}}</td>
                    <td>
                        <button type="button" (click)="onSelectRequestedService(requestPartialGetDTOrequestedServices.idRequestedService)" class="btn btn-primary btn-block confirm-request">Manage</button>  
                    </td>
                </tr>
                </tbody>
            </table>

            </div>
        </div>
        <div class="text-center">
            <button (click)="onClickMakeAsElaborated()" type="button" class="btn btn-danger btn-lg btn-block">Set Request as Elaborated</button>
        </div>

    </div>
  </div>