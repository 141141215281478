import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from 'src/app/components/modals/info-modal/info-modal.component';

@Injectable({
  providedIn: 'root'
})
export class InfoModalsService {

  constructor(private modalService: NgbModal) { }

  openInfoModal(title: string | undefined, message: string | undefined) {

    if (message == undefined) {
      alert("Undefined...")
      return;
    }

    const modalRef = this.modalService.open(InfoModalComponent,
      {
        windowClass: 'custom'
      });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

}
