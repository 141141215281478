import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-extreme-event',
  templateUrl: './extreme-event.component.html',
  styleUrls: ['./extreme-event.component.css'],
  providers: [NgbCarouselConfig]
})
export class ExtremeEventComponent implements OnInit {

  constructor(config: NgbCarouselConfig) {
    config.interval = 0;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationIndicators = true;
  }

  //images = [1, 2, 3].map((n) => `../../assets/img/catalogue_shot_${n}.png`);
  images = [
    '../../assets/img/floods_1.png'
  ]

  ngOnInit(): void {
  }

}
