/**
 * WQeMS: RestAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AlertConfigCompleteGetDTO } from '../model/alertConfigCompleteGetDTO';
import { AlertConfigPartialGetDTO } from '../model/alertConfigPartialGetDTO';
import { HTTPValidationError } from '../model/hTTPValidationError';
import { InsertAlertConfigDTO } from '../model/insertAlertConfigDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AlertConfigurationAPIsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deletealertconfigbyuserandidalertconfig
     * 
     * @param idAlertConfig 
     * @param xToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAlertConfigByUserAndIdAlertConfigApiV1AlertConfigByuserIdAlertConfigDelete(idAlertConfig: number, xToken: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteAlertConfigByUserAndIdAlertConfigApiV1AlertConfigByuserIdAlertConfigDelete(idAlertConfig: number, xToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteAlertConfigByUserAndIdAlertConfigApiV1AlertConfigByuserIdAlertConfigDelete(idAlertConfig: number, xToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteAlertConfigByUserAndIdAlertConfigApiV1AlertConfigByuserIdAlertConfigDelete(idAlertConfig: number, xToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idAlertConfig === null || idAlertConfig === undefined) {
            throw new Error('Required parameter idAlertConfig was null or undefined when calling deleteAlertConfigByUserAndIdAlertConfigApiV1AlertConfigByuserIdAlertConfigDelete.');
        }

        if (xToken === null || xToken === undefined) {
            throw new Error('Required parameter xToken was null or undefined when calling deleteAlertConfigByUserAndIdAlertConfigApiV1AlertConfigByuserIdAlertConfigDelete.');
        }

        let headers = this.defaultHeaders;
        if (xToken !== undefined && xToken !== null) {
            headers = headers.set('x-token', String(xToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/api/v1/alert-config/byuser/${encodeURIComponent(String(idAlertConfig))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Getrequestedservicecompletebyuserandidalertconfig
     * Get Complete AlertConfiguration by User
     * @param idAlertConfig 
     * @param xToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRequestedServiceCompleteByUserAndIdAlertConfigApiV1AlertConfigByuserCompleteIdAlertConfigGet(idAlertConfig: number, xToken: string, observe?: 'body', reportProgress?: boolean): Observable<AlertConfigCompleteGetDTO>;
    public getRequestedServiceCompleteByUserAndIdAlertConfigApiV1AlertConfigByuserCompleteIdAlertConfigGet(idAlertConfig: number, xToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlertConfigCompleteGetDTO>>;
    public getRequestedServiceCompleteByUserAndIdAlertConfigApiV1AlertConfigByuserCompleteIdAlertConfigGet(idAlertConfig: number, xToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlertConfigCompleteGetDTO>>;
    public getRequestedServiceCompleteByUserAndIdAlertConfigApiV1AlertConfigByuserCompleteIdAlertConfigGet(idAlertConfig: number, xToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idAlertConfig === null || idAlertConfig === undefined) {
            throw new Error('Required parameter idAlertConfig was null or undefined when calling getRequestedServiceCompleteByUserAndIdAlertConfigApiV1AlertConfigByuserCompleteIdAlertConfigGet.');
        }

        if (xToken === null || xToken === undefined) {
            throw new Error('Required parameter xToken was null or undefined when calling getRequestedServiceCompleteByUserAndIdAlertConfigApiV1AlertConfigByuserCompleteIdAlertConfigGet.');
        }

        let headers = this.defaultHeaders;
        if (xToken !== undefined && xToken !== null) {
            headers = headers.set('x-token', String(xToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AlertConfigCompleteGetDTO>('get',`${this.basePath}/api/v1/alert-config/byuser/complete/${encodeURIComponent(String(idAlertConfig))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Getrequestedservicecompletebyuser
     * Get Complete AlertConfiguration by User
     * @param xToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRequestedServiceCompleteByUserApiV1AlertConfigByuserCompleteGet(xToken: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AlertConfigCompleteGetDTO>>;
    public getRequestedServiceCompleteByUserApiV1AlertConfigByuserCompleteGet(xToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AlertConfigCompleteGetDTO>>>;
    public getRequestedServiceCompleteByUserApiV1AlertConfigByuserCompleteGet(xToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AlertConfigCompleteGetDTO>>>;
    public getRequestedServiceCompleteByUserApiV1AlertConfigByuserCompleteGet(xToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xToken === null || xToken === undefined) {
            throw new Error('Required parameter xToken was null or undefined when calling getRequestedServiceCompleteByUserApiV1AlertConfigByuserCompleteGet.');
        }

        let headers = this.defaultHeaders;
        if (xToken !== undefined && xToken !== null) {
            headers = headers.set('x-token', String(xToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AlertConfigCompleteGetDTO>>('get',`${this.basePath}/api/v1/alert-config/byuser/complete/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Getrequestedservicepartialbyuser
     * Get Complete AlertConfiguration by User
     * @param xToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRequestedServicePartialByUserApiV1AlertConfigByuserPartialGet(xToken: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AlertConfigPartialGetDTO>>;
    public getRequestedServicePartialByUserApiV1AlertConfigByuserPartialGet(xToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AlertConfigPartialGetDTO>>>;
    public getRequestedServicePartialByUserApiV1AlertConfigByuserPartialGet(xToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AlertConfigPartialGetDTO>>>;
    public getRequestedServicePartialByUserApiV1AlertConfigByuserPartialGet(xToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xToken === null || xToken === undefined) {
            throw new Error('Required parameter xToken was null or undefined when calling getRequestedServicePartialByUserApiV1AlertConfigByuserPartialGet.');
        }

        let headers = this.defaultHeaders;
        if (xToken !== undefined && xToken !== null) {
            headers = headers.set('x-token', String(xToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AlertConfigPartialGetDTO>>('get',`${this.basePath}/api/v1/alert-config/byuser/partial/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Insertnewalertconfigendpoint
     * 
     * @param body 
     * @param xToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public insertNewAlertConfigEndPointApiV1AlertConfigPost(body: InsertAlertConfigDTO, xToken: string, observe?: 'body', reportProgress?: boolean): Observable<InsertAlertConfigDTO>;
    public insertNewAlertConfigEndPointApiV1AlertConfigPost(body: InsertAlertConfigDTO, xToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InsertAlertConfigDTO>>;
    public insertNewAlertConfigEndPointApiV1AlertConfigPost(body: InsertAlertConfigDTO, xToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InsertAlertConfigDTO>>;
    public insertNewAlertConfigEndPointApiV1AlertConfigPost(body: InsertAlertConfigDTO, xToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling insertNewAlertConfigEndPointApiV1AlertConfigPost.');
        }

        if (xToken === null || xToken === undefined) {
            throw new Error('Required parameter xToken was null or undefined when calling insertNewAlertConfigEndPointApiV1AlertConfigPost.');
        }

        let headers = this.defaultHeaders;
        if (xToken !== undefined && xToken !== null) {
            headers = headers.set('x-token', String(xToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InsertAlertConfigDTO>('post',`${this.basePath}/api/v1/alert-config`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
