import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-geoss-services',
  templateUrl: './about-geoss-services.component.html',
  styleUrls: ['./about-geoss-services.component.css']
})
export class AboutGeossServicesComponent implements OnInit {

  constructor() { }

  buttonOpen:Array<boolean> = [false,false,false,false];


  ngOnInit(): void {
  }

  buttonOpenClick(id: number) {this.buttonOpen[id] = !this.buttonOpen[id]}
}
