<!-- Sezione Titolo + 1° Testo -->
<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Water Quality</h3>

      <div class="accordion" id="accordion1">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingOne">
            <button class="accordion-button bg-primary" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Service Description
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              The water quality in open surface water reservoirs in the pilot areas needs to be monitored in order to support and optimize the operational management of the reservoirs. The traditional in situ measurements can only be done at selected points. This information can be supported by frequently monitoring the entire surface of the water body by using satellite-based services, providing an additional data source for evidence based WFD reporting. Topics of this service are the identification of long-term seasonal trends or increase of yellow substances through main indicators such as Chlorophyll-a, Secchi Disk Depth, Colored Dissolved Organic Matter (CDOM), and Water Surface Temperature.
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion2">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingTwo">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Service Results
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              The water quality features’ changes service is providing results for different open surface water reservoirs in the pilot areas in Spain, Greece, Germany, and Finland. For all selected pilot areas, first results are generated, and currently being validated to show the estimation accuracy with in situ data. Different features are approximated depending on the water body properties. While the chlorophyll-a content is of interest for all reservoirs, the CDOM content is not required for each one, but only at specific locations, such as the Carlsfeld reservoir in Saxony.<br><br>

              With the abundance of in situ data there are different analyses being undertaken. Directly estimated features are compared, e.g. remotely sensed Chlorophyll-a data compared to in situ measurements of Chlorophyll-a or the Turbidity. Moreover, there are proxy analyses undertaken for the Saxony pilot case. In this use case, available in situ data for features, such as Phosphorous or Nitrogen, are correlated to the water quality features, which were indirectly approximated from the satellite imagery, due to missing optical spectral signal of Phosphorous or Nitrogen.<br><br>

              Additional work in the scope of service development is conducted regarding hyperspectral data. Data from the Italian PRISMA satellite is employed. First tests have shown great potential. Aim is to increase the potential of the physics-based water quality algorithms applied by EOMAP. For example, the differentiation of algal species can possibly be enabled by using the additional spectral features of this type of imagery. Future tests will reveal the potential of the hyperspectral methodology in water quality features estimation, e.g. for Chlorophyll-a estimation.
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion3">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingThree">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Gain for the user
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              The added value of remote sensing data becomes apparent when looking at the images from 2018-09-19 shown below. The images show a part of the Saidenbach reservoir in the Saxony pilot region.<br><br>

              In the German pilot case -the so-called 'Vorsperren' or pre-reservoirs – have an important role in decelerating the entry of nutrients into the open surface water reservoirs used for drinking water production. The status of these reservoirs can be frequently observed from space for their whole spatial extent. This provides a time advantage for managing the inflow based on evidence and real time conditions.
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion4">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingThree">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              Service Specifications
            </button>
          </h2>
          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              <b>Water Quality Feature Change Monitoring Product (*)</b>
              <br><br>
              <b>Type</b>: Slow Processes - Monitoring/Emergency (**)
              <br>
              <b>Time coverage/frequency</b>: daily ~ weekly 
              <br>
              <b>Availability</b>: systematic (depending on operational system configuration) or on demand 
              <br><br>
              <i><u>Input parameters</u></i>
              <br>
              <i>Area</i>: AoI in the form of shapefile or coordinates
              <br>
              <i>Time</i>: Selected date(s) or given period of time 
              <br><br>
              <i><u>Input data</u></i>
              <br>
              <i>EO satellite data</i>: Sentinel 3A/B; Sentinel-2A/B; Landsat 5/7/8/9; (PlanetDoves and WorldView-3 tested during the project, with an additional cost)
              <br>
              <i>Spatial resolution</i>: 300 m - 10 m (PlanetDoves: 3 m, WorldView-3: 2 m, both tested during the project)
              <br>
              <br>
              <i>Temporal Resolution</i>:
              <br>
              <ul>
                <li><i>Sentinel-2: 5 days (depending on overflight paths)</i></li> 
                <li><i>Landsat 5: 1984 until 2012, every 16 days</i></li> 
                <li><i>Landsat 7: 1999 until 2021, every 16 days</i></li> 
                <li><i>Landsat 8: since 2013, every 16 days</i></li> 
                <li><i>Landsat 9: since 2021, every 16 days</i></li> 
                <li><i>Sentinel-3: since 2016, up to daily</i></li> 
              </ul>
              <br>
              <i>Additional data to use (in case of service expansion and user request):</i>
              <br>
              <ul>
                <li><i>WorldView-2,3 data, since 2009, up to daily </i></li> 
                <li><i>PlanetSuperDoves, since 2021, up to daily</i></li> 
              </ul>
              <br>
              <i><u>Output product</u></i>
              <br>
              <i>Format</i>: GeoTIFF raster file (additional tabular format delivery possible)
              <br>
              <i>Spatial resolution</i>: 300 m -10 m for optical sensors, 100 - 1000 m for temperature sensors 
              <br>
              <i>Size [bytes]</i>: Depends on the size of the AoI, the selected sensor and its spatial resolution 
              <br>
              [<i>Product</i>: Selection of water quality parameters which are required (CDM, CHL, SDD, SST, TUR) (***)]
              <br>
              <br>
              <i>Short description</i>:
              <br>
              <ul>
                <li><b>Chlorophyll-a (CHL)</b> in (µg/l): EOMAP offers a harmonized, standardized, Chlorophyll-a indicator for a range of sensors. This is based on the derived information of in-water organic absorption, in-water turbidity and spectral characteristics of each water body. Chlorophyll values vary over 4 magnitudes, for marine waters or clear lakes typical concentrations between 0.01 and 10 µg/l, while for eutrophic lakes concentrations can reach 100 µg/l and more.</li>
                <li><b>Coloured Dissolved Organic Matter (CDM) </b>in (1/m) comprises all dissolved organic matter, which influences the water color. It consists of humic and fulvic acids. CDOM is measured in terms of absorption at a specific reference wavelength, which is defined at 440nm.</li>
                <li><b>Secchi Depth (SDD) </b>in (m) or transparency indicates the clarity in the water column. The related measure of Secchi Depth (measured with a Secchi disk) is a common parameter in water quality analysis. Visibility is related to the euphotic zone in the water and is a useful information source for divers. The SDD product is derived from the in-water scattering properties and is expressed in meters (m).</li>
                <li><b>Sea surface temperature (SST) </b>is calculated from thermal infrared channels of various sensors and indicates the temperature of the uppermost layer of the water column.</li>
                <li><b>Turbidity (TUR) </b>is a key parameter of water quality and is linearly related to the backward scattering of light of organic and inorganic particles in water. Turbidity is determined by the backward scattering of light between 450 to 800nm, physically retrieved using satellite data. The measurement unit is Nephelometric Turbidity Unit (NTU), which is similar to Formazine Turbidity Unit (FTU). It is linearly related to TSM (Total Suspended Matter) at low to moderate turbidity values.</li>
              </ul>
              <br>
              <i><u>Metadata information including (e.g.)</u></i>:
              <ul>
                <li>cloud coverage (as found in metadata of the product)</li>
                <li>quality assessment of the provided product (Total Quality, QUT)</li>
                <li>product description, keywords, essential variables</li>
                <li>extent of processed image</li>
                <li>Date and time</li>
                <li>Data Soruce</li>
                <li>Service Provider</li>                
              </ul>            
              <br>
              <i><u>Dissemination</u></i>:
              <ul>
                <li>FTP</li>
                <li>OGC Web Services & APIs</li>
              </ul>
              <br>
              <i><u>Timeliness</u></i>:
              <ul>
                <li>Upon availability of input data from DIAS provider (normally a few hours after sensing). /li>
              </ul>
              <br>
              <br>
              <i><u>Literature</u></i>:<br>
              Dörnhöfer, K., Klinger, P., Heege, T., Oppelt, N. (2017): Multi-sensor satellite and in situ monitoring of phytoplankton development in a eutrophic-mesotrophic lake. Science of the Total Environment 612C (2018) pp. 1200-1214 DOI information: 10.1016/j.scitotenv.2017.08.219 
              <br>
              <br>
              Bresciani M., Giardino C., Stroppiana D., Dessena M.A., Buscarinu P., Cabras L, Schenk K., Heege T., Bernert H., Bazdanis G. & Tzimas A. (2019): Monitoring water quality in two dammed reservoirs from multispectral satellite data, European Journal of Remote Sensing, 10 pages, DOI: 10.1080/22797254.2019.1686956 
              <br>
              <br>
              Karle, N., Wolf, T., Heege, T., Schenk, K., Klinger, P., Schulz, K. (2019): Satellite Remote Sensing of Chlorophyll and Secchi Depth for Monitoring Lake Water Quality – A Validation Study. Processing for the SPIE remote sensing publication conf 10.Sept. 19, Strasbourg
              <br><br>
              <br><br>
              (*) Products are free during the project; on payment after or free as CEMS service; products can be also accessed and handled as WQeMS services.  
              <br>
              (**) The service is classified with reference to the type of process (Fast/Slow) and the scope, namely, Monitoring/Emergency . 
              <br>
              (***) EOMAP processing. 
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</div>


<!-- Sezione Carousel -->
<div class="row">
  <div class="col-12">
      <div class="bg-light p-4 mb-5 rounded">

          <div class="row justify-content-center">
              <div class="col-12 col-xl-10">
          
                      <ngb-carousel *ngIf="images">
                          <ng-template ngbSlide *ngFor="let image of images">
                              <div class="picsum-img-wrapper">
                                  <img [src]="image" alt="WQeMS">
                              </div>
                          </ng-template>
                      </ngb-carousel>
          
              </div>
          </div>

      </div>
  </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
  <div class="col-12">
      <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
          <p class="text-start lead">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore
              magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
              ut labore
              et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt
              ut labore et dolore magna aliqua.
          </p>
      </div>
  </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
  <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
      <a href="request-service/" type="button" class="btn btn-secondary btn-lg pt-2 pb-2">
          Request a Service <fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
  </div>
</div>