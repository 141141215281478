<ng-template #popContentTitle>Give your configuration a title to distinguish it from the others.</ng-template>
<ng-template #popContentWaterBody>Select from the list the water body for which you want to create the configuration. Only AOIs for which a continous monitoring is currently active can be selected.</ng-template>
<ng-template #popContentRule>Create one or more rules. You will be notified if at least one of the rules defined is fulfilled.</ng-template>
<ng-template #popContentService>Select a phenomena for which you wish to activate the rule.</ng-template>
<ng-template #popContentParameter>Select the measure to be applied to the phenomena.</ng-template>
<ng-template #popContentTypeRule>Select the relationship to apply on the measure against the specified value(s).</ng-template>
<ng-template #popContentCrowdsourcingAlert>Select one or more categories in order to be notified when related events are detected on social media and crowdsourcing apps.</ng-template>
<ng-template #popContentNotificationMethod>Indicate how you want to receive alerts. You can choose email or, if you own a dedicated system with APIs, by HTTP POST requests.</ng-template>


<div class="row">
   <div class="col-12">
      <div class="bg-light p-4 pt-2 pb-2 mb-4 rounded">
         <h3>Alert Configurations</h3>
      </div>
   </div>
</div>

<div *ngIf="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOs == undefined" class="d-flex justify-content-center">
   <div class="spinner-border text-primary" role="status">
     <span class="visually-hidden">Loading...</span>
   </div>
 </div>

<div *ngIf="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOs != undefined">
   <div class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-4 pb-2 mb-4 rounded">
            <div class="row">
               <div class="col-12">

                  <div class="input-group mb-3">
                     <span class="input-group-text" id="basic-addon1">Configuration Name</span>
                     <input [(ngModel)]="insertAlertConfigDTO.title" type="text" class="form-control"
                        placeholder="My alert configuration" aria-label="Username" aria-describedby="basic-addon1">
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row">
      <div class="col-12 mt-3 mb-3">
         <h4>Water Body <fa-icon icon="circle-info" [ngbPopover]="popContentWaterBody"></fa-icon></h4>
      </div>
   </div>

   <div class="row mb-4">
      <div class="col-12 rounded">
         <div id="map" class="rounded"></div>
      </div>
   </div>

   <div class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-4 pb-2 mb-4 rounded">
            <div class="row">
               <div class="col-12">

                  <div class="mb-3">
                     <label for="form-select" class="form-label">Select a Water Body</label>
                     <select name="selectWaterBodyName"
                        [(ngModel)]="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected"
                        class="form-select" id="exampleFormControlSelect1" required=""
                        (click)="onChangeWaterBodySelected()">
                        <option [ngValue]="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTO"
                           *ngFor="let servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTO of servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOs; index as index">
                           {{servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTO.waterBodyNameText}}</option>
                     </select>
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row">
      <div class="col-12 mt-3 mb-3">
         <h4>Rules <fa-icon icon="circle-info" [ngbPopover]="popContentRule"></fa-icon></h4>
      </div>
   </div>

   <div class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-4 pb-4 mb-4 rounded"
            *ngFor="let alertConfigAlertConditionDTO of alertConfigAlertConditionDTOs; index as index">
            <div class="row">
               <div class="col-12">

                  <div class="row">
                     <div class="col-md-6 mb-3">
                        <div class="mb-3"
                           *ngIf="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected != undefined">
                           <label for="form-select" class="form-label">Select a phenomena <fa-icon icon="circle-info" [ngbPopover]="popContentService"></fa-icon></label>
                           <select (change)="onChangeService(index)"
                              #servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBody
                              [(ngModel)]="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds[index]"
                              class="form-select" for="" id="exampleFormControlSelect1" required="">
                              <option
                                 [ngValue]="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBody"
                                 *ngFor="let servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBody of servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected.continuousMonitoringWaterBody; index as index">
                                 {{servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBody.humanReadableServiceName}}
                              </option>
                           </select>
                        </div>
                     </div>
                     <div class="col-md-6 mb-3">
                        <div class="mb-3"
                           *ngIf="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds[index]!=undefined">
                           <label for="form-select" class="form-label">Select a measure <fa-icon icon="circle-info" [ngbPopover]="popContentParameter"></fa-icon></label>
                           <select #slectedServiceHtml
                              (click)="onChangeServiceSelectedOnAlertConfigAlertCondition(index, slectedServiceHtml.value)"
                              class="form-select" aria-label="Default select example">
                              <option
                                 value="{{servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelected.name}}"
                                 *ngFor="let servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelected of servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds[index].measureTypes; index as indexMeasureType">
                                 {{servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelected.humanReadableName}}
                              </option>
                           </select>
                        </div>
                     </div>
                  </div>

                  <div class="row">
                     <div class="col-12 mb-3 text-center"
                        *ngIf="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds[index]!=undefined && insertAlertConfigDTO!.alertConfigAlertConditions![index]!= undefined  && insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType != '' && insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType!= undefined && measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).unitOfMeasureAbbreviation == 'boolean'">
                        <div class="form-check form-check-inline">
                           <input (change)="onChangeCheckBoxOccurence(index, $event)" class="form-check-input"
                              type="checkbox" id="inlineCheckbox1">
                           <label class="form-check-label" for="inlineCheckbox1">Occurrence</label>
                        </div>
                     </div>
                  </div>

                  <div class="row"
                     *ngIf="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds[index]!=undefined && insertAlertConfigDTO!.alertConfigAlertConditions![index]!= undefined && insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType != ''  && insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType!= undefined && measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).unitOfMeasureAbbreviation != 'boolean'">
                     <div class="col-md-6 mb-3">
                        <div class="mb-3"
                           *ngIf="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds[index]!=undefined">
                           <label for="form-select" class="form-label">Select a relationship <fa-icon icon="circle-info" [ngbPopover]="popContentTypeRule"></fa-icon></label>
                           <select name="selectedTypeThereshold" [(ngModel)]="selectedTypeThereshold[index]"
                              (change)="onChangeSelectableTypeThereshold(selectedTypeThereshold, index)"
                              class="form-select" aria-label="Default select example">
                              <option value="between">between</option>
                              <option value="less-than">less than</option>
                              <option value="greater-than">greater than</option>
                           </select>
                        </div>
                     </div>
                     <div class="col-md-6 mb-3 text-center">
                        <div class="mb"
                           *ngIf="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds[index]!=undefined && insertAlertConfigDTO!.alertConfigAlertConditions![index]!=undefined && insertAlertConfigDTO!.alertConfigAlertConditions![index].nameMeasureType != ''">
                           <div *ngIf="selectedTypeThereshold[index] == 'between'">
                              <label for="ex3" class="form-label">Lower Limit</label>
                              <input
                                 [step]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).stepValue"
                                 [max]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).maxPossible"
                                 [min]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).minPossible"
                                 [(ngModel)]="insertAlertConfigDTO!.alertConfigAlertConditions![index]!.minValue"
                                 type="number" id="ex3" class="form-control">
                              <input
                                 [step]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).stepValue"
                                 [max]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).maxPossible"
                                 [min]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).minPossible"
                                 [(ngModel)]="insertAlertConfigDTO!.alertConfigAlertConditions![index]!.minValue"
                                 type="range" class="form-range" id="customRange1">
                              <label for="ex4" class="form-label">Upper Limit</label>
                              <input
                                 [step]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).stepValue"
                                 [max]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).maxPossible"
                                 [min]="insertAlertConfigDTO!.alertConfigAlertConditions![index].minValue!"
                                 [(ngModel)]="insertAlertConfigDTO!.alertConfigAlertConditions![index]!.maxValue"
                                 type="number" id="ex4" class="form-control">
                              <input
                                 [step]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).stepValue"
                                 [max]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).maxPossible"
                                 [min]="insertAlertConfigDTO!.alertConfigAlertConditions![index].minValue!"
                                 [(ngModel)]="insertAlertConfigDTO!.alertConfigAlertConditions![index]!.maxValue"
                                 type="range" class="form-range" id="customRange1">
                              <small
                                 style="text-align: center;">{{measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType,
                                 index).unitOfMeasureAbbreviation}}</small>
                           </div>
                           <div *ngIf="selectedTypeThereshold[index] == 'less-than'">
                              <label for="ex4" class="form-label">Upper Limit</label>
                              <input
                                 [step]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).stepValue"
                                 [max]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).maxPossible"
                                 [min]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).minPossible"
                                 [(ngModel)]="insertAlertConfigDTO!.alertConfigAlertConditions![index]!.maxValue"
                                 type="number" id="ex4" class="form-control">
                              <input
                                 [step]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).stepValue"
                                 [max]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).maxPossible"
                                 [min]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).minPossible"
                                 [(ngModel)]="insertAlertConfigDTO!.alertConfigAlertConditions![index]!.maxValue"
                                 type="range" class="form-range" id="customRange1">
                              <small
                                 style="text-align: center;">{{measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType,
                                 index).unitOfMeasureAbbreviation}}</small>

                           </div>
                           <div *ngIf="selectedTypeThereshold[index] == 'greater-than'">
                              <label for="ex3" class="form-label">Lower Limit</label>
                              <input
                                 [step]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).stepValue"
                                 [max]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).maxPossible"
                                 [min]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).minPossible"
                                 [(ngModel)]="insertAlertConfigDTO!.alertConfigAlertConditions![index]!.minValue"
                                 type="number" id="ex3" class="form-control">
                              <input
                                 [step]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).stepValue"
                                 [max]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).maxPossible"
                                 [min]="measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType, index).minPossible"
                                 [(ngModel)]="insertAlertConfigDTO!.alertConfigAlertConditions![index]!.minValue"
                                 type="range" class="form-range" id="customRange1">
                              <small
                                 style="text-align: center;">{{measureTypeByName(insertAlertConfigDTO!.alertConfigAlertConditions![index]!.nameMeasureType,
                                 index).unitOfMeasureAbbreviation}}</small>
                           </div>

                        </div>
                     </div>
                  </div>

                  <div class="row">
                     <div class="col-12 text-center">
                        <button class="btn btn-outline-secondary" type="button" (click)="onClickDeleteRule(index)">
                           <fa-icon icon="trash"></fa-icon> Delete Rule
                        </button>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row">
      <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-2 mb-5">
         <button type="button" class="btn btn-outline-secondary btn-lg pt-2 pb-2" (click)="onClickAddRule()"
            [disabled]="servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected == undefined ? '': false">
            Add Rule <fa-icon icon="plus" class="right"></fa-icon>
         </button>
      </div>
   </div>

   <div class="row">
      <div class="col-12 mt-3 mb-3">
         <h4>Crowdsourcing Alerts <fa-icon icon="circle-info" [ngbPopover]="popContentCrowdsourcingAlert"></fa-icon></h4>
      </div>
   </div>

   <div class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-4 pb-2 mb-4 rounded">
            <div class="row">
               <div class="col-12 mb-3">

                  <!-- <div class="form-group">
                     <label for="exampleFormControlSelect2" class="form-label">Select categories of events</label>
                     <select name="selectedServicesName" [(ngModel)]="insertAlertConfigDTO.nameSocialAlertTypes" multiple
                        class="form-select" id="exampleFormControlSelect2" required="True">
                        <option value="{{socialAlertTypeGetDTO.name}}"
                           *ngFor="let socialAlertTypeGetDTO of socialAlertTypeGetDTOs; index as index">
                           {{socialAlertTypeGetDTO.humanReadableName}}</option>
                     </select>
                  </div> -->

                  <div class="form-group">
                     <label for="exampleFormControlSelect2" class="form-label">Select categories of events</label>
                     <div name="selectedServicesName">
                        <div *ngFor="let socialAlertTypeGetDTO of socialAlertTypeGetDTOs; index as index" class="checkbox form-check form-switch">
                              <input class="form-check-input" type="checkbox" value="{{socialAlertTypeGetDTO.name}}" name="{{socialAlertTypeGetDTO.name}}"  (change) ="updateSelectedSocialAlertTypes($event)" />
                              <label class="form-check-label" for="{{socialAlertTypeGetDTO.name}}">{{socialAlertTypeGetDTO.humanReadableName}}</label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row">
      <div class="col-12 mt-3 mb-3">
         <h4>Notification Methods <fa-icon icon="circle-info" [ngbPopover]="popContentNotificationMethod"></fa-icon></h4>
      </div>
   </div>

   <div class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-4 pb-4 mb-4 rounded"
            *ngFor="let notificationMethodDTO of notificationMethodDTOs; index as index">
            <div class="row">
               <div class="col-12">

                  <div class="row">
                     <div class="col-md-6 mb-3">
                        <div class="mb-3">
                           <label for="form-select" class="form-label">Select a Notification Method</label>
                           <select #selectedNotificationType class="form-select" [(ngModel)]="notificationMethodDTO.type"
                              aria-label="Default select example" (click)="onChangeTypeOfNotification(selectedNotificationType.value)">
                              <option selected></option>
                              <option value="email">Email</option>
                              <option value="http">HTTP POST request</option>
                           </select>
                        </div>
                     </div>
                     <div class="col-md-6 mb-3">
                        <div class="mb-3">
                           <label for="exampleFormControlInput1" class="form-label">Address</label>
                           <input *ngIf="notificationMethodDTO.type=='email'" type="email" class="form-control"
                              [(ngModel)]="notificationMethodDTO.value" id="exampleFormControlInput1"
                              placeholder="email@email.com">
                           <input *ngIf="notificationMethodDTO.type=='http'" type="url" class="form-control"
                              [(ngModel)]="notificationMethodDTO.value" id="exampleFormControlInput1"
                              placeholder="http://testendpoint.com/">
                        </div>
                     </div>
                  </div>

                  <div class="row">
                     <div class="col-12 text-center">
                        <button class="btn btn-outline-secondary" type="button"
                           (click)="onClickDeleteNotificationMethod(index)">
                           <fa-icon icon="trash"></fa-icon> Delete Notification Method
                        </button>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row">
      <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-2 mb-5">
         <button type="button" class="btn btn-outline-secondary btn-lg pt-2 pb-2" (click)="onClickAddNotificationMethod()">
            Add Notification Method<fa-icon icon="plus" class="right"></fa-icon>
         </button>
      </div>
   </div>

   <div class="row">
      <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
         <button type="button" class="btn btn-secondary btn-lg pt-2 pb-2" (click)="onClickConfirm()">
            Create <fa-icon icon="arrow-right" class="right"></fa-icon>
         </button>
      </div>
   </div>
</div>