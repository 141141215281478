import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { MyAlertConfigurationDetailsModalComponent } from 'src/app/components/modals/my-alert-configuration-details-modal/my-alert-configuration-details-modal.component';
import { InfoModalsService } from 'src/app/services/interface/info-modals.service';
import { AlertAPIsService, AlertGetDTO } from 'src/app/services/restapiservices';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-my-alerts',
  templateUrl: './my-alerts.component.html',
  styleUrls: ['./my-alerts.component.css']
})
export class MyAlertsComponent implements OnInit {

  constructor(public infoModalService: InfoModalsService, private alertApiService: AlertAPIsService, private keycloakService: KeycloakService, private modalService: NgbModal, private route: Router) { }

  alertGetDTOs: AlertGetDTO[] | undefined;
  @ViewChild('table') table: any;

  ngOnInit(): void {
    this.keycloakService.getToken().then(token => {
      this.alertApiService.findAlertByUserApiV1AlertByUserGet(token).subscribe(data => {
        this.alertGetDTOs = data;
      }, error => {
        if (error.status == 404) {
          this.infoModalService.openInfoModal("Info", "There are no alerts received till now!")
          this.route.navigateByUrl('home')  
        } else {
          this.infoModalService.openInfoModal("Error", "An error occours, " + error.details)
          this.route.navigateByUrl('home')
        }
      })
    })
    
  }

  openModal(idAlertConfig: number | undefined) {

    if (idAlertConfig == undefined) {
      alert("Undefined...")
      return;
    }

    console.log("id    " + idAlertConfig)

    const modalRef = this.modalService.open(MyAlertConfigurationDetailsModalComponent);
    modalRef.componentInstance.idAlertConfig = idAlertConfig;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  search(): void {
    var input, filter, table, tr, td, i, txtValue;
    input = <HTMLInputElement>document.getElementById("search-input");
    filter = input!.value.toUpperCase();
    table = document.getElementById("table");
    tr = table?.getElementsByTagName("tr");
    console.log(tr!.length)
    
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr!.length; i++) {
      txtValue = ''
      for (let j = 0; j<tr![i].getElementsByTagName("td").length; j++) {
        
        td = tr![i].getElementsByTagName("td")[j];
        if(td) {
          txtValue = txtValue + td.textContent || td.innerText;
        }

      }
      console.log(txtValue)
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr![i].style.display = "";
      } else {
        tr![i].style.display = "none";
      }
      
      
    }
  }

  


}
