import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPendingRequestListModalComponent } from 'src/app/components/modals/user-pending-request-list-modal/user-pending-request-list-modal.component';

@Component({
  selector: 'app-administration-dashboard',
  templateUrl: './administration-dashboard.component.html',
  styleUrls: ['./administration-dashboard.component.scss']
})
export class AdministrationDashboardComponent implements OnInit {

  constructor( private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onClickManagePendingRequest() {
    const modalRef = this.modalService.open(UserPendingRequestListModalComponent, {size: 'modal-lg'});
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  } 

}
