import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AlertConfigurationAPIsService, SocialAlertAPIsService, SocialAlertTypeAPIsService, SocialAlertTypeGetDTO, UserAPIsService } from 'src/app/services/restapiservices';
import { KeycloakService } from 'keycloak-angular';
import { ServicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTO, ServicesMeasureTypesWaterBodyRolesContinuousMonitoringGetContinuousMonitoringWaterBodyItemDTO, ServicesMeasureTypesWaterBodyRolesContinuousMonitoringGetMeasureTypeDTO } from 'src/app/services/restapiservices';
import * as L from 'leaflet';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { InsertAlertConfigDTO, NotificationMethodDTO, AlertConfigAlertConditionDTO } from 'src/app/services/restapiservices';
import { NONE_TYPE } from '@angular/compiler';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from 'src/app/components/modals/info-modal/info-modal.component';
import { InfoModalsService } from 'src/app/services/interface/info-modals.service';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-alert-configuration',
  templateUrl: './alert-configuration.component.html',
  styleUrls: ['./alert-configuration.component.scss']
})
export class AlertConfigurationComponent implements OnInit/*, AfterViewInit*/ {

  public alertConfigAlertConditionDTOs: AlertConfigAlertConditionDTO[] = [];
  public notificationMethodDTOs: NotificationMethodDTO[] = [];

  public insertAlertConfigDTO: InsertAlertConfigDTO = {
    localIdWaterBody: '',
    title: 'My alerts configuration',
    nameSocialAlertTypes: [],
    notificationMethods: [],
    alertConfigAlertConditions: []
  };

  private map: any;

  socialAlertTypeGetDTOs: SocialAlertTypeGetDTO[] = [];
  servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected?: ServicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTO | any;
  servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelected?: any;
  selectedTypeThereshold: string[] = [];
  servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds: ServicesMeasureTypesWaterBodyRolesContinuousMonitoringGetMeasureTypeDTO[] | any[] = [];
  token: string = ''
  // servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOs: ServicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTO[] = [];
  servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOs!: ServicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTO[]
  public userProfile: KeycloakProfile | null = null;
  public isLoggedIn = false;

  constructor(private modalService: NgbModal,
    private router: Router, 
    private userAPIsService: UserAPIsService,
    private keycloakService: KeycloakService, 
    private alertConfigurationAPIsService: AlertConfigurationAPIsService, 
    private socialAlertTypeAPIsService: SocialAlertTypeAPIsService,
    public infoModalService: InfoModalsService) { }
  

  public async ngOnInit() {
    this.keycloakService.getToken().then(token => {
      this.token =token;
      this.userAPIsService.getWaterBodiesServicesAndMeasureTypesByRoleAndContinuousMonitoringApiV1UserServicesWaterbodiesRoleContinuousMonitoringGet(token).subscribe(data => {
        this.servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOs = data;
        console.log(this.servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOs)
        this.socialAlertTypeAPIsService.getAllSocialAlertTypeApiApiV1SocialAlertTypeAllGet(token).subscribe(data => {
          this.socialAlertTypeGetDTOs = data
          console.log(data)
          this.initMap()
        }, error => {
          console.error(error)
          alert("An error occours. Plase, try later...")
        })
      }, error => {
        console.error(error)
      })
    })

    this.isLoggedIn = await this.keycloakService.isLoggedIn();

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloakService.loadUserProfile();
    }
  }
  

  // ngAfterViewInit(): void {
  //   this.initMap()
  // }

  private initMap(): void {
    this.map = L.map('map', {
      center: [ 51.90683463077498, 20.319029203944183 ],
      zoom: 4
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
    this.map.attributionControl.setPrefix(false);
  }

  changeMapCenter(lat: number, lon: number) {
    this.map.panTo(new L.LatLng(lon, lat));
    this.map.setZoom(14)
  }


  onClickAddRule() {
    // console.log("onClickAddRole")
    let alertConfigAlertConditionDTO: AlertConfigAlertConditionDTO = {maxValue: 0, minValue: 0, nameMeasureType: ''}
    this.alertConfigAlertConditionDTOs.push(alertConfigAlertConditionDTO)
    this.selectedTypeThereshold.push('')
  }

  onClickAddNotificationMethod() {
    let notificationMethodDTO: NotificationMethodDTO = {value: '', type: 'email'}
    this.notificationMethodDTOs.push(notificationMethodDTO)
    this.insertAlertConfigDTO.notificationMethods = this.notificationMethodDTOs;
  }

  onChangeWaterBodySelected() {
    this.insertAlertConfigDTO!.localIdWaterBody = this.servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected?.waterBodyLocalId
    if (this.servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected !== undefined) {this.changeMapCenter(this.servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected?.waterBodyCoordinates[0] as number, this.servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelected?.waterBodyCoordinates[1] as number)}
  }

  
  onChangeTypeOfNotification(selectedNotificationType: string) {
    if (selectedNotificationType == 'http') {
      // console.log(selectedNotificationType);
    }
  }

  onChangeServiceSelectedOnAlertConfigAlertCondition(index: number, selectedMeasureType: string) {
    if (this.alertConfigAlertConditionDTOs != undefined) {
      this.alertConfigAlertConditionDTOs[index].nameMeasureType = selectedMeasureType
      this.insertAlertConfigDTO!.alertConfigAlertConditions = this.alertConfigAlertConditionDTOs;
    }
   
  }

  onClickDeleteNotificationMethod(index: number) {
    this.notificationMethodDTOs.splice(index, 1)
    this.insertAlertConfigDTO!.notificationMethods = this.notificationMethodDTOs;
  }

  onClickDeleteRule(index: number) {
    this.alertConfigAlertConditionDTOs.splice(index, 1); 
    this.servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds.splice(index, 1)
    this.selectedTypeThereshold.splice(index, 1)

  } 

  onChangeSelectableTypeThereshold(type: any, index: number) {
    this.insertAlertConfigDTO!.alertConfigAlertConditions![index]!.minValue =null as any;
    this.insertAlertConfigDTO!.alertConfigAlertConditions![index]!.maxValue  = null as any;   
  }

  onClickConfirm() {
    console.log(this.insertAlertConfigDTO)
    this.alertConfigurationAPIsService.insertNewAlertConfigEndPointApiV1AlertConfigPost(this.insertAlertConfigDTO, this.token).subscribe(data => {
      this.infoModalService.openInfoModal("Success!", "New alert configuration created. Congratulations.")
      this.router.navigateByUrl('my-alert-configurations')
    }, error => {
      this.infoModalService.openInfoModal("Error!", "An error occurred, please try again later.")
      this.router.navigateByUrl('home')
    })
  }

  
  measureTypeByName(nameMeasureType: string, index: number) {
    let res: any = this.servicesMeasureTypesWaterBodyRolesContinuousMonitoringGetDTOSelectedContinuousMonitoringWaterBodySelectedMeasureTypeSelecteds[index].measureTypes.filter(function(item: any){
      return item.name == nameMeasureType;         
    })[0];
    return res

  }

  onChangeService(index: number) {
    this.insertAlertConfigDTO!.alertConfigAlertConditions![index]  =  {maxValue: 0, minValue: 0, nameMeasureType: ''}
  }

  onChangeCheckBoxOccurence(index: number, event: any) {
    this.insertAlertConfigDTO!.alertConfigAlertConditions![index]!.maxValue = undefined;
    if (event.target.checked) {
      this.insertAlertConfigDTO!.alertConfigAlertConditions![index]!.minValue = 1;
     
    } else {
      this.insertAlertConfigDTO!.alertConfigAlertConditions![index]!.minValue = 0;
    }
  }

  // insertAlertConfigDTO.nameSocialAlertTypes
  updateSelectedSocialAlertTypes(event: any) {
    if (event.target.checked) {
      if (this.insertAlertConfigDTO!.nameSocialAlertTypes!.indexOf(event.target.name) < 0) { 
        this.insertAlertConfigDTO!.nameSocialAlertTypes!.push(event.target.name);
      }
     } else {
        if (this.insertAlertConfigDTO!.nameSocialAlertTypes!.indexOf(event.target.name) > -1) 
        {
          this.insertAlertConfigDTO!.nameSocialAlertTypes!.splice(this.insertAlertConfigDTO!.nameSocialAlertTypes!.indexOf(event.target.name), 1);              
        }
    } 
    // console.log(this.insertAlertConfigDTO!.nameSocialAlertTypes!)
}


}