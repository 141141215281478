<div class="row">
  <div class="col-12 col-md-8 mx-auto mt-0 mb-5 text-center">
    <img class="img-big" src="../../../assets/img/copernicus/logo-copernicus.png" style="width: 70% !important; padding-bottom: 50px;" alt="Copernicus">
    <p class="lead">
      Copernicus is the European Union's Earth Observation Programme offering information services originating from 
      satellite Earth Observation missions like the Sentinel mission fleets enriched with in-situ and modelled data. 
      This page includes a list of available data repositories and online data services related to Copernicus that can 
      complement the information provided by WQeMS in the context of water quality monitoring.
    </p>
    <p>
      <a target="_blank" href="https://www.copernicus.eu/en/copernicus-services" class="btn btn-outline-primary my-2">
        Go to Copernicus WebSite <fa-icon icon="arrow-right"></fa-icon>
      </a>
    </p>
  </div>
</div>

<!-- 0 -->

<div class="row" id="3">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Copernicus Emergency Management Service</h3>
      <p class="text-start lead mt-4 mb-4">
        The Copernicus Emergency Management Service (CEMS) supports all actors involved in the management of 
        natural or manmade disasters by providing geospatial data and images for informed decision making. 
        CEMS constantly monitors Europe and the globe for signals of an impending disaster or evidence of one 
        happening in real time. The service immediately notifies national authorities of their findings or can 
        be activated on-demand and offers to provide them with maps, time-series or other relevant information 
        to better manage disaster risk. CEMS products are created using satellite, in-situ (non-space) and model data.
      </p>
      <p class="text-end lead mb-4">
        <!--<a href="#" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>-->
        <a (click)="buttonOpenClick(3)" *ngIf="!buttonOpen[3]" class="btn btn-sm btn-primary">Expand <fa-icon icon="expand"></fa-icon></a>
        <a (click)="buttonOpenClick(3)" *ngIf="buttonOpen[3]" class="btn btn-sm btn-secondary">Close <fa-icon icon="times"></fa-icon></a>
      </p>
    </div>
  </div>
</div>

<div class="row justify-content-start" *ngIf="buttonOpen[3]">

  <div class="col-12 col-md-10 offset-md-1">
    <div class="card mb-5">
      <div class="row g-0">
        <div class="col-md-3 p-4 text-center">
          <img src="../../assets/img/copernicus/EFAS.png" class="img-fluid rounded" alt="EFAS">
        </div>
        <div class="col-md-9">
          <div class="card-body lead">
            <h4 class="card-title">EFAS</h4>
            <p class="card-text">
              EFAS is an operational flood forecasting and monitoring system for Europe, part of the CEMS
              Early Warning & Monitoring services, which offers early flood forecasting information to
              support relevant authorities with flood risk management before the occurrence of an event.
              Furthermore, apart from monitoring, the service estimates and produces maps of the socio-economic
              impact of such extreme flood events. Forecasts concern medium-range (10 days), which utilise
              various datasets such as hydrological and numerical weather predictions, among others. This
              service can be used for activating the on-demand rapid mapping component.
            </p>
          </div>
        </div>
        <div class="col-md-12 bg-light">
          <div class="btn-div">
            <a href="https://www.efas.eu/" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-10 offset-md-1">
    <div class="card mb-5">
      <div class="row g-0">
        <div class="col-md-3 p-4 text-center">
          <img src="../../assets/img/copernicus/GLOFAS.png" class="img-fluid rounded" alt="GLOFAS">
        </div>
        <div class="col-md-9">
          <div class="card-body lead">
            <h4 class="card-title">GLOFAS</h4>
            <p class="card-text">
              GloFAS, part of the CEMS Early Warning & Monitoring services, is a similar service to EFAS
              that exploits hydrological forecasting and monitoring, provides probabilistic hydrological
              predictions on scales from days up to 4 months globally. GloFAS products are also based on
              flood forecast simulations as EFAS do. The GFM (Global Flood Monitoring) service/product is
              a complementary component to GloFAS, which provides flood extent maps in a continuous, global
              and systematic manner, among others. The service is principally based on Sentinel-1 data and
              its flood product is an ensemble of three different algorithms, which increases robustness.
              The algorithms do not include time series or any learning procedure. The products are
              typically produced up to 8 hours after satellite data acquisition.
            </p>
          </div>
        </div>
        <div class="col-md-12 bg-light">
          <div class="btn-div">
            <a href="https://www.globalfloods.eu/" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-10 offset-md-1">
    <div class="card mb-5">
      <div class="row g-0">
        <div class="col-md-3 p-4 text-center">
          <img src="../../assets/img/copernicus/CEMS_mapping_service.png" class="img-fluid rounded" alt="CEMS - Mapping service">
        </div>
        <div class="col-md-9">
          <div class="card-body lead">
            <h4 class="card-title">CEMS - Mapping service</h4>
            <p class="card-text">
              Copernicus Emergency Management Service comprises the Mapping Service  which consists of Rapid Mapping
              and Risk & Recovery Mapping services. The former provides mapping for emergency response right
              after a disaster, while the latter provides information for the prevention, preparedness, disaster
              risk reduction and recovery stages of disaster risk management cycle, including floods and
              algae blooms related disasters. 
            </p>
          </div>
        </div>
        <div class="col-md-12 bg-light">
          <div class="btn-div">
            <a href="https://emergency.copernicus.eu/mapping/" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 1 -->

<div class="row" id="0">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Copernicus Global Land Service (CGLS)</h3>
      <p class="text-start lead mt-4 mb-4">
        The Copernicus Global Land Service (CGLS) is a component of the Land Monitoring Core Service (LMCS)
        of Copernicus, the European flagship programme on Earth Observation. The Global Land Service systematically
        produces a series of qualified bio-geophysical products on the status and evolution of the land surface,
        at global scale and at mid to low spatial resolution, complemented by the constitution of long-term time series.
        The products are used to monitor also the water cycle.
      </p>
      <p class="text-end lead mb-4" >
        <a (click)="buttonOpenClick(0)" *ngIf="!buttonOpen[0]" class="btn btn-sm btn-primary">Expand <fa-icon icon="expand"></fa-icon></a>
        <a (click)="buttonOpenClick(0)" *ngIf="buttonOpen[0]" class="btn btn-sm btn-secondary">Close <fa-icon icon="times"></fa-icon></a>
      </p>
    </div>
  </div>
</div>

<div class="row justify-content-start" *ngIf="buttonOpen[0]">

  <div class="col-12 col-md-10 offset-md-1">
    <div class="card mb-5">
      <div class="row g-0">
        <div class="col-md-3 p-4 text-center">
          <img src="../../assets/img/copernicus/lake_surface_water_temperature.png" class="img-fluid rounded" alt="Lake surface water temperature">
        </div>
        <div class="col-md-9">
          <div class="card-body lead">
            <h4 class="card-title">Lake surface water temperature</h4>
            <p class="card-text">
              Lake surface water temperature (LSWT) describes the temperature of the lake surface, one important
              indicator
              of lake hydrology and biogeochemistry. Temperature trends observed over many years can be an indicator of
              how
              climate change affects the lake.
            </p>
            <p class="card-text">
              LSWT is recognized internationally as an Essential Climate Variable (ECV)
              and complements the water quality information, in environmental monitoring of a large number of lakes
              globally.
            </p>
          </div>
        </div>
        <div class="col-md-12 bg-light">
          <div class="btn-div">
            <a href="https://land.copernicus.eu/global/products/lswt" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-10 offset-md-1">
    <div class="card mb-5">
      <div class="row g-0">
        <div class="col-md-3 p-4 text-center">
          <img src="../../assets/img/copernicus/lake_water_quality.png" class="img-fluid rounded" alt="Lake water quality">
        </div>
        <div class="col-md-9">
          <div class="card-body lead">
            <h4 class="card-title">Lake water quality</h4>
            <p class="card-text">
              The Lake Water Products (lake water quality, lake surface water temperature) provide a semi-continuous
              observation record for a large number (nominally 4,200) of medium and large-sized lakes, according to the
              Global Lakes and Wetlands Database (GLWD) or otherwise of specific environmental monitoring interest.
              Next to the lake surface water temperature that is provided separately, this record consists of three
              water quality parameters:
            </p>
            <ul>
              <li>The turbidity of a lake describes water clarity, or whether sunlight can penetrate deeper parts of
                the lake. Turbidity often varies seasonally, both with the discharge of rivers and growth of
                phytoplankton (algae and cyanobacteria).</li>
              <li>The trophic state index is an indicator of the productivity of a lake in terms of phytoplankton,
                and indirectly (over longer time scales) reflects the eutrophication status of a water body.</li>
              <li>Finally, the lake surface reflectances describe the apparent colour of the water body, intended for
                scientific users interested in further development of algorithms. The reflectance bands can also be used
                to produce true-colour images by combining the visual wavebands.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 bg-light">
          <div class="btn-div">
            <a href="https://land.copernicus.eu/global/products/lwq" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-10 offset-md-1">
    <div class="card mb-5">
      <div class="row g-0">
        <div class="col-md-3 p-4 text-center">
          <img src="../../assets/img/copernicus/water_level.png" class="img-fluid rounded" alt="Water level">
        </div>
        <div class="col-md-9">
          <div class="card-body lead">
            <h4 class="card-title">Water level</h4>
            <p class="card-text">
              The Water Level is defined as the height, in meters above the geoid, of the reflecting surface of
              continental water bodies. It is observed by space radar altimeters that measure the time it takes
              for radar pulses to reach the ground targets, directly below the spacecraft (nadir position), and return.
            </p>
            <p class="card-text">
              Hence, only water bodies located along the satellite's ground tracks can be monitored, with a quality of
              measurement that not only depends of the size of the water body, but also on the reflecting targets in its
              surroundings such as topography or vegetation.
            </p>
            <p class="card-text">
              Water Level is computed as time series:
            </p>
            <ul>
              <li>over lakes</li>
              <li>over rivers, at the intersections of the river network with the satellite ground tracks,
                so-called Virtual Stations</li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 bg-light">
          <div class="btn-div">
            <a href="https://land.copernicus.eu/global/products/wl" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- 1 -->

<div class="row" id="1">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Copernicus Local Land Service (CLLS)</h3>
      <p class="text-start lead mt-4 mb-4">
        The CLLS component is coordinated by the European Environment Agency and aims to provide
        specific and detailed information focusing on different hotspots, i.e. areas that are prone
        to specific environmental challenges and problems. It will be based on very high resolution
        imagery (2,5 x 2,5 m pixels) in combination with other available datasets (high and medium
        resolution images) over the pan-European area.
      </p>
      <p class="text-end lead mb-4">
        <a href="https://land.copernicus.eu/local" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
        <a (click)="buttonOpenClick(1)" *ngIf="!buttonOpen[1]" class="btn btn-sm btn-primary">Expand <fa-icon icon="expand"></fa-icon></a>
        <a (click)="buttonOpenClick(1)" *ngIf="buttonOpen[1]" class="btn btn-sm btn-secondary">Close <fa-icon icon="times"></fa-icon></a>
      </p>
    </div>
  </div>
</div>

<div class="row justify-content-start" *ngIf="buttonOpen[1]">

  <div class="col-12 col-md-10 offset-md-1">
    <div class="card mb-5">
      <div class="row g-0">
        <div class="col-md-3 p-4 text-center">
          <img src="../../assets/img/copernicus/riparian_zones.png" class="img-fluid rounded" alt="Riparian zones">
        </div>
        <div class="col-md-9">
          <div class="card-body lead">
            <h4 class="card-title">Riparian zones</h4>
            <p class="card-text">
              Riparian zones represent transitional areas occurring between land and freshwater ecosystems,
              characterised by distinctive hydrology, soil and biotic conditions and strongly influenced by the
              stream water. They provide a wide range of riparian functions (e.g. chemical filtration, flood control,
              bank stabilization, aquatic life and riparian wildlife support, etc.) and ecosystem services.
            </p>
            <p class="card-text">
              The Riparian Zones products will support the objectives of several European legal acts and policy
              initiatives, such as the EU Biodiversity Strategy to 2030, the Habitats and Birds Directives and the
              Water Framework Directive.
            </p>
            <p class="card-text">
              Three complementary product groups provide detailed information on the state and characteristics of
              riparian zones across the EEA member and cooperating countries + UK, for supporting biodiversity monitoring
              and mapping and assessment of ecosystems and their services.
            </p>
            <p class="card-text">
              The Land Cover / Land Use product covers 805,177 km2 of land surface across Europe in a variable buffer
              zone of selected rivers (Strahler levels 2-9 derived from EU-Hydro) for two reference years: 2012 and 2018.
            </p>
            <p class="card-text">
              The Land Cover/Land Use (LC/LU) classification is extracted from VHR satellite data and other available data.
              This is a unique product with a high level of detail (Minimum Mapping Unit (MMU) is 0.5 ha). The
              classification provides 55 distinct thematic classes and the class definitions follow a pre-defined nomenclature
              based on the Mapping and Assessment of Ecosystems and their Services typology of ecosystems and are further
              harmonised with Corine Land Cover and adapted to the specific characteristics of riparian zones. It offers
              two types of products: status maps for the two reference years and one change product.
            </p>
          </div>
        </div>
        <div class="col-md-12 bg-light">
          <div class="btn-div">
            <a href="https://land.copernicus.eu/local/riparian-zones" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-10 offset-md-1">
    <div class="card mb-5">
      <div class="row g-0">
        <div class="col-md-3 p-4 text-center">
          <img src="../../assets/img/copernicus/coastal_zones.png" class="img-fluid rounded" alt="Coastal zones">
        </div>
        <div class="col-md-9">
          <div class="card-body lead">
            <h4 class="card-title">Coastal zones</h4>
            <p class="card-text">
              This final version of the Coastal Zones datasets covering 100% of the extent for reference years
              2012 and 2018 together with the accompanying change mapping, is now available. Please explore the
              datasets together with the nomenclature and mapping guidelines for in-depth information.
            </p>
            <h5 class="card-title">Coastal Zone hotspot thematic mapping</h5>
            <p class="card-text">
              In 2019 the EEA launched the implementation of a new thematic hotspot product to monitor landscape
              dynamics in coastal zones. The new products will cover all European coastal territory to an inland
              depth of 10 km with a total area of approximately 730,000 km². The products will have a minimum mapping
              unit of 0.5 ha and record around 60 LC/LU classes.
            </p>
            <p class="card-text">
              Thematic hotspot mapping activities within the CLMS aim, complementary to generic wall-to-wall mapping,
              to provide specific and detailed land cover / land use (LC/LU) information to address
              environmental challenges and issues.
            </p>
            <p class="card-text">
              The initial production of the Coastal Zones hotspot thematic mapping will consist of:
            </p>
            <ul>
              <li>a LC/LU status layer for 2012</li>
              <li>a LC/LU change layer between 2012 and 2018</li>
              <li>and a LC/LU status layer for 2018</li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 bg-light">
          <div class="btn-div">
            <a href="https://land.copernicus.eu/local/coastal-zones" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- 2 -->



<!-- 3 -->

<div class="row" id="2">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>EU-Hydro</h3>
      <p class="text-start lead mt-4 mb-4">
        EU-Hydro is a dataset for all EEA39 countries providing a photo-interpreted river network,
        consistent of surface interpretation of water bodies (lakes and wide rivers), and a drainage model
        (also called Drainage Network), derived from EU-DEM, with catchments and drainage lines and nodes.
        The production of EU-Hydro and the derived layers was coordinated by the European Environment Agency
        in the frame of the EU Copernicus programme. More information can be found in the user manual.
      </p>
      <p class="text-end lead mb-4">
        <a href="https://land.copernicus.eu/imagery-in-situ/eu-hydro" target="_blank"
              class="btn btn-sm btn-outline-primary">Read more <fa-icon icon="external-link"></fa-icon></a>
        <!--<a (click)="buttonOpenClick(2)" *ngIf="!buttonOpen[2]" class="btn btn-sm btn-primary">Expand <fa-icon icon="expand"></fa-icon></a>
        <a (click)="buttonOpenClick(2)" *ngIf="buttonOpen[2]" class="btn btn-sm btn-secondary">Close <fa-icon icon="times"></fa-icon></a>-->
      </p>
    </div>
  </div>
</div>

<!--<div class="row justify-content-start" *ngIf="buttonOpen[2]"></div>-->

<!-- 3 -->

