import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-crowdsourcing-dashboard',
  templateUrl: './crowdsourcing-dashboard.component.html',
  styleUrls: ['./crowdsourcing-dashboard.component.scss'],
  providers: [NgbCarouselConfig]
})
export class CrowdsourcingDashboardComponent implements OnInit {

  constructor(config: NgbCarouselConfig) {
    config.interval = 0;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationIndicators = true;
  }

  images = [1, 2, 3].map((n) => `../../assets/img/crowdsourcing_dashboard_shot_${n}.png`);

  ngOnInit(): void {
  }

}
