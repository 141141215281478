<!-- Sezione Titolo + 1° Testo -->
<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Extreme Event Detection</h3>

      <div class="accordion" id="accordion1">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingOne">
            <button class="accordion-button bg-primary" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Service Description
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              Extreme events service aims to utilize Copernicus satellite products, namely, Sentinel-1 and Sentinel-2, to identify extreme events in inland drinking water reservoirs which degrade water quality. The focus is on mapping extreme floods, hydrocarbon formations and muddy water which is usually owed to extreme rainfall/flood events. 
              Regarding flash floods they are fast-evolving phenomena that have become more frequent for more than a decade as a result of the climate crisis. Frequent monitoring and mapping of such phenomena is of high importance to the water utility users, among others, since they impose a direct and indirect threat to the water quality of drinking water reservoirs. WQeMS develops an extreme flood event detection service by exploiting ONDA DIAS Copernicus data provision and infrastructure.
              <br><br>
              Another event that compromises the quality of the water is the existence of oil spills. Usually, oil spills are identified in open sea; however, within WQeMS the focus is inland water. A characteristic of the oil spills monitored is that they are small-scale events, which makes their identification a challenging task. To this end, the developed module aims at identifying oil spill formations identified on the water surface of inland water and provide metadata such as size estimation of the oil spill. 
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion2">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingTwo">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Service Results
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              Extreme flood mapping service is currently applicable on the Italian pilot use case (PUC), where time series of Sentinel-1 data are used. The latter are preprocessed and are used as input to a custom deep convolutional neural network model. The model inherently learns temporal patterns that are mostly characteristic under the presence of an extreme flood event. 
              <br>As a result, extreme flood maps are generated every time Sentinel-1 passes over the PUC. At the same time, several statistics that are indicative of the magnitude of a flood event are calculated and will be available to the user.              
              <br><br>
              Hydrocarbon formation presence is a crucial proxy of water quality. This service utilises a Sentinel-2 Copernicus satellite data acquired from ONDA DIAS which are properly preprocessed. A trained deep neural network model is applied and generates an oil spill map for a given date. The final map shows areas that hydrocarbon formations are present.
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion3">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingThree">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Gain for the user
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              The service allows anyone to get access to water quality information in a convenient manner. In the
              Finnish use case, the latest values in May indicate increasing trend in algal biomass. This is expected as
              the algal bloom season was approaching.
              <br><br>
              In the German use case, the focus now lies on validating past events, such as the one shown before, before
              looking into the future in the form of the operational (monitoring) service for the water authorities.
            </div>
          </div>
        </div>
      </div>

      <div class="accordion" id="accordion4">
        <div class="accordion-item">
          <h2 class="accordion-header m-0" id="headingThree">
            <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              Service Specifications
            </button>
          </h2>
          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body bg-white">
              <b>Lakes, Reservoirs, Rivers Flood/Hydrocarbons Detection Product (*)</b>
              <br><br>
              <b>Type</b>: Emergency / Fast process (**)
              <br>
              <b>Availability</b>: Continuous or on demand, upon detection in EO data, or triggered by the Alert Module
              <br>
              <b>Time coverage</b>: In the “continuous” delivery mode the services will run every day, and once a satellite product is found they will generate a flood/muddy water/oil spill product. In the “on-demand” delivery mode we foresee that the services will need a specific date from the user.
              <br><br>
              <i><u>Input parameters</u></i>
              <br>
              <i>Area</i>: area coordinates (as WKT), shapefile
              <br><br>
              <i><u>Input data</u></i>
              <br>
              <i>EO satellite data</i>: Copernicus Sentinel-1 and Sentinel-2 data
              <br><br>
              <i>Spatial resolution</i>:
              <ul>
                <li>~20m (Sentinel 1)</li>
                <li>10-60m depending on the band (Sentinel-2)</li>
              </ul>
              <br>
              <i>Temporal Resolution</i>:
              <br>
              <ul>
                <li><i>Sentinel-1: 1-5 days (2 days on average) before Sentinel-1B malfunction in January 2022 and 1-11 days (4 days on average) after Sentinel-1B malfunction</i></li> 
                <li><i>Sentinel-2: 5 days which depends on latitude and cloud coverage</i></li>
              </ul>
              <br>
              <i>Additional data to use:</i>
              <br>
              <ul>
                <li><i>VHR (validation/annotation only)</i></li> 
                <li><i>In situ for muddy water (exploration only)</i></li> 
              </ul>
              <br>
              <b>Lakes, Reservoirs, Rivers Flood Product</b>
              <br><br>
              <i><u>Output product</u></i>
              <br>
              <i>Format</i>: GeoTIFF raster file
              <br><br>
              Flood:
              <ul>
                <li>Values / Classes:
                  <ul>
                    <li>0 / no flood</li>
                    <li>1 / flood</li>
                    <li>255 / no data</li>
                  </ul>
                </li>
              </ul>
              Muddy water (experimental): 
              <ul>
                <li>Values / Classes:
                  <ul>
                    <li>0/ no muddy water</li>
                    <li>1 / muddy water</li>
                    <li>2 / low muddy water</li>
                    <li>3 / clouds</li>
                    <li>255 / no data</li>
                  </ul>
                </li>
              </ul>
              <br>
              <i>Spatial resolution</i>: nominal 10 m (flood/muddy water)
              <br>
              <i>Temporal Resolution</i>: 1-5 or 1-11 days (flood), 5 days (muddy water)
              <br>
              <i>Size</i>: Up to 100 of MB (GeoTIFF) (this heavily depends on the AOI size, but up to now it usually is a couple of kb)<br>
              <br>
              <i>Metadata information including (e.g.)</i>:
              <ul>
                <li>Relevant metadata from the original product (e.g., sensing date, cloud coverage etc.)</li>
                <li>Classification evaluation metrics of the models (e.g., Overall Accuracy, F1-score, IoU etc.) (as static values)</li>
              </ul>            
              <br>
              <b>Hydrocarbons Detection Product</b>
              <br><br>
              <i><u>Output product</u></i>
              <br>
              <br>
              Oil: 
              <ul>
                <li>Values / Classes:
                  <ul>
                    <li>0/ no oil</li>
                    <li>1 / oil</li>
                    <li>2 / clouds</li>
                    <li>255 / no data</li>
                  </ul>
                </li>
              </ul>
              <br>
              <i>Format</i>: GeoTIFF raster file (same narrative as with flood) 
              <br>
              <i>Spatial resolution</i>: nominal 10 m
              <br>
              <i>Temporal Resolution</i>: 5 days which depends on latitude and cloud coverage
              <br>
              <i>Size</i>: up to 100 of MB (GeoTIFF) (this heavily depends on the AOI size, but up to now it usually is a couple of kb)<br>
              <br>
              <i>Metadata information including (e.g.)</i>:
              <ul>
                <li>Relevant metadata from the original product (e.g., sensing date, cloud coverage etc.)</li>
                <li>Classification evaluation metrics of the models (e.g., Overall Accuracy, F1-score, IoU etc.) (as static values)</li>          
              </ul>   
              <br>
              <i><u>Dissemination</u></i>:
              <ul>
                <li>FTP</li>
                <li>OGC Web Services & APIs</li>
                <li>Dedicated Web Interface</li>
              </ul>
              <br>
              <i><u>Timeliness</u></i>:
              <ul>
                <li>Upon availability of Sentinel-1 and Sentinel-2 data in ONDA catalogue (normally a few hours after sensing).</li>
              </ul>
              <br><br>
              (*) Free during the project; on payment after or free as CEMS service; products can be also accessed and visualized as WQeMS services; alert notification is part of the associated service.
              <br><br>
              (**) The service is classified with reference to the type of process (Fast/Slow) and the scope, namely, Monitor-ing/Emergency. 

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 mb-5 rounded">

      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">

          <ngb-carousel *ngIf="images">
            <ng-template ngbSlide *ngFor="let image of images">
              <div class="picsum-img-wrapper">
                <img [src]="image" alt="WQeMS">
              </div>
            </ng-template>
          </ngb-carousel>

        </div>
      </div>

    </div>
  </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
  <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
    <a href="request-service" type="button" class="btn btn-secondary btn-lg pt-2 pb-2">
      Request a Service <fa-icon icon="arrow-right" class="right"></fa-icon>
    </a>
  </div>
</div>