import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AlertConfigurationAPIsService } from 'src/app/services/restapiservices';
import { AlertConfigCompleteGetDTO } from 'src/app/services/restapiservices';
import { KeycloakProfile } from 'keycloak-js';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from 'src/app/components/modals/info-modal/info-modal.component';
import { InfoModalsService } from 'src/app/services/interface/info-modals.service';

@Component({
  selector: 'app-my-alert-configurations',
  templateUrl: './my-alert-configurations.component.html',
  styleUrls: ['./my-alert-configurations.component.scss']
})
export class MyAlertConfigurationsComponent implements OnInit {

  alertConfigCompleteGetDTOs!: AlertConfigCompleteGetDTO[];
  public keycloakProfile: KeycloakProfile = {};

  constructor(public infoModalService: InfoModalsService, private keycloakService: KeycloakService, private alertConfigurationAPIsService: AlertConfigurationAPIsService, private route: Router) { }

  ngOnInit(): void {
    this.keycloakService.getToken().then(token => {
      this.keycloakService.loadUserProfile().then(data => {
        this.keycloakProfile = data;
      })
      this.alertConfigurationAPIsService.getRequestedServiceCompleteByUserApiV1AlertConfigByuserCompleteGet(token).subscribe((data: AlertConfigCompleteGetDTO[]) => {
        this.alertConfigCompleteGetDTOs = data;       
      }, error => {
        if (error.status == 404) {
          this.infoModalService.openInfoModal("Warning", "You have not configured any alerts. Create a new configuration")
          this.route.navigateByUrl('alert-configuration')  
        } else {
          this.infoModalService.openInfoModal("Error", "An error occours, " + error.details)
          this.route.navigateByUrl('home')
        }
        
      })
    });
  }

}
