<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>File Server</h3>
            <p class="text-start lead">
                HTTPS File Server is a web page through which the user can <strong>navigate directories and download rasters and associated metadata files</strong> for which they have the necessary permissions.
                The File Server interface initially shows a list of folders referring to the four main WQeMS services (Land Water Transition Zone, Water Quality, Extreme Event and Bloom Event). Each folder contains within it one directory for each sub-service offered by the selected service. Inside them there is a folder for each monitored Water Body containing the data products (raster files in tif format and metadata files in xml format) which can only be accessed if the users own the necessary permissions.
            </p>          
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="https://cog-wqems.iti.gr/" class="btn btn-secondary btn-lg pt-2 pb-2" target="_blank">
            Go to the File Server <fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>