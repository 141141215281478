<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Training</h3>
            <p class="text-start lead">
                The innovation process starts and ends with the users' involvement, the expression of their needs and subsequently the validation of the developed technologies within the identified business/use-cases. The systematic increment of understanding about the WQeMS services is of vital importance in the project’s innovation process. The objective is for water professionals and water utilities to acquire the necessary skills and competencies related to the operation and content interpretation of the developed WQeMS services. At the same time, the correct and sustainable operation of the WQeMS platform will facilitate the long-term benefit to the water consumers, who will enjoy the basic right for high water quality.
            <br>
            At its completion, the User Training task will produce:
            </p>
            <ul class="text-start lead">
                <li>A Training Curriculum, with clear indication of skills/competences to be pursued and a collection of learning objects (textual, videos, gaming tools, etc.) to facilitate the delivery of the training.</li>
                <li>A Training Handbook, to act as a guide for the training and transferring of knowledge to the WQeMS users and stakeholders.</li>
                <li>An eLearning environment to host the learning material and facilitate the access to it</li>
            </ul>                
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="https://training.wqems.eu" class="btn btn-secondary btn-lg pt-2 pb-2">
            Go to the eLearning environment<fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>