<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Crowdsourcing Mobile App</h3>
            <p class="text-start lead">
                The Crowdsourcing Mobile App is an Android application that offers an intuitive way for its users to <strong>report water-related issues</strong>. Users can fill in a simple form to define the description and the location of the water issue and possibly attach/take an associated photo. Authorized water utility users can directly validate water quality reports by entering a password into the "Validation Code" field. However, water quality complaints reported by non-authorized users must first be validated by water utilities in the <a href="crowdsourcing-dashboard/">Alert Dashboard</a> before being pushed to the middleware layer. WQeMS’s Crowdsourcing Mobile App enable the direct communication between water utilities and citizens. It facilitate citizens to post water-related complaints through their smartphone and water utilities to efficiently receive and handle them. Instead of a possibly lengthy conversation on the phone, the <strong>water operator instantly have the complete necessary details</strong> as posted via the app. Thus, the water company will save human resources (operators that have to answer the calls) and, at the same time, improve its image, since they will facilitate water consumers to submit their complaints. A <a href="https://socialmedia-server-m4d.iti.gr/WQeMS/WQeMS_Crowdsourcing_Mobile_APP_USER_MANUAL.pdf"  target="_blank" download>user manual</a> is provided.
            </p>           
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione Galleria Fotografica -->
<!--<div class="row">

    <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
            <a href="#">
                <img src="../../assets/img/crowdsourcing_app_shot_1.jpg" class="card-img-top">
            </a>
        </div>
    </div>
    
    <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
            <a href="#">
                <img src="../../assets/img/crowdsourcing_app_shot_2.jpg" class="card-img-top">
            </a>
        </div>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
            <a href="#">
                <img src="../../assets/img/crowdsourcing_app_shot_3.jpg" class="card-img-top">
            </a>
        </div>
    </div>

</div>-->

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">

        <button (click)="onClickDownloadApp()" class="btn btn-secondary btn-lg pt-2 pb-2" download>
            Download the Crowdsourcing Mobile App <fa-icon icon="arrow-right" class="right"></fa-icon>
        </button>
    </div>
</div>