/**
 * WQeMS: RestAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AlertConfigCompleteGetDTO } from './alertConfigCompleteGetDTO';
import { ReasonAlertDTO } from './reasonAlertDTO';

export interface AlertGetDTO { 
    idAlert?: number;
    date?: Date;
    delivered?: boolean;
    caused?: string;
    reasons?: Array<ReasonAlertDTO>;
    reasonsText?: Array<string>;
    alertConfig?: AlertConfigCompleteGetDTO;
}