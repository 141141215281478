/**
 * WQeMS: RestAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ServicesWaterBodyRolesGetProductTypeDTO } from './servicesWaterBodyRolesGetProductTypeDTO';

export interface ServicesWaterBodyRolesGetDTO { 
    waterBodyLocalId?: string;
    waterBodyNameText?: string;
    waterBodyCoordinates?: Array<number>;
    productTypes?: Array<ServicesWaterBodyRolesGetProductTypeDTO>;
}