import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { RequestAPIsService, RequestPartialGetDTO } from 'src/app/services/restapiservices';

@Component({
  selector: 'app-administration-requests-list',
  templateUrl: './administration-requests-list.component.html',
  styleUrls: ['./administration-requests-list.component.scss']
})
export class AdministrationRequestsListComponent implements OnInit {

  requestPartialGetDTOs!: RequestPartialGetDTO[];

  constructor(private router: Router, private keycloakService: KeycloakService, private requestAPIsService: RequestAPIsService) { }

  ngOnInit(): void {
    this.keycloakService.getToken().then(token => {
      this.requestAPIsService.getRequestedServicePartialNotElaboratedApiV1RequestNotElaboratedPartialGet(token).subscribe(data => {
        // this.requestPartialGetDTOs = data;
        this.requestPartialGetDTOs = data.sort((a, b) => new Date(b.requestDate!).getTime() - new Date(a.requestDate!).getTime())
        console.log(data)
      }, error => {
        alert(error.error.detail)
        this.router.navigateByUrl('home')
      })
    })
  }

  onClickManageRequest(idRequest: number | undefined) {
    this.router.navigateByUrl('administration-request/' + idRequest)
  }
  

}
