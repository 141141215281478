/**
 * WQeMS: RestAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AlertConfigCompleteGetAlertConditionDTO } from './alertConfigCompleteGetAlertConditionDTO';
import { AlertConfigCompleteGetNotificationMethodDTO } from './alertConfigCompleteGetNotificationMethodDTO';
import { AlertConfigCompleteGetWaterBodyDTO } from './alertConfigCompleteGetWaterBodyDTO';

export interface AlertConfigCompleteGetDTO { 
    idAlertConfig?: number;
    title?: string;
    dateRequest?: string;
    alertConditions?: Array<AlertConfigCompleteGetAlertConditionDTO>;
    notificationMethod?: Array<AlertConfigCompleteGetNotificationMethodDTO>;
    waterBody?: AlertConfigCompleteGetWaterBodyDTO;
}