<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Reports</h3>
            <p class="text-start lead">
                WQeMS can <strong>generate PDF reports</strong> including information for a specific water body and a list of parameters and measures of interest for the user in a specified time frame. Reports include also a time-series chart, which shows the <strong>evolution in time of one statistical measure over each monitored parameter</strong> (e.g. average value of turbidity in the specified time period for the selected water body).
                To generate a new report: i) log in to the service, ii) select the Water Body of interest, iii) specify one or more services to include and enter the desired period. The report will be ready in a few seconds and will be sent to the email address specified. 
            </p>          
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="request-report" class="btn btn-secondary btn-lg pt-2 pb-2">
            Go to the Report Generator<fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>