<ng-template #popContentWaterBodyInfo>Select the water body you want to monitor.</ng-template>
<ng-template #popContentWaterBodySelectFromMyWaterBodyInfo>Select a water body from a list of those you have already
   requested previously. It will be processed as a single Area of Interest (AOI).</ng-template>
<ng-template #popContentWaterBodySelectNewWaterBodyInfo>Select a water body from a predefined list of water bodies in
   Europe. It will be processed a single Area of Interest (AOI).</ng-template>
<ng-template #popContentWaterBodyLoadShapeFileInfo>Upload a file containing one or more water body polygons that you
   want to monitor. Each polygon will be treated as a distinct Area of Interest (AOI). Supported formats are shapefiles
   (in zip, rar or 7zip), KML and KMZ.</ng-template>
<ng-template #popContentServices>Select one or more services, the modality of execution (continous monitoring or
   on-demand) and, if applicable, the period for which you wish to activate the service.<br><br><b>Continous monitoring</b>: continuously monitors lakes, generates geospatial maps and detects water related-issues. Recommended for routinely operational monitoring and early-warning. <br><br><b>On-demand</b>: generation of one-off geospatial maps of a given area at a given time. Recommended for emergency management or for any type of issues spotted in a lake.</ng-template>
<ng-template #popContentLwtzModeMultispectral>Dates may need to be shifted to suit to acquisition dates, accuracy drops
   by ~5 - 8% with the use of radar data, computational and service cost is high</ng-template>
<ng-template #popContentLwtzModeRadarDataMultispectral>Dates may need to be shifted to suit to acquisition dates,
   accuracy drops by ~5-8% with the use of radar data, computational and service cost is medium.
</ng-template>
<ng-template #popContentLwtzModeRadarData>Dates may need to be shifted to suit to acquisition dates, but accuracy is
   higher, computational and service cost is low.</ng-template>
<ng-template #popContentLwtzTdModeRadarDataMultispectral>Temporal accuracy is high, accuracy drops by ~5-8% with the use
   of radar data, computational and service cost is high.</ng-template>
<ng-template #popContentLwtzTdModeRadarData>Temporal accuracy is low, but accuracy is higher, computational and service
   cost is low</ng-template>

<div class="row">
   <div class="col-12">
      <div class="bg-light p-4 pt-2 pb-2 mb-4 rounded">
         <h3>Service Request</h3>
      </div>
   </div>
</div>

<div class="row">
   <div class="col-12 mt-3 mb-3">
      <h4>Water Body <small>
            <fa-icon icon="circle-info" [ngbPopover]="popContentWaterBodyInfo"></fa-icon>
         </small></h4>
   </div>
</div>

<div class="row mb-4">
   <div class="col-12 rounded">
      <div id="map" class="rounded"></div>
   </div>
</div>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
   <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path
         d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z">
      </path>
   </symbol>
</svg>
<div class="alert alert-primary d-flex alert-dismissible fade show align-items-center mt-4 mb-4" role="alert">
   <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:">
      <use xlink:href="#info-fill"></use>
   </svg>
   <div>EO based data and other information about the pilot lake located in Finland is provided by Syke through the <a
         href="https://testbed.ymparisto.fi/eo-tarkka/mapandwaterareas/?ver=0&time=2022-06-30&bbox=27.56379,60.99166,28.56037,61.24108&data=s2_rgb,ref_regions,ehp_all,esri_gray_base&collectionId=all_data"
         target="_blank" class="alert-link">TARKKA+</a> service. See <a
         href="https://syke.atlassian.net/wiki/spaces/SYKEEOEN/pages/792657921/Syke+s+TARKKA+service+and+WQeMS+project"
         target="_blank" class="alert-link">this page</a> for additional details and instructions. Syke
      provides water quality information also for the Baltic Sea and other lakes in Finland (boreal and subarctic
      zones).
   </div>
   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>

<div class="row">
   <div class="col-12">
      <div class="bg-light p-4 pt-4 pb-2 mb-4 rounded">

         <div>
            <div class="row">
               <div class="col-md-12 mb-3">


               </div>
            </div>
            <div class="row">
               <div class="col-md-6 mb-3">

                  <div class="mb-3">
                     <div class="form-check">
                        <input [checked]="selectedSelectOneOfThePreviouslyRequested"
                           (change)="onMethodSelectionChange('previously-requested')" class="form-check-input"
                           type="radio" name="flexRadioDefaultMyWaterBodyMethodSelection"
                           id="flexRadioDefaultMyWaterBodyMethodSelectionList">
                        <label for="form-select-water-body-list" class="form-check-label"
                           for="flexRadioDefaultMyWaterBodyMethodSelectionList">
                           Select one of the previously requested
                           <fa-icon icon="circle-info" [ngbPopover]="popContentWaterBodySelectFromMyWaterBodyInfo">
                           </fa-icon>
                        </label>
                     </div>
                     <select [disabled]="disabledOptionSelectOneOfThePreviouslyRequested"
                        name="myWaterBodyShapeSchemaListSelectName" class="form-control"
                        [(ngModel)]="myWaterBodyGeometryGetDTOSelected" id="form-select-water-body-list"
                        (change)="onWaterBodyShapeSchemaListSelect($event)">
                        <option [disabled]="disabledOptionSelectOneOfThePreviouslyRequested"
                           [ngValue]="myWaterBodyGeometryGetDTO"
                           *ngFor="let myWaterBodyGeometryGetDTO of myWaterBodyGeometryGetDTOs; index as index">
                           {{myWaterBodyGeometryGetDTO.nameText}}</option>
                     </select>
                  </div>
                  <div *ngIf="!finishedLoadWaterBodies" class="spinner-border text-primary" role="status">
                     <span class="visually-hidden">Loading...</span>
                  </div>
               </div>
               <div class="col-md-6 mb-3">
                  <div class="mb-3">
                     <div class="form-check">
                        <input [disabled]="disabledUploadAShapeFile" [checked]="selectedUploadAShapeFile"
                           (change)="onMethodSelectionChange('shapefile')" class="form-check-input" type="radio"
                           name="flexRadioDefaultWaterBodyMethodSelection"
                           id="flexRadioDefaultWaterBodyMethodSelectionShapeFile">
                        <label for="form-select-water-body-shpefile" class="form-check-label"
                           for="flexRadioDefaultWaterBodyMethodSelectionShapeFile">
                           Upload a file (.kmz, .kml, shapefile in .zip)
                           <fa-icon icon="circle-info" [ngbPopover]="popContentWaterBodyLoadShapeFileInfo"></fa-icon>
                        </label>
                     </div>
                     <div class="input-group custom-file-button">
                        <label class="input-group-text" for="form-select-water-body-shpefile">Choose file</label>
                        <input (change)="onSelectWaterBodyShapeFile($event)" accept=".zip,.rar,.7zip,.kmz,.kml"
                           class="form-control" type="file" id="form-select-water-body-shpefile"
                           [disabled]="disabledOptionSelectShapefile">
                     </div>
                  </div>
               </div>
            </div>

         </div>

      </div>
   </div>
</div>

<div class="row">
   <div class="col-12 mt-3 mb-3">
      <h4>Services <fa-icon icon="circle-info" [ngbPopover]="popContentServices"></fa-icon>
      </h4>
   </div>
</div>

<form>

   <div id="parentForm" class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-2 pb-4 mb-4 rounded">

            <div class="row justify-content-end">
               <div class="col-12 col-md-8 mb-md-4">
                  <h5>Water Quality Features Changes</h5>
               </div>
               <div class="col-12 col-md-2 mb-4 text-center text-md-end">
                  <a href="/water-quality" target="_blank" class="btn btn-sm btn-primary mt-md-3">
                     <fa-icon icon="info-circle"></fa-icon> Details
                  </a>
               </div>
            </div>

            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [checked]="selectedWaterQualityTur" [disabled]="disabledWaterQualityTur"
                        #checkboxWaterQualityTur name="checkboxWaterQualityTur"
                        (change)="onServiceSeleted('waterquality-tur', checkboxWaterQualityTur)"
                        class="form-check-input" type="checkbox" value="" id="flexCheckCheckedWaterQualityTur">
                     <label class="form-check-label" for="flexCheckCheckedWaterQualityTur">
                        Turbidity
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check form-switch">
                     <input class="form-check-input" #turContinuousMonitoring
                        [checked]='selectedWaterQualityTurContinuous'
                        (change)="onTypeMonitoringSelect('waterquality-tur', 'continuous', turContinuousMonitoring)"
                        [attr.disabled]="checkboxWaterQualityTur.checked && !disabledWaterQualityTurContinuous ! ? null : ''"
                        type="checkbox" id="flexRadioDefaultWaterQualityTurContinuous"
                        name="flexRadioDefaultWaterQualityTurContinuousName"
                        id="flexRadioDefaultWaterQualityTurContinuous">
                     <label class="form-check-label" for="flexRadioDefaultWaterQualityTurContinuous">
                        Continuous Monitoring
                     </label>
                  </div>
                  <div class="form-check form-switch">
                     <input disabled class="form-check-input" #turOndemandMonitoring
                        [checked]='selectedWaterQualityTurOnDemand'
                        (change)="onTypeMonitoringSelect('waterquality-tur', 'on-demand', turOndemandMonitoring)"
                        [attr.disabled]="checkboxWaterQualityTur.checked && !disabledWaterQualityTurOnDemand ! ? null : ''"
                        type="checkbox" name="flexRadioDefaultWaterQualityTurOnDemandName"
                        id="flexRadioDefaultWaterQualityTurOnDemand">
                     <label class="form-check-label" for="flexRadioDefaultWaterQualityTurOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input disabled #startDateWaterQualityTur
                           (change)="onDateSelect('startDate', 'waterquality-tur', startDateWaterQualityTur.value, startDateWaterQualityTur)"
                           [attr.disabled]="checkboxWaterQualityTur.checked  && turOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input disabled #endDateWaterQualityTur [min]="startDateWaterQualityTur.value"
                           (change)="onDateSelect('endDate', 'waterquality-tur', endDateWaterQualityTur.value, endDateWaterQualityTur)"
                           [attr.disabled]="checkboxWaterQualityTur.checked  && turOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>
            <hr>
            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [disabled]="disabledWaterQualityChl" [checked]='selectedWaterQualityChl'
                        #checkboxWaterQualityChl
                        (change)="onServiceSeleted('waterquality-chl', checkboxWaterQualityChl)"
                        class="form-check-input" type="checkbox" value="waterquality-chl"
                        id="flexCheckCheckedWaterQualityChl">
                     <label class="form-check-label" for="flexCheckCheckedWaterQualityChl">
                        Chlorophyll-a
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check form-switch">
                     <input [checked]='selectedWaterQualityChlOnDemand' disabled class="form-check-input"
                        #chlContinuousMonitoring
                        (change)="onTypeMonitoringSelect('waterquality-chl', 'continuous', chlContinuousMonitoring)"
                        [attr.disabled]="checkboxWaterQualityChl.checked && !disabledWaterQualityChlContinuous ? null : ''"
                        type="checkbox" name="flexRadioDefaultWaterQualityChl"
                        id="flexRadioDefaultWaterQualityChlContinuous">
                     <label class="form-check-label" for="flexRadioDefaultWaterQualityChlContinuous">
                        Continuous Monitoring
                     </label>
                  </div>
                  <div class="form-check form-switch">
                     <input disabled class="form-check-input" #chlOndemandMonitoring
                        (change)="onTypeMonitoringSelect('waterquality-chl', 'on-demand', chlOndemandMonitoring)"
                        [attr.disabled]="checkboxWaterQualityChl.checked ? null : ''" type="checkbox"
                        name="flexRadioDefaultWaterQualityChl" id="flexRadioDefaulWaterQualityChlOndemand">
                     <label class="form-check-label" for="flexRadioDefaultWaterQualityChlOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateWaterQualityChl
                           (change)="onDateSelect('startDate', 'waterquality-chl', startDateWaterQualityChl.value, startDateWaterQualityChl)"
                           [attr.disabled]="checkboxWaterQualityChl.checked  && chlOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateWaterQualityChl [min]="startDateWaterQualityChl.value"
                           (change)="onDateSelect('endDate', 'waterquality-chl', endDateWaterQualityChl.value, endDateWaterQualityChl)"
                           [attr.disabled]="checkboxWaterQualityChl.checked  && chlOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>
            <hr>
            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [disabled]="disabledWaterQualitySst" [checked]="selectedWaterQualitySst"
                        #checkboxWaterQualitySst
                        (change)="onServiceSeleted('waterquality-sst', checkboxWaterQualitySst)"
                        class="form-check-input" type="checkbox" value="" id="flexCheckCheckedWaterQualitySst">
                     <label class="form-check-label" for="flexCheckCheckedWaterQualitySst">
                        Sea Surface Temperature
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check form-switch">
                     <input [checked]='selectedWaterQualitySstContinuous' class="form-check-input"
                        #sstContinuousMonitoring
                        (change)="onTypeMonitoringSelect('waterquality-sst', 'continuous', sstContinuousMonitoring)"
                        [attr.disabled]="checkboxWaterQualitySst.checked && !disabledWaterQualitySstContinuous ? null : ''"
                        type="checkbox" name="flexRadioDefaultWaterQualitySst"
                        id="flexRadioDefaultWaterQualitySstContinuous">
                     <label class="form-check-label" for="flexRadioDefaultWaterQualitySstContinuous">
                        Continuous Monitoring
                     </label>
                  </div>
                  <div class="form-check form-switch">
                     <input [checked]='selectedWaterQualitySstOnDemand' class="form-check-input" #sstOndemandMonitoring
                        (change)="onTypeMonitoringSelect('waterquality-sst', 'on-demand', sstOndemandMonitoring)"
                        [attr.disabled]="checkboxWaterQualitySst.checked ? null : ''" type="checkbox"
                        name="flexRadioDefaultWaterQualitySst" id="flexRadioDefaulWaterQualitySstOndemand">
                     <label class="form-check-label" for="flexRadioDefaultWaterQualitySstOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateWaterQualitySst
                           (change)="onDateSelect('startDate', 'waterquality-sst', startDateWaterQualitySst.value, startDateWaterQualitySst)"
                           [attr.disabled]="checkboxWaterQualitySst.checked  && sstOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateWaterQualitySst [min]="startDateWaterQualitySst.value"
                           (change)="onDateSelect('endDate', 'waterquality-sst', endDateWaterQualitySst.value, endDateWaterQualitySst)"
                           [attr.disabled]="checkboxWaterQualitySst.checked  && sstOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>
            <hr>
            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [disabled]="disabledWaterQualitySdd" [checked]="selectedWaterQualitySdd"
                        #checkboxWaterQualitySdd
                        (change)="onServiceSeleted('waterquality-sdd', checkboxWaterQualitySdd)"
                        class="form-check-input" type="checkbox" value="" id="flexCheckCheckedWaterQualitSdd">
                     <label class="form-check-label" for="flexCheckCheckedWaterQualitySdd">
                        Secchi Disk Depth
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check form-switch">
                     <input [checked]='selectedWaterQualitySddContinuous' class="form-check-input"
                        #sddContinuousMonitoring
                        (change)="onTypeMonitoringSelect('waterquality-sdd', 'continuous', sddContinuousMonitoring)"
                        [attr.disabled]="checkboxWaterQualitySdd.checked && !disabledWaterQualitySddContinuous ? null : ''"
                        type="checkbox" name="flexRadioDefaultWaterQualitySdd"
                        id="flexRadioDefaultWaterQualitySddContinuous">
                     <label class="form-check-label" for="flexRadioDefaultWaterQualitySddContinuous">
                        Continuous Monitoring
                     </label>
                  </div>
                  <div class="form-check form-switch">
                     <input [checked]='selectedWaterQualitySddOnDemand' class="form-check-input" #sddOndemandMonitoring
                        (change)="onTypeMonitoringSelect('waterquality-sdd', 'on-demand', sddOndemandMonitoring)"
                        [attr.disabled]="checkboxWaterQualitySdd.checked ? null : ''" type="checkbox"
                        name="flexRadioDefaultWaterQualitySdd" id="flexRadioDefaulWaterQualitySddOndemand">
                     <label class="form-check-label" for="flexRadioDefaultWaterQualitySddOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker">Start Date</label>
                        <input #startDateWaterQualitySdd
                           (change)="onDateSelect('startDate', 'waterquality-sdd', startDateWaterQualitySdd.value, startDateWaterQualitySdd)"
                           [attr.disabled]="checkboxWaterQualitySdd.checked  && sddOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker">End Date</label>
                        <input #endDateWaterQualitySdd [min]="startDateWaterQualitySdd.value"
                           (change)="onDateSelect('endDate', 'waterquality-sdd', endDateWaterQualitySdd.value, endDateWaterQualitySdd)"
                           [attr.disabled]="checkboxWaterQualitySdd.checked  && sddOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>
            <hr>
            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [disabled]="disabledWaterQualityCdm" [checked]="selectedWaterQualityCdm"
                        #checkboxWaterQualityCdm
                        (change)="onServiceSeleted('waterquality-cdm', checkboxWaterQualityCdm)"
                        class="form-check-input" type="checkbox" value="" id="flexCheckCheckedWaterQualityCdm">
                     <label class="form-check-label" for="flexCheckCheckedWaterQualityCdm">
                        Coloured Dissolved Organic Matter
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <form disalbed="checkboxWaterQualityCdm">
                     <div class="form-check form-switch">
                        <input [checked]='selectedWaterQualityCdmContinuous' class="form-check-input"
                           #cdmContinuousMonitoring
                           (change)="onTypeMonitoringSelect('waterquality-cdm', 'continuous', cdmContinuousMonitoring)"
                           [attr.disabled]="checkboxWaterQualityCdm.checked && !disabledWaterQualityCdmContinuous ? null : ''"
                           type="checkbox" name="flexRadioDefaultWaterQualityCdm"
                           id="flexRadioDefaultWaterQualityCdmContinuous">
                        <label class="form-check-label" for="flexRadioDefaultWaterQualityCdmContinuous">
                           Continuous Monitoring
                        </label>
                     </div>
                     <div class="form-check form-switch">
                        <input [checked]='selectedWaterQualityCdmOnDemand' class="form-check-input"
                           #cdmOndemandMonitoring
                           (change)="onTypeMonitoringSelect('waterquality-cdm', 'on-demand', cdmOndemandMonitoring)"
                           [attr.disabled]="checkboxWaterQualityCdm.checked ? null : ''" type="checkbox"
                           name="flexRadioDefaultWaterQualityCdm" id="flexRadioDefaultWaterQualityCdmOndemand">
                        <label class="form-check-label" for="flexRadioDefaultWaterQualityCdmOndemand">
                           On-demand
                        </label>
                     </div>
                  </form>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateWaterQualityCdm
                           (change)="onDateSelect('startDate', 'waterquality-cdm', startDateWaterQualityCdm.value, startDateWaterQualityCdm)"
                           [attr.disabled]="checkboxWaterQualityCdm.checked  && cdmOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateWaterQualityCdm [min]="startDateWaterQualityCdm.value"
                           (change)="onDateSelect('endDate', 'waterquality-cdm', endDateWaterQualityCdm.value, endDateWaterQualityCdm)"
                           [attr.disabled]="checkboxWaterQualityCdm.checked  && cdmOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>

   <div class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-2 pb-4 mb-4 rounded">

            <div class="row justify-content-end">
               <div class="col-12 col-md-8 mb-md-4">
                  <h5>Land-Water Transition Zone Change Detection</h5>
               </div>
               <div class="col-12 col-md-2 mb-4 text-center text-md-end">
                  <a href="/land-water-transition-zone" target="_blank"  class="btn btn-sm btn-primary mt-md-3">
                     <fa-icon icon="info-circle"></fa-icon> Details
                  </a>
               </div>
            </div>

            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input #checkboxLandWaterTransitionZoneHydroperiod
                        [checked]="selectedLandWaterTransitionZoneHydroperiod"
                        [disabled]="disabledLandWaterTransitionZoneHydroperiod"
                        (change)="onServiceSeleted('landwatertransitionzone-hydroperiod', checkboxLandWaterTransitionZoneHydroperiod)"
                        class="form-check-input" type="checkbox" value=""
                        id="flexCheckCheckedLandWaterTransitionZoneHydroperiod">
                     <label class="form-check-label" for="flexCheckCheckedLandWaterTransitionZoneHydroperiod">
                        Hydroperiod
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <!-- <div class="form-check form-switch">
                          <input   [attr.disabled]="''" class="form-check-input" type="checkbox" name="" id="">
                          <label class="form-check-label" for="">
                             Continuous Monitoring
                          </label>
                       </div> -->
                  <div class="form-check form-switch">
                     <input [checked]='selectedLandWaterTransitionZoneHydroperiodContinuous' #lwtzhpOndemandMonitoring
                        (change)="onTypeMonitoringSelect('landwatertransitionzone-hydroperiod', 'on-demand', lwtzhpOndemandMonitoring)"
                        [attr.checked]="checkboxLandWaterTransitionZoneHydroperiod.checked? 'checked' : null"
                        [attr.disabled]="checkboxLandWaterTransitionZoneHydroperiod.checked ? null : ''"
                        class="form-check-input" type="checkbox"
                        name="flexRadioDefaultLandWaterTransitionZoneHydroperiod"
                        id="flexRadioDefaultLandWaterTransitionZoneHydroperiodOndemand">
                     <label class="form-check-label" for="flexRadioDefaultLandWaterTransitionZoneHydroperiodOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateLandWaterTransitionZoneHydroperiod
                           (change)="onDateSelect('startDate', 'landwatertransitionzone-hydroperiod', startDateLandWaterTransitionZoneHydroperiod.value, startDateLandWaterTransitionZoneHydroperiod)"
                           [attr.disabled]="checkboxLandWaterTransitionZoneHydroperiod.checked  && lwtzhpOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateLandWaterTransitionZoneHydroperiod
                           [min]="startDateLandWaterTransitionZoneHydroperiod.value"
                           (change)="onDateSelect('endDate', 'landwatertransitionzone-hydroperiod', endDateLandWaterTransitionZoneHydroperiod.value, endDateLandWaterTransitionZoneHydroperiod)"
                           [attr.disabled]="checkboxLandWaterTransitionZoneHydroperiod.checked  && lwtzhpOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
               <div class="col-sm">
                  <!-- <div class="form-check form-switch">
                          <input   [attr.disabled]="''" class="form-check-input" type="checkbox" name="" id="">
                          <label class="form-check-label" for="">
                             Continuous Monitoring
                          </label>
                       </div> -->
                  <div class="form-check">
                     <input [attr.disabled]="checkboxLandWaterTransitionZoneHydroperiod.checked ? null : ''" checked
                        (change)="onModeExecutionSelect('SENT2', 'landwatertransitionzone-hydroperiod')"
                        class="form-check-input" type="radio" name="flexRadioDefaultlwtztd"
                        id="flexRadioDefaultlwtztdMode1">
                     <label class="form-check-label" for="flexRadioDefaultlwtztdMode1">
                        Multispectral Data
                        <fa-icon icon="circle-info" [ngbPopover]="popContentLwtzTdModeRadarData"></fa-icon>
                     </label>
                  </div>
                  <div class="form-check">
                     <input [attr.disabled]="checkboxLandWaterTransitionZoneHydroperiod.checked ? null : ''"
                        (change)="onModeExecutionSelect('SENT1-SENT2', 'landwatertransitionzone-hydroperiod')"
                        class="form-check-input" type="radio" name="flexRadioDefaultlwtztd"
                        id="flexRadioDefaultlwtztdMode2">
                     <label class="form-check-label" for="flexRadioDefaultlwtztdMode2">
                        Multispectral + Radar Data
                        <fa-icon icon="circle-info" [ngbPopover]="popContentLwtzTdModeRadarDataMultispectral"></fa-icon>
                     </label>
                  </div>
               </div>
            </div>
            <hr>
            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input #checkboxLandWaterTransitionZoneTwoDates [checked]="selectedLandWaterTransitionZoneTwoDates"
                        [disabled]="disabledLandWaterTransitionZoneTwoDates"
                        (change)="onServiceSeleted('landwatertransitionzone-twodates', checkboxLandWaterTransitionZoneTwoDates)"
                        class="form-check-input" type="checkbox" value=""
                        id="flexCheckCheckedLandWaterTransitionZonePeriod">
                     <label class="form-check-label" for="flexCheckCheckedLandWaterTransitionZonePeriod">
                        Two Dates
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <!-- <div class="form-check form-switch">
                          <input  [attr.disabled]="''" class="form-check-input" type="checkbox" name="" id="">
                          <label class="form-check-label" for="">
                             Continuous Monitoring
                          </label>
                       </div> -->
                  <div class="form-check form-switch">
                     <input [checked]='selectedLandWaterTransitionZoneTwoDatesOnDemand' #lwtztdOndemandMonitoring
                        (change)="onTypeMonitoringSelect('landwatertransitionzone-twodates', 'on-demand', lwtztdOndemandMonitoring)"
                        [attr.checked]="checkboxLandWaterTransitionZoneTwoDates.checked? 'checked' : null"
                        [attr.disabled]="checkboxLandWaterTransitionZoneTwoDates.checked ? null : ''"
                        class="form-check-input" type="checkbox" name="flexRadioDefaultLandWaterTransitionZonePeriod"
                        id="flexRadioDefaultLandWaterTransitionZonePeriodOndemand">
                     <label class="form-check-label" for="flexRadioDefaultLandWaterTransitionZonePeriodOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateLandWaterTransitionZoneTwoDates
                           (change)="onDateSelect('startDate', 'landwatertransitionzone-twodates', startDateLandWaterTransitionZoneTwoDates.value, startDateLandWaterTransitionZoneTwoDates)"
                           [attr.disabled]="checkboxLandWaterTransitionZoneTwoDates.checked  && lwtztdOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateLandWaterTransitionZoneTwoDates
                           [min]="startDateLandWaterTransitionZoneTwoDates.value"
                           (change)="onDateSelect('endDate', 'landwatertransitionzone-twodates', endDateLandWaterTransitionZoneTwoDates.value, endDateLandWaterTransitionZoneTwoDates)"
                           [attr.disabled]="checkboxLandWaterTransitionZoneTwoDates.checked  && lwtztdOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check">
                     <input [attr.disabled]="checkboxLandWaterTransitionZoneTwoDates.checked ? null : ''" checked
                        (change)="onModeExecutionSelect('SENT2', 'landwatertransitionzone-twodates')"
                        class="form-check-input" type="radio" name="flexRadioDefaultlwtzhp"
                        id="flexRadioDefaultlwtzhpMode1">
                     <label class="form-check-label" for="flexRadioDefaultlwtzhpMode1">
                        Multispectral Data
                        <fa-icon icon="circle-info" [ngbPopover]="popContentLwtzModeMultispectral"></fa-icon>
                     </label>
                  </div>
                  <div class="form-check">
                     <input [attr.disabled]="checkboxLandWaterTransitionZoneTwoDates.checked ? null : ''"
                        (change)="onModeExecutionSelect('SENT1-SENT2', 'landwatertransitionzone-twodates')"
                        class="form-check-input" type="radio" name="flexRadioDefaultlwtzhp"
                        id="flexRadioDefaultlwtzhpMode2">
                     <label class="form-check-label" for="flexRadioDefaultlwtzhpMode2">
                        Multispectral + Radar Data
                        <fa-icon icon="circle-info" [ngbPopover]="popContentLwtzModeRadarDataMultispectral"></fa-icon>
                     </label>
                  </div>
                  <div class="form-check">
                     <input [attr.disabled]="checkboxLandWaterTransitionZoneTwoDates.checked ? null : ''"
                        (change)="onModeExecutionSelect('SENT1', 'landwatertransitionzone-twodates')"
                        class="form-check-input" type="radio" name="flexRadioDefaultlwtzhp"
                        id="flexRadioDefaultlwtzhpMode3">
                     <label class="form-check-label" for="flexRadioDefaultlwtzhpMode3">
                        Radar Data
                        <fa-icon icon="circle-info" [ngbPopover]="popContentLwtzModeRadarData"></fa-icon>
                     </label>
                  </div>

               </div>
            </div>

         </div>
      </div>
   </div>

   <div class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-2 pb-4 mb-4 rounded">

            <div class="row justify-content-end">
               <div class="col-12 col-md-8 mb-md-4">
                  <h5>Bloom Events Detection</h5>
               </div>
               <div class="col-12 col-md-2 mb-4 text-center text-md-end">
                  <a href="/bloom-event" target="_blank"  class="btn btn-sm btn-primary mt-md-3">
                     <fa-icon icon="info-circle"></fa-icon> Details
                  </a>
               </div>
            </div>

            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [disabled]="disabledBloomEventDetectionHab" [checked]="selectedBloomEventDetectionHab"
                        #checkboxBloomEventHab (change)="onServiceSeleted('bloomevent-hab', checkboxBloomEventHab)"
                        class="form-check-input" type="checkbox" value="" id="flexCheckCheckedBloomEventDetection">
                     <label class="form-check-label" for="flexCheckCheckedBloomEventDetection">
                        Harmful Algae Bloom
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check form-switch">
                     <input [checked]='selectedBloomEventDetectionHabContinuous' #bloomeventhabContinuousMonitoring
                        (change)="onTypeMonitoringSelect('bloomevent-hab', 'continuous', bloomeventhabContinuousMonitoring)"
                        [attr.disabled]="checkboxBloomEventHab.checked && !disabledBloomEventDetectionHabContinuous ? null : ''"
                        class="form-check-input" type="checkbox" name="flexRadioDefaultBloomEvent"
                        id="flexRadioDefaultBloomEventDetectionContinuous">
                     <label class="form-check-label" for="flexRadioDefaulBloomEventDetectionContinuous">
                        Continuous Monitoring
                     </label>
                  </div>
                  <div class="form-check form-switch">
                     <input #bloomeventhabOndemandMonitoring
                        (change)="onTypeMonitoringSelect('bloomevent-hab', 'on-demand', bloomeventhabOndemandMonitoring)"
                        [attr.disabled]="checkboxBloomEventHab.checked ? null : ''" class="form-check-input"
                        type="checkbox" name="flexRadioDefaultBloomEvent"
                        id="flexRadioDefaultBloomEventDetectionOndemand">
                     <label class="form-check-label" for="flexRadioDefaultBloomEventDetectionOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateBloomEventHab
                           (change)="onDateSelect('startDate', 'bloomevent-hab', startDateBloomEventHab.value, startDateBloomEventHab)"
                           [attr.disabled]="checkboxBloomEventHab.checked  && bloomeventhabOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateBloomEventHab [min]="startDateBloomEventHab.value"
                           (change)="onDateSelect('endDate', 'bloomevent-hab', endDateBloomEventHab.value, endDateBloomEventHab)"
                           [attr.disabled]="checkboxBloomEventHab.checked  && bloomeventhabOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>

   <div class="row">
      <div class="col-12">
         <div class="bg-light p-4 pt-2 pb-4 mb-4 rounded">

            <div class="row justify-content-end">
               <div class="col-12 col-md-8 mb-md-4">
                  <h5>Extreme Events Detection</h5>
               </div>
               <div class="col-12 col-md-2 mb-4 text-center text-md-end">
                  <a href="/extreme-event" target="_blank" class="btn btn-sm btn-primary mt-md-3">
                     <fa-icon icon="info-circle"></fa-icon> Details
                  </a>
               </div>
            </div>

            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [disabled]="disabledExtremeEventOilSpillDetection"
                        [checked]="selectedExtremeEventOilSpillDetection" #checkboxExtremeEventOilSpill
                        (change)="onServiceSeleted('extremeevent-oilspill', checkboxExtremeEventOilSpill)"
                        class="form-check-input" type="checkbox" value="" id="flexCheckCheckedOilSpillDetection">
                     <label class="form-check-label" for="flexCheckCheckedOilSpillDetection">
                        Oil Spill Detection
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check form-switch">
                     <input [checked]='selectedExtremeEventOilSpillDetectionContinuous'
                        #extremeeventOilSpillContinuousMonitoring
                        (change)="onTypeMonitoringSelect('extremeevent-oilspill', 'continuous', extremeeventOilSpillContinuousMonitoring)"
                        [attr.disabled]="checkboxExtremeEventOilSpill.checked && !disabledExtremeEventOilSpillDetectionContinuous ? null : ''"
                        class="form-check-input" type="checkbox" name="flexRadioDefaultOilSpill"
                        id="flexRadioDefaultOilSpillDetectionContinuous">
                     <label class="form-check-label" for="flexRadioDefaultOilSpillDetectionContinuous">
                        Continuous Monitoring
                     </label>
                  </div>
                  <div class="form-check form-switch">
                     <input [checked]='selectedExtremeEventOilSpillDetectionOnDemand'
                        #extremeeventOilSpillOndemandMonitoring
                        (change)="onTypeMonitoringSelect('extremeevent-oilspill', 'on-demand', extremeeventOilSpillOndemandMonitoring)"
                        [attr.disabled]="checkboxExtremeEventOilSpill.checked ? null : ''" class="form-check-input"
                        type="checkbox" name="flexRadioDefaultOilSpill" id="flexRadioDefaultOilSpillDetectionOndemand">
                     <label class="form-check-label" for="flexRadioDefaultOilSpillDetectionOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateExtremeEventOilSpill
                           (change)="onDateSelect('startDate', 'extremeevent-oilspill', startDateExtremeEventOilSpill.value, startDateExtremeEventOilSpill)"
                           [attr.disabled]="checkboxExtremeEventOilSpill.checked  && extremeeventOilSpillOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateExtremeEventOilSpill [min]="startDateExtremeEventOilSpill.value"
                           (change)="onDateSelect('endDate', 'extremeevent-oilspill', endDateExtremeEventOilSpill.value, endDateExtremeEventOilSpill)"
                           [attr.disabled]="checkboxExtremeEventOilSpill.checked  && extremeeventOilSpillOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>
            <hr>
            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [disabled]="disabledExtremeEventMuddyWaterDetection"
                        [checked]="selectedExtremeEventMuddyWaterDetection" #checkboxExtremeEventMuddyWater
                        (change)="onServiceSeleted('extremeevent-muddywater', checkboxExtremeEventMuddyWater)"
                        class="form-check-input" type="checkbox" value="" id="flexCheckCheckedMuddyWater">
                     <label class="form-check-label" for="flexCheckCheckedMuddyWater">
                        Muddy Water
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check form-switch">
                     <input [checked]='selectedExtremeEventMuddyWaterDetectionContinuous'
                        #extremeeventMuddyWaterContinuousMonitoring
                        (change)="onTypeMonitoringSelect('extremeevent-muddywater', 'continuous', extremeeventMuddyWaterContinuousMonitoring)"
                        [attr.disabled]="checkboxExtremeEventMuddyWater.checked && !disabledExtremeEventMuddyWaterDetectionContinuous ? null : ''"
                        class="form-check-input" type="checkbox" name="flexRadioDefaultMuddyWater"
                        id="flexRadioDefaultMuddyWaterContinuous">
                     <label class="form-check-label" for="flexRadioDefaultMuddyWaterContinuous">
                        Continuous Monitoring
                     </label>
                  </div>
                  <div class="form-check form-switch">
                     <input [checked]='selectedExtremeEventMuddyWaterDetectionOnDemand'
                        #extremeeventMuddyWaterOndemandMonitoring
                        (change)="onTypeMonitoringSelect('extremeevent-muddywater', 'on-demand', extremeeventMuddyWaterOndemandMonitoring)"
                        [attr.disabled]="checkboxExtremeEventMuddyWater.checked ? null : ''" class="form-check-input"
                        type="checkbox" name="flexRadioDefaultMuddyWater" id="flexRadioDefaultMuddyWaterOndemand">
                     <label class="form-check-label" for="flexRadioDefaultMuddyWaterOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateExtremeEventMuddyWater
                           (change)="onDateSelect('startDate', 'extremeevent-muddywater', startDateExtremeEventMuddyWater.value, startDateExtremeEventMuddyWater)"
                           [attr.disabled]="checkboxExtremeEventMuddyWater.checked  && extremeeventMuddyWaterOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateExtremeEventMuddyWater [min]="startDateExtremeEventMuddyWater.value"
                           (change)="onDateSelect('endDate', 'extremeevent-muddywater', endDateExtremeEventMuddyWater.value, endDateExtremeEventMuddyWater)"
                           [attr.disabled]="checkboxExtremeEventMuddyWater.checked  && extremeeventMuddyWaterOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>
            <hr>
            <div class="row align-items-center">
               <div class="col-sm">
                  <div class="form-check">
                     <input [disabled]="disabledExtremeEventFloodDetection"
                        [checked]="selectedExtremeEventFloodDetection" #checkboxExtremeEventFlood
                        (change)="onServiceSeleted('extremeevent-flood', checkboxExtremeEventFlood)"
                        class="form-check-input" type="checkbox" value="" id="flexCheckCheckedFloodEventDetection">
                     <label class="form-check-label" for="flexCheckCheckedFloodEventDetection">
                        Flood Event Detection
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="form-check form-switch">
                     <input [checked]='selectedExtremeEventFloodDetectionContinuous'
                        #extremeeventFloodContinuousMonitoring
                        (change)="onTypeMonitoringSelect('extremeevent-flood', 'continuous', extremeeventFloodContinuousMonitoring)"
                        [attr.disabled]="checkboxExtremeEventFlood.checked && !disabledExtremeEventFloodDetectionContinuous ? null : ''"
                        class="form-check-input" type="checkbox" name="flexRadioDefaultFloodEvent"
                        id="flexRadioDefaultFloodEventDetectionContinuous">
                     <label class="form-check-label" for="flexRadioDefaultFloodEventDetectionContinuous">
                        Continuous Monitoring
                     </label>
                  </div>
                  <div class="form-check form-switch">
                     <input [checked]='selectedExtremeEventFloodDetectionOnDemand' #extremeeventFloodOndemandMonitoring
                        (change)="onTypeMonitoringSelect('extremeevent-flood', 'on-demand', extremeeventFloodOndemandMonitoring)"
                        [attr.disabled]="checkboxExtremeEventFlood.checked ? null : ''" class="form-check-input"
                        type="checkbox" name="flexRadioDefaultFloodEvent"
                        id="flexRadioDefaultFloodEventDetectionOndemand">
                     <label class="form-check-label" for="flexRadioDefaultFloodEventDetectionOndemand">
                        On-demand
                     </label>
                  </div>
               </div>
               <div class="col-sm">
                  <div class="row">
                     <div class="col">
                        <label for="datepicker" class="form-label">Start Date</label>
                        <input #startDateExtremeEventFlood
                           (change)="onDateSelect('startDate', 'extremeevent-flood', startDateExtremeEventFlood.value, startDateExtremeEventFlood)"
                           [attr.disabled]="checkboxExtremeEventFlood.checked  && extremeeventFloodOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                     <div class="col">
                        <label for="datepicker" class="form-label">End Date</label>
                        <input #endDateExtremeEventFlood [min]="startDateExtremeEventFlood.value"
                           (change)="onDateSelect('endDate', 'extremeevent-flood', endDateExtremeEventFlood.value, endDateExtremeEventFlood)"
                           [attr.disabled]="checkboxExtremeEventFlood.checked  && extremeeventFloodOndemandMonitoring.checked? null : ''"
                           type="date" class="datepicker form-control">
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>

   <div class="row">
      <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
         <button (click)="onClickSubmitRequest()" type="submit" class="btn btn-secondary btn-lg pt-2 pb-2">
            Submit Request <fa-icon icon="arrow-right" class="right"></fa-icon>
         </button>
      </div>
   </div>

</form>