<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Map and Data Navigator</h3>
            <p class="text-start lead">
                The Map and Data navigator is a web application which enables registered users to <strong>visualize and navigate on a map</strong> the requested data products generated by the system. It also enables to <strong>visualize metadata</strong> associated with each data product and to <strong>generate statistics</strong> on the visualized parameters (e.g., mean value of chlorophyll-a on the visualized area of interest). It also includes the GUF system that enable users to <strong>provide feedback</strong> on a data product/layer visualized on the map.
            </p>           
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="https://www.ogc3.grumets.cat/wqems/" class="btn btn-secondary btn-lg pt-2 pb-2" target="_blank">
            Go to the Map and Data Navigator <fa-icon icon="arrow-right" class="right"></fa-icon>
        </a>
    </div>
</div>