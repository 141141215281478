import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AlertAPIsService } from './api/alertAPIs.service';
import { AlertConfigurationAPIsService } from './api/alertConfigurationAPIs.service';
import { ProcessingChainsTestAPIsService } from './api/processingChainsTestAPIs.service';
import { ProductAPIsService } from './api/productAPIs.service';
import { RequestAPIsService } from './api/requestAPIs.service';
import { RequestedReportAPIsService } from './api/requestedReportAPIs.service';
import { RequestedServiceAPIsService } from './api/requestedServiceAPIs.service';
import { SocialAlertAPIsService } from './api/socialAlertAPIs.service';
import { SocialAlertTypeAPIsService } from './api/socialAlertTypeAPIs.service';
import { UserAPIsService } from './api/userAPIs.service';
import { WaterBodyAPIsService } from './api/waterBodyAPIs.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AlertAPIsService,
    AlertConfigurationAPIsService,
    ProcessingChainsTestAPIsService,
    ProductAPIsService,
    RequestAPIsService,
    RequestedReportAPIsService,
    RequestedServiceAPIsService,
    SocialAlertAPIsService,
    SocialAlertTypeAPIsService,
    UserAPIsService,
    WaterBodyAPIsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
