<div class="row">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h3>Requested Services</h3>
      <p class="text-center lead mt-4 mb-4">
        On this page you can view the list of services that you requested and their status. When a request of service is submitted, it is first evaluated by the WQeMS team ("Waiting for evaluation" status <span class="circle-preview wait" title="Waiting for evaluation"></span>). When evaluated ("Evaluated" status <span class="circle-preview ok" title="Evaluated"></span>), accepted services will start processing and the related generated data will be available through the different tools of WQeMS.
      </p>
      <p class="text-center">
        <a class="btn btn-lg btn-secondary" href="request-service" role="button">
          Request service <fa-icon icon="arrow-right"></fa-icon>
        </a>
      </p>
    </div>
  </div>
</div>

<!-- <div class="row">
  <div class="col-12">
    <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
      <h4>Your Requested Services</h4>
    </div>
  </div>
</div> -->

<div class="row">
  <div class="col-12">
    <app-my-requested-services-component></app-my-requested-services-component>
  </div>
</div>
