/**
 * WQeMS: RestAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type SourceTypeEnum = 'twitter' | 'citObs' | 'mobile';

export const SourceTypeEnum = {
    Twitter: 'twitter' as SourceTypeEnum,
    CitObs: 'citObs' as SourceTypeEnum,
    Mobile: 'mobile' as SourceTypeEnum
};