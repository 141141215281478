<div class="row">
    <div class="col-12">
      <div class="bg-light p-4 pt-2 pb-2 mb-4 rounded">
        <h3>Account Developer</h3>
      </div>
    </div>
  </div>
  
  
  
  <div class="row">
    <div class="col-12">
      <div class="bg-light p-4 pt-2 pb-2 mb-4 rounded">
  
      
  
        <div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="idClient" class="form-label">Client Name</label>
              <div class="input-group mb-3">
                <input *ngIf="userClientCompleteGetDTO!=undefined && userClientCompleteGetDTO!.idClient " disabled="true" type="text" class="form-control"  [(ngModel)]="userClientCompleteGetDTO!.idClient" aria-label="Recipient's username">
              </div>
              
            </div>
            <div class="col-md-6 mb-3">
              <label for="clientId" class="form-label">Client Id</label>
              <div class="input-group mb-3">
                <input  *ngIf="userClientCompleteGetDTO!=undefined && userClientCompleteGetDTO!.clientId "  disabled="true" type="text" [(ngModel)]="userClientCompleteGetDTO!.clientId"  class="form-control">
              </div>
              
            </div>
          </div>
        </div>
  
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Client Secret</label>
          <div class="input-group mb-3">
            <input  *ngIf="userClientCompleteGetDTO!=undefined && userClientCompleteGetDTO!.clientSecret "  disabled="true" type="{{typeFieldClientSecret}}" [(ngModel)]="userClientCompleteGetDTO!.clientSecret"  class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2">
            <button class="btn btn-outline-secondary" type="button" (click)="onClickShowHide()" id="button-addon2">Show/Hide</button>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <button ng-disabled="requestReportForm.$invalid" (click)="onClickGetToken()" type="submit" class="btn btn-secondary btn-lg pt-2 pb-2">
            Get Token <fa-icon icon="arrow-right" class="right"></fa-icon>
          </button>
    </div>

    <div class="input-group">
        <span class="input-group-text">Result</span>
        <textarea class="form-control" disabled="true" [(ngModel)]="token" aria-label="With textarea"></textarea>
      </div>

  </div>
    