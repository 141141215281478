<div class="modal-content">
  <div *ngIf="requestPartialGetDTOs == undefined" class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div class="modal-body">
    <div class="table-wrapper">
      <table *ngIf="requestPartialGetDTOs != undefined" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Request</th>
            <th scope="col" style="width: 120px;">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let requestPartialGetDTO of requestPartialGetDTOs; index as i">
            <td>
              <div class="accordion" id="myAccordion">

                <div class="accordion-item">
                  <h2 class="accordion-header" id="heading{{ i }}">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse'+ i" aria-expanded="true" [attr.aria-controls]="'collapse'+ i">
                      {{requestPartialGetDTO.requestDate | date: 'd/M/y'}}
                    </button>
                  </h2>
                  <div id="collapse{{ i }}" class="accordion-collapse collapse" [attr.aria-controls]="'heading'+ i"
                    data-bs-parent="#myAccordion">
                    <div class="accordion-body">

                      <div class="table-wrapper">
                        <table class="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Area of Interest (AOI)</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container
                              *ngFor="let requestPartialGetDTOrequestedService of requestPartialGetDTO.requestedServices; index as j">
                              <tr>
                                <th scope="row">{{j+1}}</th>
                                <td>{{requestPartialGetDTOrequestedService.waterBody?.nameText}}</td>
                                <td class="text-center"><button type="button" class="btn btn-primary"
                                    (click)="openModal(requestPartialGetDTOrequestedService, requestPartialGetDTO.elaborated)">
                                    <fa-icon icon="search"></fa-icon> Details</button>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </td>
            <td class="text-center">
              <span class="circle" [ngClass]="{'ok': requestPartialGetDTO.elaborated === true, 'wait' : requestPartialGetDTO.elaborated === false }"
              [attr.title]= "requestPartialGetDTO.elaborated === true ? 'Evaluated' : 'Waiting for evaluation'"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>