<!-- Sezione Titolo + 1° Testo -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Land Water Transition Zone</h3>

            <div class="accordion" id="accordion1">
              <div class="accordion-item">
                <h2 class="accordion-header m-0" id="headingOne">
                  <button class="accordion-button bg-primary" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Service Description
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body bg-white">
                    Water extent fluctuations influence the presence and concentration of solid material and substances in the water. In case the water is flooding additional land areas, pollutants or other substances can potentially be introduced in the water reservoirs, requiring immediate assessment and follow-up actions. In case water retreats, because of over-abstraction or evaporation, the concentration of dissolved substances may increase possibly over certain limits. Moreover, a great decrease in water extent can have an impact on the inflow or speed of water in the water utilities infrastructure. For the aformentioned reasons, it is beneficial for the water utilities to be able to detect, map and investigate all these incidents. This will further support the decision-making process regarding the water quality monitoring.
                    <br><br>
                    Land Water Transition Zone Change Detection (LWTZCD) service exploits earth observation data from the Copernicus mission, to capture the variabilities in land/ water cover in open surface water reservoirs. ONDA DIAS provider and computation resources will be exploited for the access to the satellite data and the execution of the processing chains.
                    <br><br>
                    The pilot use cases are the open surface water reservoirs of Polyphytos in Greece and Giaretta in Italy. Leveraging the WaterMask [1] and Hydroperiod modules, developed in the Horizon 2020 ECOPOTENTIAL project, the LWTZCD service will provide maps with a spatial resolution of 10m, which indicate:
                    
                    <ul class="text-start">
                      <li>The transition zones from land to water and vice versa for two instances in time</li>
                      <li>The total number of inundation days per pixel through a given time.</li>
                    </ul>  

                    The user will be able to request for a specific product through the WQeMS dedicated platform.
                    <br><br>
                    To enhance the temporal frequency, two modes will be offered at each case: One which uses information solely generated by multispectral data (Sentinel-2), and a second which uses a fusion approach [2] based on multispectral and Synthetic Aperture Radar (SAR) data (Sentinel-2 and Sentinel-1). Especially, for the hydroperiod estimation an adequate number of available products (e.g. every 15 days) is required to achieve an acceptable accuracy. Thus, in case of periods with persistent cloud cover, the lack of optical data makes the use of the Radar ones necessary.
                  </div>
                </div>
              </div>
            </div>
      
            <div class="accordion" id="accordion2">
              <div class="accordion-item">
                <h2 class="accordion-header m-0" id="headingTwo">
                  <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Service Results
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body bg-white">
                    To validate the inundation maps for Polyphytos pilot use case, Google Earth Images (in combination with the bathymetry map, EYATH observations, and spot wise Very High Resolution imagery < 1m2), whose acquisition date coincides with the ones of Sentinel-2, were used as the reference overall accuracy of ≥ 98% was achieved. To detect the variabilities in the water extent for two instances in time, inundations maps are generated for each date. For example, in Figure 1 and 2, the land/ cover transition between the dates 07-07-2021 and 31-08-2021, and 21-10-2017 and 05-12-2017 is presented, respectively. Precisely, the areas where water has retreated are depicted with yellow color whereas the flooded areas with light blue.
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion" id="accordion3">
              <div class="accordion-item">
                <h2 class="accordion-header m-0" id="headingThree">
                  <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Gain for the user
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body bg-white">
                    The service allows anyone to get access to water quality information in a convenient manner. In the
                    Finnish use case, the latest values in May indicate increasing trend in algal biomass. This is expected as
                    the algal bloom season was approaching.
                    <br><br>
                    In the German use case, the focus now lies on validating past events, such as the one shown before, before
                    looking into the future in the form of the operational (monitoring) service for the water authorities.
                  </div>
                </div>
              </div>
            </div>
      
            <div class="accordion" id="accordion4">
              <div class="accordion-item">
                <h2 class="accordion-header m-0" id="headingThree">
                  <button class="accordion-button bg-primary collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Service Specifications
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body bg-white">
                    <b>Land Water Transition Zone Change Detection (*) </b>
                    <br>
                    <br>
                    <b>Type</b>: Slow / Monitoring (**)
                    <br>
                    <b>Time coverage/frequency</b>: normally 2-6 days (minimum time interval depending on S2 image availability, fused with S1 images the time interval is usually less than 6 days)
                    <br>
                    <b>Availability</b>: systematic (depending on operational system configuration) or on demand 
                    <br><br>
                    <i><u>Input parameters</u></i>
                    <br>
                    <i>Area</i>: area coordinates; shapefile, kml, identifiable location name
                    <br>
                    <i>Time</i>: start/end dates
                    <br><br>
                    <i><u>Input data</u></i>
                    <br>
                    <i>EO satellite data</i>:
                    <ul>
                      <li>Copernicus Sentinel-2 (S2) images</li>
                      <li>Copernicus Sentinel-1 (S1) (optional in case of lack of S2 cloud-free images)</li>
                    </ul>
                    <br>
                    <i>Spatial resolution</i>: nominal 10m or 20 m (this may vary, if different resolution products will be used, e.g., if we adapt for Landsat or VHR data)
                    <br><br>
                    <i>Temporal Resolution</i>:
                    <br>
                    <ul>
                      <li><i>Sentinel-2: 5 days (depended on cloud coverage over the sample area)</i></li> 
                      <li><i>Sentinel-1: 6 days</i></li> 
                      <li><i>For the fused application (S1, S2 utilization) even less than 5 days</i></li>
                    </ul>
                    <br>
                    <i>Additional data to use (in case of service expansion and user request):</i>
                    <br>
                    <ul>
                      <li><i>VHR data (that include the SWIR band)</i></li> 
                      <li><i>Landsat Data</i></li> 
                    </ul>
                    <br>
                    <i>Thematic layers (in case of the "Two dates Service Element"):</i> 
                    <ul>
                      <li>Corine Land Cover (CLC) 2018 (or a more recent land cover map - optional)</li>
                    </ul>
                    <br>
                    <i><u>Timeliness</u></i>: <br>
                    Upon availability of S2, S1 data in ONDA catalogue (normally a few hours after sensing).
                    <br>

                    <br>
                    <i><u><b>A. Two dates Service Element: </b></u></i>
                    <br><br>
                    <i><u>Output product</u></i>
                    <br>
                    Land water transition zone maps, which indicate the change of the pixel status from non-inundated to inundated and vice versa between the two dates the user provides. The satellite products, selected for the processing, are the ones with the minimum temporal distance from the dates the user indicates and at the same time their cloud coverage property is less than a pre-defined threshold value.
                    <br><br>
                    <i>Format</i>: GeoTIFF raster files
                    <br>
                    <i>Spatial resolution</i>:  10 m (nominal/ pixel size, input data include S-2 MSI SWIR with 20m spatial resolution), could be coarser (in case Landsat data are used) or finer (in case VHR data are used)
                    <br>
                    <i>Size [bytes]</i>: example: for a land water transition zone map a 10x10km2 area requires about 2MB of storage
                    <br>
                    <i>Data Type</i>: Byte - Eight-bit unsigned integer
                    <br><br>
                    <i><u>Metadata information including</u></i>:
                    <ul>
                      <li>the time period of interest, the local water body id, and the name of the Pilot Use Case. </li>
                      <li>the dates of the acquisition of the products, selected to create the final map. These dates might differentiate from the ones the user specifies.</li>          
                      <li>the uncertainty of the result (derived from the cloud and bad pixel presence as found, and reported for each image) (in %). An uncertainty value higher than 15% possibly indicates erroneous predictions in the final result.</li>
                      <li>the accuracy assessment score for the pixels recognized as inundated/non-inundated (based on the pilot areas' results during the project implementation phase and the literature for previously validated assessments - [1-3]). The reference layers for the validation of the inundation maps for the pilot use cases were created by digitizing on screen the border between land and water from images of higher spatial resolution. Precisely, google earth images (and other very high resolution ones with similar spatial resolution) are used, whose acquisition date coincides or is close to the date at which the satellite images were acquired. The reference layers were then juxtaposed with the inundation maps produced using Sentinel-2 or Sentinel-1 imagery and the following metrics were calculated. 
                        <br>Accuracy metrics provided:<br>
                        <ul>
                          <li>overall accuracy (%)</li>
                          <li>omission error (%)</li>
                          <li>comission error (%)</li>
                          <li>kappa coefficient</li>
                        </ul>
                      </li>
                      <li>the date the raster file and metadata created, edited or updated</li>
                      <li>the bounding box of the area of interest, the Coordinate Reference System (CRS), the dimensions of the raster</li>
                      <li>maximum, minimum pixel values and NoData values</li>
                    </ul>            
                    <br>
                    <i><u>Statistics data including</u></i>:
                    <ul>
                      <li>the area extent changing from land to water and water to land between two dates (in km2)</li>
                      <li>the percentage of each CLC class that was influenced from the transition either land-to-water or water-to-land.</li>
                    </ul> 
                    <br>
                    <i><u>Dissemination</u></i>:
                    <ul>
                      <li>FTP</li>
                      <li>OGC Web Services & APIs</li>
                      <li>dedicated web interface</li>
                    </ul>

                    <br>
                    <i><u><b>B. Hydroperiod Service Element:</b></u></i> 
                    <br><br>
                    Unless declared by the user input inundation maps will be acquired with approximately 15 days frequency on average (provided that respective frequently acquired satellite data are available for the area of interest). 
                    <br><br>
                    <i><u>Output product</u></i>
                    <br>
                    Hydroperiod maps, which indicate the total number of inundation days per pixel in a given time-period. The satellite products selected for the processing are the ones that are included in the period of interest, which the user provides and at the same time show a cloud coverage property less than a pre-defined threshold value (&#60;20% based on the spatial extent and form of the water body).  
                    <br><br>
                    <i>Format</i>: GeoTIFF raster files
                    <br>
                    <i>Spatial resolution</i>: 10 m (nominal/ pixel size, input data include S-2 MSI SWIR with 20m spatial resolution), could be coarser (in case Landsat data are used) or finer (in case VHR data are used) 
                    <br>
                    <i>Size [bytes]</i>:  example: a hydroperiod map uses about 3 MB of storage
                    <br>
                    <i>Data Type</i>: UInt16 - Sixteen-bit unsigned integer
                    <br><br>
                    <i><u>Metadata information including</u></i>:
                    <ul>
                      <li>the number of the inundation maps generated and used in the hydroperiod estimation</li>
                      <li>the dates of acquisition of the satellite products processed to create the inundation maps</li>
                      <li>the uncertainty of the service (derived from the cloud and bad pixel presence, and reported for each image) (in %) (in case the uncertainty of an image exceeds a pre-defined threshold (e.g. 20% for the extent of the processed image), the image will not be used in the processing so as not to degrade the final product.)</li>          
                      <li>accuracy assessment score for the pixels recognized as water/land (based on the pilot areas' results during the project implementation phase and the literature for previously validated assessments- [1-3] ). The reference layers for the validation of the inundation maps for the pilot use cases were created by digitizing on screen the border between land and water from images of higher resolution. Precisely, google earth images (and other very high resolution ones with similar spatial resolution) are used, whose acquisition date coincides or is close to the date at which the satellite images were acquired. The reference layers were then juxtaposed with the inundation maps produced using Sentinel-2 or Sentinel-1 imagery and the following metrics were calculated.                         
                        <br>Accuracy metrics provided:<br>
                        <ul>
                          <li>overall accuracy (%)</li>
                          <li>omission error (%)</li>
                          <li>comission error (%)</li>
                          <li>kappa coefficient</li>
                        </ul>
                      </li>
                      <li>Temporal quality metrics:<br>
                        <ul>
                          <li>mean day difference between the inundation maps used for the hydroperiod estimation</li>
                          <li>standard deviation of the days difference between inundation maps used for the hydroperiod estimation</li>
                        </ul>
                      </li>
                      <li>the date the raster file and metadata created, edited or updated</li>
                      <li>the bounding box of the area of interest, the CRS, the dimensions of the raster</li>
                      <li>maximum, minimum pixel values and NoData Values</li>
                    </ul>            
                    <br> 
                    <i><u>Important note:</u></i> In case there are no satellite imageries, which coincide with the dates the user specifies, the products with the closest proximity in time within the period of interest and with an acceptable cloud coverage are used instead. In such a case, the maximum value of the pixels of the final product might be less than the total number of the days the period of interest includes. This value would be the expected value of the pixels that reside inside the water body. 
                    <br><br>
                    <i><u>Dissemination</u></i>:
                    <ul>
                      <li>FTP</li>
                      <li>OGC Web Services & APIs</li>
                      <li>dedicated web interface</li>
                    </ul>
                    <br>
                    <br>
                    <i><u>Literature</u></i>:<br>
                    G. Kordelas, I. Manakos, D. Aragones, R. Diaz-Delgado, J. Bustamante, <a href="https://www.mdpi.com/2072-4292/10/6/910">Fast and automatic data-driven thresholding for inundation mapping with Sentinel-2 data</a>, 2018, Remote Sensing, 10, 910, DOI: 10.3390/rs10060910 
                    <br><br>
                    I. Manakos, G. Kordelas, K. Marini,<a href="https://www.tandfonline.com/doi/full/10.1080/22797254.2019.1596757%22%20/t%20%22_blank">Fusion of Sentinel-1 data with Sentinel-2 products to overcome non-favourable atmospheric conditions for the delineation of inundation maps"</a>, 2019, European Journal of Remote Sensing, DOI: 10.1080/22797254.2019.1596757
                    <br><br>
                    G. Kordelas, I. Manakos, G. Lefebvre, B. Poulin,<a href="https://www.mdpi.com/2072-4292/11/19/2251%22%20/t%20%22_blank">Automatic Inundation Mapping Using Sentinel-2 Data Applicable to Both Camargue and Doñana Biosphere Reserves</a>, 2019, Remote Sensing Journal, 11(19), 2251, DOI: https://doi.org/10.3390/rs11192251 
                    <br><br><br>
                    (*) Products are free during the project.  
                    <br>
                    (**) The service is classified with reference to the type of process (Fast/Slow) and the scope, namely, Monitoring/Emergency . 
                  </div>
                </div>
              </div>
            </div>
      
        </div>
    </div>
</div>

<!-- Sezione Carousel -->
<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 mb-5 rounded">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
            
                        <ngb-carousel *ngIf="images">
                            <ng-template ngbSlide *ngFor="let image of images">
                                <div class="picsum-img-wrapper">
                                    <img [src]="image" alt="WQeMS">
                                </div>
                            </ng-template>
                        </ngb-carousel>
            
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Sezione 2° Testo -->
<!-- <div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mt-4 mb-5 rounded">
            <p class="text-start lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore
                et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt
                ut labore et dolore magna aliqua.
            </p>
        </div>
    </div>
</div> -->

<!-- Sezione Bottone -->
<div class="row">
    <div class="d-grid gap-2 col-12 col-sm-6 mx-auto mt-5 mb-5">
        <a href="request-service/" type="button" class="btn btn-secondary btn-lg pt-2 pb-2">
            Request a Service <fa-icon icon="arrow-right" class="right"></fa-icon>
          </a>
    </div>
</div>