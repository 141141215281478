<div class="row">
    <div class="col-12">
        <div class="bg-light p-4 pt-2 pb-2 mb-5 rounded">
            <h3>Data Access</h3>
        </div>
    </div>
</div>

<div class="row">

    <div class="d-grid gap-2 col-12 col-sm-6 col-lg-4 mx-auto mb-4">
        <a href="map-and-data-navigator" class="btn btn-primary btn-lg py-4">
            <fa-icon icon="map"></fa-icon><hr>
            Map and Data Navigator
        </a>
    </div>

    <div class="d-grid gap-2 col-12 col-sm-6 col-lg-4 mx-auto mb-4">
        <a href="catalogue" class="btn btn-primary btn-lg py-4">
            <fa-icon icon="book"></fa-icon><hr>
            Catalogue
        </a>
    </div>

    <div class="d-grid gap-2 col-12 col-sm-6 col-lg-4 mx-auto mb-4">
        <a href="crowdsourcing-dashboard" class="btn btn-primary btn-lg py-4">
            <fa-icon icon="tachometer"></fa-icon><hr>
            Crowdsourcing Dashboard
        </a>
    </div>

    <div class="d-grid gap-2 col-12 col-sm-6 col-lg-4 mx-auto mb-4">
        <a href="web-services" class="btn btn-primary btn-lg py-4">
            <fa-icon icon="database"></fa-icon><hr>
            Web Services
        </a>
    </div>

    <div class="d-grid gap-2 col-12 col-sm-6 col-lg-4 mx-auto mb-4">
        <a href="ftp-access" class="btn btn-primary btn-lg py-4">
            <fa-icon icon="globe"></fa-icon><hr>
            FTP Access
        </a>
    </div>

    <div class="d-grid gap-2 col-12 col-sm-6 col-lg-4 mx-auto mb-4">
        <a href="file-server" class="btn btn-primary btn-lg py-4">
            <fa-icon icon="file"></fa-icon><hr>
            HTTP File Server
        </a>
    </div>

    <div class="d-grid gap-2 col-12 col-sm-6 col-lg-4 mx-auto mb-4">
        <a href="account-dev" class="btn btn-primary btn-lg py-4">
            <fa-icon icon="cog"></fa-icon><hr>
            Developer
        </a>
    </div>


</div>