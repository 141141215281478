import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { RequestServiceComponent } from './pages/request-service/request-service.component';
import { AlertConfigurationComponent } from './pages/alert-configuration/alert-configuration.component';
import { RequestReportComponent } from './pages/request-report/request-report.component';
import { AuthGuardAdministrator } from './guard/auth.guard.administrators';
import { AdministrationDashboardComponent } from './pages/administration-dashboard/administration-dashboard.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AboutCopernicusServicesComponent } from './pages/about-copernicus-services/about-copernicus-services.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { MyRequestedServicesComponent } from './pages/my-requested-services/my-requested-services.component';
import { MyAlertConfigurationsComponent } from './pages/my-alert-configurations/my-alert-configurations.component';
import { UserHomeComponent } from './pages/user-home/user-home.component';
import { AdministrationRequestsListComponent } from './pages/administration-requests-list/administration-requests-list.component';
import { AdministrationRequestComponent } from './pages/administration-request/administration-request.component';
import { AdministrationRequestedServiceComponent } from './pages/administration-requested-service/administration-requested-service.component';
import { CatalogueComponent } from './pages/catalogue/catalogue.component';
import { DataAccessComponent } from './pages/data-access/data-access.component';
import { TrainingComponent } from './pages/training/training.component';
import { MapAndDataNavigatorComponent } from './pages/map-and-data-navigator/map-and-data-navigator.component';
import { WebServicesComponent } from './pages/web-services/web-services.component';
import { FtpAccessComponent } from './pages/ftp-access/ftp-access.component';
import { FileServerComponent } from './pages/file-server/file-server.component';
import { CrowdsourcingMobileAppComponent } from './pages/crowdsourcing-mobile-app/crowdsourcing-mobile-app.component';
import { CrowdsourcingDashboardComponent } from './pages/crowdsourcing-dashboard/crowdsourcing-dashboard.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { AlertsComponent } from './pages/alerts/alerts.component';
import { BloomEventComponent } from './pages/bloom-event/bloom-event.component';
import { ExtremeEventComponent } from './pages/extreme-event/extreme-event.component';
import { LandWaterTransitionZoneComponent } from './pages/land-water-transition-zone/land-water-transition-zone.component';
import { WaterQualityComponent } from './pages/water-quality/water-quality.component';
import { AccountDevComponent } from './pages/account-dev/account-dev.component';
import { AboutGeossServicesComponent } from './pages/about-geoss-services/about-geoss-services.component';
import { MyAccountDevComponent } from './pages/my-account-dev/my-account-dev.component';
import { ServiceSchemasComponent } from './pages/service-schemas/service-schemas.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { MyAlertsComponent } from './pages/my-alerts/my-alerts.component';

const routes: Routes = [
  { path: 'home', component: UserHomeComponent},
  { path: '', component: UserHomeComponent},
  { path: 'request-service/:waterBodyLocalId', component: RequestServiceComponent , canActivate: [AuthGuard]},
  { path: 'request-service', component: RequestServiceComponent , canActivate: [AuthGuard]},
  { path: 'request-report', component: RequestReportComponent , canActivate: [AuthGuard]},
  { path: 'alert-configuration', component: AlertConfigurationComponent , canActivate: [AuthGuard]},
  { path: 'administration-dashboard', component: AdministrationDashboardComponent , canActivate: [AuthGuardAdministrator]},
  { path: 'administration-requests-list', component: AdministrationRequestsListComponent, canActivate: [AuthGuardAdministrator]},
  { path: 'about-copernicus-services', component: AboutCopernicusServicesComponent},
  { path: 'my-requested-service', component: MyRequestedServicesComponent , canActivate: [AuthGuard]},
  { path: 'my-alert-configurations', component: MyAlertConfigurationsComponent , canActivate: [AuthGuard]},
  { path: 'my-alerts', component: MyAlertsComponent , canActivate: [AuthGuard]},
  { path: 'forbidden', component: ForbiddenComponent},
  { path: 'administration-request/:idRequest', component: AdministrationRequestComponent, canActivate: [AuthGuard]},
  { path: 'administration-requested-service/:idRequestedService', component: AdministrationRequestedServiceComponent, canActivate: [AuthGuard]},
  { path: 'catalogue', component: CatalogueComponent },
  { path: 'data-access', component: DataAccessComponent },
  { path: 'training', component: TrainingComponent },
  { path: 'map-and-data-navigator', component: MapAndDataNavigatorComponent },
  { path: 'web-services', component: WebServicesComponent },
  { path: 'ftp-access', component: FtpAccessComponent },
  { path: 'bloom-event', component: BloomEventComponent },
  { path: 'extreme-event', component: ExtremeEventComponent },
  { path: 'land-water-transition-zone', component: LandWaterTransitionZoneComponent },
  { path: 'water-quality', component: WaterQualityComponent },
  { path: 'file-server', component: FileServerComponent },
  { path: 'crowdsourcing-mobile-app', component: CrowdsourcingMobileAppComponent },
  { path: 'account-dev', component: AccountDevComponent },
  { path: 'crowdsourcing-dashboard', component: CrowdsourcingDashboardComponent },
  { path: 'alerts', component: AlertsComponent },
  { path: 'reports', component: ReportsComponent},
  { path: 'about-geoss-services', component: AboutGeossServicesComponent},
  { path: 'my-account-dev', component: MyAccountDevComponent, canActivate: [AuthGuard]},
  { path: 'service-schemas', component: ServiceSchemasComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'cookie-policy', component: CookiePolicyComponent},
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent},
  { path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
