import { AfterViewInit, Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { RequestAPIsService, RequestCompletGetDTO, RequestPartialGetDTO } from 'src/app/services/restapiservices';
import { ActivatedRoute, Router } from '@angular/router';
import * as L from 'leaflet';
import * as shp from 'shpjs';
import 'leaflet-draw';

@Component({
  selector: 'app-administration-request',
  templateUrl: './administration-request.component.html',
  styleUrls: ['./administration-request.component.scss']
})
export class AdministrationRequestComponent implements OnInit {

  private map: any;
  drawnItems: any;


  constructor(private activatedRoute: ActivatedRoute, private keycloakService: KeycloakService, private requestAPIsService: RequestAPIsService, private router: Router) { }

  private idRequest: number | undefined;
  private token: string | undefined;
  public requestPartialGetDTO: RequestPartialGetDTO | undefined;
  public indexRequestedServiceSelected: number = 0;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.idRequest = params['idRequest'];
      this.keycloakService.getToken().then(token => {
        this.token = token;      
        this.requestAPIsService.getRequestedServicePartialByIdRequestApiV1RequestByIdRequestIdRequestPartialGet(this.idRequest as number, token).subscribe(data => {
          this.requestPartialGetDTO = data;
          console.log(data)
        }, error => {
          if (error.code == 404) {
            alert("No requests pending found..")
            this.router.navigateByUrl('home')
          }
        })  
      })
    });
  }


  onSelectRequestedService(idRequestedService: any) {
    this.router.navigateByUrl('administration-requested-service/'+idRequestedService)

  }

  onClickMakeAsElaborated() {
    if(confirm("Are you sure?")) {
      this.keycloakService.getToken().then(token => {
        this.requestAPIsService.setElaboratedByIdRequestApiV1RequestSetElaboratedByIdRequestIdRequestPut(this.idRequest as number, token).subscribe(data => {
          alert("Success")
          this.router.navigateByUrl('administration-requests-list')
        }, error => {
          alert("Error")
        })
      })
    }

  }


}
