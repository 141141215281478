import { style } from '@angular/animations';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { data } from 'jquery';
import { KeycloakService } from 'keycloak-angular';
import * as L from 'leaflet';
import * as shp from 'shpjs';
import * as jsZip from 'jszip';
import * as toGeoJSON from '@tmcw/togeojson';
import { CEnumTypeRequest, InsertRequestedServiceDTO, RequestAPIsService, RequestedServiceProductTypeCompleteGetDTO, UserAPIsService, WaterBodyAPIsService, WaterBodyGeometryGetDTO } from 'src/app/services/restapiservices';
import { RequestedServiceProductTypeItemDTO } from 'src/app/services/restapiservices';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from 'src/app/components/modals/info-modal/info-modal.component';
import { of } from 'rxjs';

@Component({
  selector: 'app-request-service',
  templateUrl: './request-service.component.html',
  styleUrls: ['./request-service.component.scss']
})
export class RequestServiceComponent implements OnInit, AfterViewInit {

  public map: any;

  token: string | undefined;
  myWaterBodyGeometryGetDTOs: WaterBodyGeometryGetDTO[] = [];

  myWaterBodyGeometryGetDTOSelected: WaterBodyGeometryGetDTO | undefined;
  predefinedListWaterBodyGeometryGetDTOs: WaterBodyGeometryGetDTO[] = [];


  public privateDisabledSelectWaterBodyByList: boolean = false;
  public privateDisabledSelectWaterBodyByShapeFile: boolean = true;

  finishedLoadWaterBodies: boolean = false; 
  finishedLoadWaterBodiesPredefinedList: boolean = false;

  selectedWaterBodyShapeFileArrayGeoJSON:any[] = [];
  selectedWaterBodyShapeFileArrayGeoJSONLayerGroup: any;

  insertRequestedServiceDTOs: InsertRequestedServiceDTO[] = []

  itemsOnMap: any;

  today: any;

  //DISABLED VARIABLEs Service
  disabledLandWaterTransitionZoneTwoDates: boolean = false;
  disabledLandWaterTransitionZoneTwoDatesContinuous: boolean = false;
  disabledLandWaterTransitionZoneTwoDatesOnDemand: boolean = false

  disabledLandWaterTransitionZoneHydroperiod: boolean = false;
  disabledLandWaterTransitionZoneHydroperiodContinuous: boolean = false;
  disabledLandWaterTransitionZoneHydroperiodOnDemand: boolean = false;

  disabledExtremeEventOilSpillDetection: boolean = true;
  disabledExtremeEventOilSpillDetectionContinuous: boolean = false;
  disabledExtremeEventOilSpillDetectionOnDemand: boolean = false;

  disabledExtremeEventMuddyWaterDetection: boolean = true;
  disabledExtremeEventMuddyWaterDetectionContinuous: boolean = false;
  disabledExtremeEventMuddyWaterDetectionOnDemand: boolean = false;

  disabledExtremeEventFloodDetection: boolean = true;
  disabledExtremeEventFloodDetectionContinuous: boolean = false;
  disabledExtremeEventFloodDetectionOnDemand: boolean = false;

  disabledWaterQualitySdd: boolean = true;
  disabledWaterQualitySddContinuous: boolean = false;
  disabledWaterQualitySddOnDemand: boolean = false;

  disabledWaterQualitySst: boolean = true;
  disabledWaterQualitySstContinuous: boolean = false;
  disabledWaterQualitySstOnDemand: boolean = false;

  disabledWaterQualityTur: boolean = true;
  disabledWaterQualityTurContinuous: boolean = true;
  disabledWaterQualityTurOnDemand: boolean = false;

  disabledWaterQualityCdm: boolean = true;
  disabledWaterQualityCdmContinuous: boolean = false;
  disabledWaterQualityCdmOnDemand: boolean = false;

  disabledWaterQualityChl: boolean = true;
  disabledWaterQualityChlContinuous: boolean = false;
  disabledWaterQualityChlOnDemand: boolean = false;

  disabledBloomEventDetectionHab: boolean = true;
  disabledBloomEventDetectionHabContinuous: boolean = false;
  disabledBloomEventDetectionHabOnDemand: boolean = false;


  //SELECTED
  selectedLandWaterTransitionZoneTwoDates: boolean = false;
  selectedLandWaterTransitionZoneTwoDatesContinuous: boolean = false;
  selectedLandWaterTransitionZoneTwoDatesOnDemand: boolean = false

  selectedLandWaterTransitionZoneHydroperiod: boolean = false;
  selectedLandWaterTransitionZoneHydroperiodContinuous: boolean = false;
  selectedLandWaterTransitionZoneHydroperiodOnDemand: boolean = false;

  selectedExtremeEventOilSpillDetection: boolean = false;
  selectedExtremeEventOilSpillDetectionContinuous: boolean = false;
  selectedExtremeEventOilSpillDetectionOnDemand: boolean = false;

  selectedExtremeEventMuddyWaterDetection: boolean = false;
  selectedExtremeEventMuddyWaterDetectionContinuous: boolean = false;
  selectedExtremeEventMuddyWaterDetectionOnDemand: boolean = false;

  selectedExtremeEventFloodDetection: boolean = false;
  selectedExtremeEventFloodDetectionContinuous: boolean = false;
  selectedExtremeEventFloodDetectionOnDemand: boolean = false;

  selectedWaterQualitySdd: boolean = false;
  selectedWaterQualitySddContinuous: boolean = false;
  selectedWaterQualitySddOnDemand: boolean = false;

  selectedWaterQualitySst: boolean = false;
  selectedWaterQualitySstContinuous: boolean = false;
  selectedWaterQualitySstOnDemand: boolean = false;

  selectedWaterQualityTur: boolean = false;
  selectedWaterQualityTurContinuous: boolean = false;
  selectedWaterQualityTurOnDemand: boolean = false;

  selectedWaterQualityCdm: boolean = false;
  selectedWaterQualityCdmContinuous: boolean = false;
  selectedWaterQualityCdmOnDemand: boolean = false;

  selectedWaterQualityChl: boolean = false;
  selectedWaterQualityChlContinuous: boolean = false;
  selectedWaterQualityChlOnDemand: boolean = false;

  selectedBloomEventDetectionHab: boolean = false;
  selectedBloomEventDetectionHabContinuous: boolean = false;
  selectedBloomEventDetectionHabOnDemand: boolean = false;

  selectedSelectOneOfThePreviouslyRequested: boolean = true;
  selectedFromAPredefinedList: boolean = false;
  selectedUploadAShapeFile: boolean = false;


  disabledSelectOneOfThePreviouslyRequested: boolean = false;
  disabledFromAPredefinedList: boolean = false;
  disabledUploadAShapeFile: boolean = false;
  
  disabledOptionSelectOneOfThePreviouslyRequested: boolean = false;
  disabledOptionSelectPredefinedList: boolean = true;
  disabledOptionSelectShapefile: boolean = true;

  constructor(private modalService: NgbModal,
    private keycloakService: KeycloakService,
    private waterBodyAPIsService: WaterBodyAPIsService, 
    private requestAPIsService: RequestAPIsService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private userAPIsService: UserAPIsService) { }


  onMethodSelectionChange(method: string) {
    console.log(`onMethodSelectionChange ${method}`)
    this.myWaterBodyGeometryGetDTOSelected = undefined;
    this.resetWaterBodyMethodSelection()
    this.resetToTheInitialSateDisabledAndSelected()
    if (method == 'predefined-list') {
      this.selectedSelectOneOfThePreviouslyRequested = false;
      this.selectedFromAPredefinedList = true;
      this.selectedUploadAShapeFile = false;
      this.disabledOptionSelectPredefinedList = false;
      return;
    }

    if (method == 'shapefile') {
    
      this.selectedSelectOneOfThePreviouslyRequested = false;
      this.selectedFromAPredefinedList = false;
      this.selectedUploadAShapeFile = true;
      this.disabledOptionSelectShapefile = false;
      return;
    }

    if (method == 'previously-requested') {
      this.selectedSelectOneOfThePreviouslyRequested = true;
      this.selectedFromAPredefinedList = false;
      this.selectedUploadAShapeFile = false;
      this.disabledOptionSelectOneOfThePreviouslyRequested = false;
      
      return;
    }
  }

  resetWaterBodyMethodSelection() {
    this.selectedSelectOneOfThePreviouslyRequested = false;
    this.selectedFromAPredefinedList = false;
    this.selectedUploadAShapeFile = false;
    this.disabledOptionSelectOneOfThePreviouslyRequested = true;
    this.disabledOptionSelectPredefinedList = true;
    this.disabledOptionSelectShapefile = true;

  }


  resetSelectedAndDisabledCheckbox() {
    console.log("*****RESET*****")
    this.resetSelectedCheckbox()
    this.resetDisabledCheckbox()    
    console.log(this.selectedWaterQualityTurOnDemand)
    console.log(this.disabledWaterQualityTurOnDemand)
    

  }

  resetToTheInitialSateDisabledAndSelected() {
    this.selectedLandWaterTransitionZoneTwoDates= false;
    this.selectedLandWaterTransitionZoneTwoDatesContinuous= false;
    this.selectedLandWaterTransitionZoneTwoDatesOnDemand= false

    this.selectedLandWaterTransitionZoneHydroperiod= false;
    this.selectedLandWaterTransitionZoneHydroperiodContinuous= false;
    this.selectedLandWaterTransitionZoneHydroperiodOnDemand= false;

    this.selectedExtremeEventOilSpillDetection= false;
    this.selectedExtremeEventOilSpillDetectionContinuous= false;
    this.selectedExtremeEventOilSpillDetectionOnDemand= false;

    this.selectedExtremeEventMuddyWaterDetection= false;
    this.selectedExtremeEventMuddyWaterDetectionContinuous= false;
    this.selectedExtremeEventMuddyWaterDetectionOnDemand= false;

    this.selectedExtremeEventFloodDetection= false;
    this.selectedExtremeEventFloodDetectionContinuous= false;
    this.selectedExtremeEventFloodDetectionOnDemand= false;

    this.selectedWaterQualitySdd= false;
    this.selectedWaterQualitySddContinuous= false;
    this.selectedWaterQualitySddOnDemand= false;

    this.selectedWaterQualitySst= false;
    this.selectedWaterQualitySstContinuous= false;
    this.selectedWaterQualitySstOnDemand= false;

    this.selectedWaterQualityTur= false;
    this.selectedWaterQualityTurContinuous= false;
    this.selectedWaterQualityTurOnDemand= false;

    this.selectedWaterQualityCdm= false;
    this.selectedWaterQualityCdmContinuous= false;
    this.selectedWaterQualityCdmOnDemand= false;

    this.selectedWaterQualityChl= false;
    this.selectedWaterQualityChlContinuous= false;
    this.selectedWaterQualityChlOnDemand= false;

    this.selectedBloomEventDetectionHab= false;
    this.selectedBloomEventDetectionHabContinuous= false;
    this.selectedBloomEventDetectionHabOnDemand= false;

    this.selectedSelectOneOfThePreviouslyRequested= true;
    this.selectedFromAPredefinedList= false;
    this.selectedUploadAShapeFile= false;
    //DISABLED VARIABLEs Service
    this.disabledLandWaterTransitionZoneTwoDates= false;
    this.disabledLandWaterTransitionZoneTwoDatesContinuous= false;
    this.disabledLandWaterTransitionZoneTwoDatesOnDemand= false

    this.disabledLandWaterTransitionZoneHydroperiod= false;
    this.disabledLandWaterTransitionZoneHydroperiodContinuous= false;
    this.disabledLandWaterTransitionZoneHydroperiodOnDemand= false;

    this.disabledExtremeEventOilSpillDetection= true;
    this.disabledExtremeEventOilSpillDetectionContinuous= false;
    this.disabledExtremeEventOilSpillDetectionOnDemand= false;

    this.disabledExtremeEventMuddyWaterDetection= true;
    this.disabledExtremeEventMuddyWaterDetectionContinuous= false;
    this.disabledExtremeEventMuddyWaterDetectionOnDemand= false;

    this.disabledExtremeEventFloodDetection= true;
    this.disabledExtremeEventFloodDetectionContinuous= false;
    this.disabledExtremeEventFloodDetectionOnDemand= false;

    this.disabledWaterQualitySdd= true;
    this.disabledWaterQualitySddContinuous= false;
    this.disabledWaterQualitySddOnDemand= false;

    this.disabledWaterQualitySst= true;
    this.disabledWaterQualitySstContinuous= false;
    this.disabledWaterQualitySstOnDemand= false;

    this.disabledWaterQualityTur= true;
    this.disabledWaterQualityTurContinuous= true;
    this.disabledWaterQualityTurOnDemand= false;

    this.disabledWaterQualityCdm= true;
    this.disabledWaterQualityCdmContinuous= false;
    this.disabledWaterQualityCdmOnDemand= false;

    this.disabledWaterQualityChl= true;
    this.disabledWaterQualityChlContinuous= false;
    this.disabledWaterQualityChlOnDemand= false;

    this.disabledBloomEventDetectionHab= true;
    this.disabledBloomEventDetectionHabContinuous= false;
    this.disabledBloomEventDetectionHabOnDemand= false;
  }

  resetSelectedCheckbox() {
    this.selectedLandWaterTransitionZoneTwoDates = false;
    this.selectedLandWaterTransitionZoneTwoDatesContinuous = false;
    this.selectedLandWaterTransitionZoneTwoDatesOnDemand = false

    this.selectedLandWaterTransitionZoneHydroperiod = false;
    this.selectedLandWaterTransitionZoneHydroperiodContinuous = false;
    this.selectedLandWaterTransitionZoneHydroperiodOnDemand = false;

    this.selectedExtremeEventOilSpillDetection = false;
    this.selectedExtremeEventOilSpillDetectionContinuous = false;
    this.selectedExtremeEventOilSpillDetectionOnDemand = false;

    this.selectedExtremeEventMuddyWaterDetection = false;
    this.selectedExtremeEventMuddyWaterDetectionContinuous = false;
    this.selectedExtremeEventMuddyWaterDetectionOnDemand = false;

    this.selectedExtremeEventFloodDetection = false;
    this.selectedExtremeEventFloodDetectionContinuous = false;
    this.selectedExtremeEventFloodDetectionOnDemand = false;

    this.selectedWaterQualitySdd = false;
    this.selectedWaterQualitySddContinuous = false;
    this.selectedWaterQualitySddOnDemand = false;

    this.selectedWaterQualitySst = false;
    this.selectedWaterQualitySstContinuous = false;
    this.selectedWaterQualitySstOnDemand = false;

    this.selectedWaterQualityTur = false;
    this.selectedWaterQualityTurContinuous = false;
    this.selectedWaterQualityTurOnDemand = false;    
    

    this.selectedWaterQualityCdm = false;
    this.selectedWaterQualityCdmContinuous = false;
    this.selectedWaterQualityCdmOnDemand = false;

    this.selectedWaterQualityChl = false;
    this.selectedWaterQualityChlContinuous = false;
    this.selectedWaterQualityChlOnDemand = false;

    this.selectedBloomEventDetectionHab = false;
    this.selectedBloomEventDetectionHabContinuous = false;
    this.selectedBloomEventDetectionHabOnDemand = false;
    
  }

  resetDisabledCheckbox() {
    this.disabledLandWaterTransitionZoneTwoDates = false;
    this.disabledLandWaterTransitionZoneTwoDatesContinuous = false;
    this.disabledLandWaterTransitionZoneTwoDatesOnDemand = false

    this.disabledLandWaterTransitionZoneHydroperiod = false;
    this.disabledLandWaterTransitionZoneHydroperiodContinuous = false;
    this.disabledLandWaterTransitionZoneHydroperiodOnDemand = false;

    this.disabledExtremeEventOilSpillDetection = false;
    this.disabledExtremeEventOilSpillDetectionContinuous = false;
    this.disabledExtremeEventOilSpillDetectionOnDemand = false;

    this.disabledExtremeEventMuddyWaterDetection = false;
    this.disabledExtremeEventMuddyWaterDetectionContinuous = false;
    this.disabledExtremeEventMuddyWaterDetectionOnDemand = false;

    this.disabledExtremeEventFloodDetection = false;
    this.disabledExtremeEventFloodDetectionContinuous = false;
    this.disabledExtremeEventFloodDetectionOnDemand = false;

    this.disabledWaterQualitySdd = false;
    this.disabledWaterQualitySddContinuous = false;
    this.disabledWaterQualitySddOnDemand = false;

    this.disabledWaterQualitySst = false;
    this.disabledWaterQualitySstContinuous = false;
    this.disabledWaterQualitySstOnDemand = false;

    this.disabledWaterQualityTur = false;
    this.disabledWaterQualityTurContinuous = false;
    this.disabledWaterQualityTurOnDemand = false;

    this.disabledWaterQualityCdm = false;
    this.disabledWaterQualityCdmContinuous = false;
    this.disabledWaterQualityCdmOnDemand = false;

    this.disabledWaterQualityChl = false;
    this.disabledWaterQualityChlContinuous = false;
    this.disabledWaterQualityChlOnDemand = false;

    this.disabledBloomEventDetectionHab = false;
    this.disabledBloomEventDetectionHabContinuous = false;
    this.disabledBloomEventDetectionHabOnDemand = false;
    
  }
  
  ngOnInit(): void {
    this.today = new Date;
    this.keycloakService.getToken().then(token => {
      this.token = token;
      this.activatedRoute.params.subscribe(params => {
        let waterBodyLocalIdPreselectedWaterBody = params['waterBodyLocalId']
        if (waterBodyLocalIdPreselectedWaterBody != undefined) { 
          this.waterBodyAPIsService.getWaterBodyGeometryByUserApiV1WaterBodyByUserGeometryGet(token).subscribe(data => {
            this.myWaterBodyGeometryGetDTOs = data;
            this.finishedLoadWaterBodies = true;
            var preselectedWaterBody = this.myWaterBodyGeometryGetDTOs.filter(obj => {
              return obj.localId === waterBodyLocalIdPreselectedWaterBody
            })[0]
            this.myWaterBodyGeometryGetDTOSelected = preselectedWaterBody
            this.onWaterBodyShapeSchemaListSelect(undefined)

          }, error => {
            console.error(error)
            console.log(error)
            alert("Error")
          })
          
         } else {
          this.waterBodyAPIsService.getWaterBodyGeometryByUserApiV1WaterBodyByUserGeometryGet(token).subscribe(data => {
            this.myWaterBodyGeometryGetDTOs = data;
            this.finishedLoadWaterBodies = true;
          
          }, error => {
            console.error(error)
            alert("Error")
          })
         } 
      })
    })      
  }

  ngAfterViewInit(): void {
    this.initMap()
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [ 51.90683463077498, 20.319029203944183 ],
      zoom: 4
    });
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map);
    this.map.attributionControl.setPrefix(false);
  }

  async onSelectWaterBodyShapeFile(event:any) {
    // console.log("onSelectWaterBodyShapeFile")

    // Clean Services DTOs
    this.insertRequestedServiceDTOs = []
    this.resetSelectedAndDisabledCheckbox()

    let getDom = (xml: any) => (new DOMParser()).parseFromString(xml, "text/xml")
    let getExtension = (fileName: any) => fileName.split(".").pop()

    let getKmlDom = (kmzFile: any) => {
        var zip = new jsZip()
        return zip.loadAsync(kmzFile)
            .then((zip: any) => {
                let kmlDom: any = null
                zip.forEach((relPath: any, file: { async: (arg0: string) => Promise<any>; }) => {
                    if (getExtension(relPath) === "kml" && kmlDom === null) {
                        kmlDom = file.async("string").then(getDom)
                    }
                })
                return kmlDom || Promise.reject("No kml file found")
            });
    }

    let setGeoJsonOnMap = (geojson: any) => {
      this.selectedWaterBodyShapeFileArrayGeoJSON = (geojson as any).features
      for (let selectedWaterBodyShapeFileGeoJSON of this.selectedWaterBodyShapeFileArrayGeoJSON) {
       console.log(selectedWaterBodyShapeFileGeoJSON.geometry)
       this.insertRequestedServiceDTOs.push({'geometry':  selectedWaterBodyShapeFileGeoJSON.geometry, 'services': []})
       this.selectedWaterBodyShapeFileArrayGeoJSONLayerGroup = L.geoJSON(selectedWaterBodyShapeFileGeoJSON, {
          onEachFeature: onEachFeature,
          style: {color: "red", fillOpacity: .0}
       })
       function onEachFeature(feature:any, layer:any) {
         layer.on({
           click: (e: any) => {
             layer.setStyle({fillColor :'red', fillOpacity: .75}) 
           }
       })};
       this.itemsOnMap = new L.FeatureGroup();
       this.itemsOnMap.addLayer(this.selectedWaterBodyShapeFileArrayGeoJSONLayerGroup)
       this.map.addLayer(this.itemsOnMap)
     }

     let geoJSON = L.geoJSON(this.selectedWaterBodyShapeFileArrayGeoJSON as any, {});
     this.map.fitBounds(geoJSON.getBounds());
     //  this.changeMapCenter((geojson as any).features[0].geometry.coordinates[0][0][0], (geojson as any).features[0].geometry.coordinates[0][0][1])   
    }

    if(this.itemsOnMap!=undefined) this.map.removeLayer(this.itemsOnMap)
    // let file = event.target.files[0]
    let geojson: any = null
    let file = event.target.files[0]
    if (event.target.files && file && ['zip','rar','7zip'].includes(file.name.split('.').pop().toLowerCase())) {
      let fr = new FileReader();
      fr.onload = (e => { 
        let result = fr.result
        shp(result as shp.ShpJSBuffer).then(gj => { 
          setGeoJsonOnMap(gj);
        }, error => {
            alert("An error orccour. Maybe the loaded file is not a Shapefile.zip")
        });
      });
      fr.readAsArrayBuffer(file);
    } else {
      if (event.target.files && file && file.name.split('.').pop().toLowerCase() == 'kmz') {
        geojson = getKmlDom(file).then((kmlDom: any) => {
          let geoJsonObject = toGeoJSON.kml(kmlDom)
          console.log(geoJsonObject)
          setGeoJsonOnMap(geoJsonObject);
        });
      } else if (event.target.files && file && file.name.split('.').pop().toLowerCase() == 'kml') {
        let reader = new FileReader();
        reader.onload = (e => {
            let readXml = reader.result;
            let kmlDom = getDom(readXml)
            geojson = toGeoJSON.kml(kmlDom)
            setGeoJsonOnMap(geojson);
        });
        reader.readAsText(file);
      } else {
        alert("The file is not supported! Only KML, KMZ and Shapefiles included in ZIP, RAR and 7ZIP formats are allowed.")
      }      
    }   
  }

  changeMapCenter(lat: number, lon: number) {
    console.log(lat, lon)
    this.map.panTo(new L.LatLng(lon, lat));
    this.map.setZoom(14)
  }

  onWaterBodyShapeSchemaListSelectSelectedAndSiabledCheckboxContinuousMonitoring(localId: string) {
    this.resetSelectedAndDisabledCheckbox()
    console.log(`onWaterBodyShapeSchemaListSelectSelectedAndSiabledCheckboxContinuousMonitoring for ${localId}`)
    this.keycloakService.getToken().then(token => {
      this.userAPIsService.getMyRequestedServiceByWaterBodyLocalIdApiV1UserServicesLocalIdGet(localId, token).subscribe(data => {
        if (data == undefined) return;
        var requestedServiceProductTypeCompleteGetDTOs: RequestedServiceProductTypeCompleteGetDTO[] = data;
        for (let requestedServiceProductTypeCompleteGetDTO  of requestedServiceProductTypeCompleteGetDTOs) {
          console.log(requestedServiceProductTypeCompleteGetDTO)
          var waterQualityTurContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'waterquality-tur' && e.typeRequest == 'CONTINUOUS'})
          var waterQualityCdmContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'waterquality-cdm' && e.typeRequest == 'CONTINUOUS'})
          var waterQualitySstContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'waterquality-sst' && e.typeRequest == 'CONTINUOUS'})
          var waterQualitySddContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'waterquality-sdd' && e.typeRequest == 'CONTINUOUS'})
          var waterQualityChlContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'waterquality-chl' && e.typeRequest == 'CONTINUOUS'})

          var extremeEventOilSpillContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'extremeevent-oilspill' && e.typeRequest == 'CONTINUOUS'})
          var extremeEventFloodContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'extremeevent-flood' && e.typeRequest == 'CONTINUOUS'})
          var extremeEventMuddyWaterContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'extremeevent-muddywater' && e.typeRequest == 'CONTINUOUS'})

          var bloomeventHabContinuous = requestedServiceProductTypeCompleteGetDTO.requestedServiceProductType?.filter(function(e){return e.productType?.serviceName == 'bloomevent-hab' && e.typeRequest == 'CONTINUOUS'})

          if (waterQualityTurContinuous && waterQualityTurContinuous.length>0) {
            this.selectedWaterQualityTurContinuous = true;
            this.disabledWaterQualityTurContinuous = true;
          }

          if (waterQualityCdmContinuous && waterQualityCdmContinuous.length>0) {
            this.selectedWaterQualityCdmContinuous = true;
            this.disabledWaterQualityCdmContinuous = true;
          }

          if (waterQualitySstContinuous && waterQualitySstContinuous.length>0) {
            this.selectedWaterQualitySstContinuous = true;
            this.disabledWaterQualitySstContinuous = true;
          }

          if (waterQualitySddContinuous && waterQualitySddContinuous.length>0) {
            this.selectedWaterQualitySddContinuous = true;
            this.disabledWaterQualitySddContinuous = true;
          }

          if (waterQualityChlContinuous && waterQualityChlContinuous.length>0) {
            this.selectedWaterQualityChlContinuous = true;
            this.disabledWaterQualityChlContinuous = true;
          }

          if (extremeEventFloodContinuous && extremeEventFloodContinuous.length>0) {
            this.selectedExtremeEventFloodDetectionContinuous = true;
            this.disabledExtremeEventFloodDetectionContinuous = true;
          }

          if (extremeEventOilSpillContinuous && extremeEventOilSpillContinuous.length>0) {
            this.selectedExtremeEventOilSpillDetectionContinuous = true;
            this.disabledExtremeEventOilSpillDetectionContinuous = true;
          }

          if (extremeEventMuddyWaterContinuous && extremeEventMuddyWaterContinuous.length>0) {
            this.selectedExtremeEventMuddyWaterDetectionContinuous = true;
            this.disabledExtremeEventMuddyWaterDetectionContinuous = true;
          }

          if (bloomeventHabContinuous && bloomeventHabContinuous.length>0) {
            this.selectedBloomEventDetectionHabContinuous = true;
            this.disabledBloomEventDetectionHabContinuous = true;
          }
 
        }
      })
    })

  }
 

  onWaterBodyShapeSchemaListSelect(event: any) {
    
    console.log("onWaterBodyShapeSchemaListSelect")
    this.onWaterBodyShapeSchemaListSelectSelectedAndSiabledCheckboxContinuousMonitoring(this.myWaterBodyGeometryGetDTOSelected?.localId as string)
    this.onWaterBodyShapeSchemaListSelectSetupMap();
    this.changeMapCenter(this.myWaterBodyGeometryGetDTOSelected?.lat as number, this.myWaterBodyGeometryGetDTOSelected?.longt as number)
    this.insertRequestedServiceDTOs = [{'localId':  this.myWaterBodyGeometryGetDTOSelected?.localId, 'services': []}]
  }

  onWaterBodyShapeSchemaListSelectSetupMap() {
    console.log("onWaterBodyShapeSchemaListSelectSetupMap")
    if (this.selectedWaterBodyShapeFileArrayGeoJSONLayerGroup) {
      this.map.removeLayer(this.selectedWaterBodyShapeFileArrayGeoJSONLayerGroup);
    }
    this.changeMapCenter(this.myWaterBodyGeometryGetDTOSelected?.lat as number, this.myWaterBodyGeometryGetDTOSelected?.longt as number)
    console.log(this.myWaterBodyGeometryGetDTOSelected?.location)
    this.selectedWaterBodyShapeFileArrayGeoJSONLayerGroup = L.geoJSON(this.myWaterBodyGeometryGetDTOSelected?.location as any)
    console.log(this.selectedWaterBodyShapeFileArrayGeoJSONLayerGroup)
    this.selectedWaterBodyShapeFileArrayGeoJSONLayerGroup.addTo(this.map)          

  }

  public onServiceSeleted(serivce: string, event:any) {
    console.log(`New service selected for ${serivce}. Is ${event.checked}`);
    if (event.checked) {
     
      console.error("Non deve entrare qui all'inizio")

      let mode = undefined;
      if (serivce.includes("landwatertransitionzone")) {
        mode = "SENT2"

      }

      //let requestedServiceProductTypeItemDTO: RequestedServiceProductTypeItemDTO = {serviceName: serivce, monitoringType: CEnumTypeRequest.ONDEMAND, mode: mode};
      let requestedServiceProductTypeItemDTO: RequestedServiceProductTypeItemDTO = {serviceName: serivce, monitoringType: CEnumTypeRequest.CONTINUOUS, mode: mode};

      console.log(requestedServiceProductTypeItemDTO)
      
      var arrayLength = this.insertRequestedServiceDTOs.length;
      for (var i = 0; i < arrayLength; i++) {
        this.insertRequestedServiceDTOs[i].services!.push(requestedServiceProductTypeItemDTO)
      
      }
      
    } else {
      var arrayLength = this.insertRequestedServiceDTOs.length;
      for (var i = 0; i < arrayLength; i++) {
        this.insertRequestedServiceDTOs[0] = { ...this.insertRequestedServiceDTOs[0], services: this.insertRequestedServiceDTOs[0].services?.filter(item => item.serviceName !== serivce) }
      }
      

    }


  }

  public onDateSelect(typeDate: string, service: string, date: any, event: any) {
    console.log(`${typeDate} selected of value ${date} for the service ${service}.`);
    let index = this.insertRequestedServiceDTOs[0].services?.map(val => val.serviceName).indexOf(service)

    if (index == undefined) {return}

    if (typeDate.includes("start")) {
      console.log(typeDate)
      var arrayLength = this.insertRequestedServiceDTOs.length;
      for (var i = 0; i < arrayLength; i++) {
        this.insertRequestedServiceDTOs[i].services![index].startDate = date
      }
      
     
      
    } else {
      var arrayLength = this.insertRequestedServiceDTOs.length;
      for (var i = 0; i < arrayLength; i++) {
        this.insertRequestedServiceDTOs[i].services![index].endDate = date
      }
      
    }
    
    
  }


  public onModeExecutionSelect(typeMode: string, service: string) {
    console.log(`${typeMode} selected for the service ${service}.`);
    let index = this.insertRequestedServiceDTOs[0].services?.map(val => val.serviceName).indexOf(service)

    if (index == undefined) {return}

    var arrayLength = this.insertRequestedServiceDTOs.length;
      for (var i = 0; i < arrayLength; i++) {
        this.insertRequestedServiceDTOs[i].services![index].mode = typeMode  
      
      }
    
    
    
  }

  public onTypeMonitoringSelect(service: string, type: string, event:any) {
    console.log(`Type of Monitoring ${type} selected for the service ${service}. Is ${event.checked}`);
    
    let enumType = CEnumTypeRequest.CONTINUOUS;
    if (type=='on-demand') {
      enumType = CEnumTypeRequest.ONDEMAND;
    }

    for (var i = 0; i < this.insertRequestedServiceDTOs.length; i++) {
      if (this.insertRequestedServiceDTOs[i].services == undefined) continue;
      for (var j = 0; j < this.insertRequestedServiceDTOs[i].services!.length; j++) {
        if (this.insertRequestedServiceDTOs[i].services![j].serviceName ==service) {
          this.insertRequestedServiceDTOs[i].services![j].monitoringType =enumType;
        }
      }
      
    }

  }

  public onClickSubmitRequest() {

    if (!this.validateInsertRequestedServiceDTOs(this.insertRequestedServiceDTOs)) {
      return
    }
    
    this.keycloakService.updateToken().then(token => {
      this.keycloakService.getToken().then(token => {
        this.token = token;
        console.log(this.token)
        console.log(this.insertRequestedServiceDTOs)
        this.requestAPIsService.insertNewRequestApiV1RequestPost(this.insertRequestedServiceDTOs, token).subscribe(data => {
          this.openInfoModal("Success!", "Request submitted.")
          this.router.navigateByUrl("my-requested-service")

        }, error => {
          this.openInfoModal("Error!", "An error occurred, please try again later.")
          //this.router.navigateByUrl('home')
        })
      })
    })
  }

  openInfoModal(title: string | undefined, message: string | undefined) {

    if (message == undefined) {
      alert("Undefined...")
      return;
    }

    const modalRef = this.modalService.open(InfoModalComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  validateInsertRequestedServiceDTOs(insertRequestedServiceDTOs: InsertRequestedServiceDTO[]) {
    if (insertRequestedServiceDTOs.length == 0) {
      this.openInfoModal("Warning", "Select a water body or update a shapefile before submit the request")
      return false;
    }
    for (let insertRequestedServiceDTO of insertRequestedServiceDTOs) {
      console.log(insertRequestedServiceDTO)
      if (insertRequestedServiceDTO.geometry == undefined && insertRequestedServiceDTO.localId == undefined) {
        this.openInfoModal("Warning", "Select a water body or update a shapefile before submit the request")
        return false;
      }
      for (let requestedServiceProductTypeItemDTO of insertRequestedServiceDTO.services!) {
        if (requestedServiceProductTypeItemDTO.monitoringType == 'ON-DEMAND' && (requestedServiceProductTypeItemDTO.startDate == undefined || requestedServiceProductTypeItemDTO.endDate == undefined)) {
          this.openInfoModal("Warning", "If you select the ON-DEMAND monitoring type, then you should indicate both the Start Date and the End Date")
          return false;
        }
      }
    }
    return true;
  }


}
