<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            Area of Interest (AOI): <b>{{requestedServiceProductTypeCompleteGetDTO?.waterBody?.nameText}}</b><br>
            <small>Date of the request: {{requestedServiceProductTypeCompleteGetDTO?.requestedServiceDate| date:'d MMMM yyyy, h:mm a'}}</small>
        </h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
    </div>

    <div  class="modal-body">
        <div id="map">
            <div *ngIf="requestedServiceProductTypeCompleteGetDTO == undefined" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div *ngIf="requestedServiceProductTypeCompleteGetDTO != undefined">
            <h4>List of services requested for the AOI</h4>

            <div class="table-wrapper">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Service Name</th>
                        <th scope="col">Type of Request</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Accepted</th>
                        <th scope="col">Data access</th>
                    </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let requestedServiceProductType of requestedServiceProductTypeCompleteGetDTO?.requestedServiceProductType; index as index">
                        <tr>
                            <th scope="row">{{index+1}}</th>
                            <td>{{requestedServiceProductType?.productType?.humanReadableServiceName}}</td>
                            <td>{{requestedServiceProductType.typeRequest}}</td>
                            <td>{{requestedServiceProductType.startDate | date: 'd/M/y' }}</td>
                            <td>{{requestedServiceProductType.endDate | date: 'd/M/y'}}</td>
                            <td class="text-center" *ngIf="!elaborated"><b>-</b></td>
                            <td class="text-center no" *ngIf="!requestedServiceProductType.accepted && elaborated"><b>No</b></td>
                            <td class="text-center ok" *ngIf="requestedServiceProductType.accepted && elaborated"><b>Yes</b></td>
                            <td class="text-center">
                                <a href="{{buildFileServerURL(requestedServiceProductType?.productType?.serviceName!, requestedServiceProductTypeCompleteGetDTO?.waterBody?.localId!)}}" target="_blank" title="Download data" class="btn btn-primary subservice-actions" [ngClass]="{'disabled' : !requestedServiceProductType.accepted}">
                                    <fa-icon icon="folder-open"></fa-icon>
                                </a>
                                <!-- <a href="https://www.ogc3.grumets.cat/wqems/" target="_blank" title="View data on the Map and Data Navigator" [ngClass]="{'disabled' : !requestedServiceProductType.accepted}" class="btn btn-primary subservice-actions">
                                    <fa-icon icon="map"></fa-icon>
                                </a> -->
                            </td>
                        </tr>
                        </ng-container>      
                    </tbody>
                </table>
            </div>
            
            <div class="row justify-content-center">
                <!-- <div class="col-12 col-md-6">
                    <button type="button" class="btn btn-primary pt-2 pb-2 w-100 mt-4 mb-4" data-bs-dismiss="modal"
                            (click)="closeModal()">
                        <fa-icon icon="times" class="left"></fa-icon> Close
                    </button>
                </div> -->
                <div class="col-4">
                    <button type="button" class="btn btn-primary pt-2 pb-2 w-100 mt-4 mb-4" data-bs-dismiss="modal"
                            (click)="onClickMakeNewRequestForThisWaterBody()"><fa-icon class="right"></fa-icon> Make a new request for this AOI
                    </button>
                </div>
            </div>
        </div>
    </div>
    </div>
