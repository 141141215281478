import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserAPIsService } from 'src/app/services/restapiservices';
import { KeycloakService } from 'keycloak-angular';
import { ServicesWaterBodyRolesGetDTO, ServicesWaterBodyRolesGetProductTypeDTO } from 'src/app/services/restapiservices';
import { InsertRequestedReportDTO } from 'src/app/services/restapiservices';
import { RequestedReportAPIsService } from 'src/app/services/restapiservices';
import * as L from 'leaflet';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from 'src/app/components/modals/info-modal/info-modal.component';
import { Router } from '@angular/router';
import { InfoModalsService } from 'src/app/services/interface/info-modals.service';
import { KeycloakProfile } from 'keycloak-js';
@Component({
  selector: 'app-request-report',
  templateUrl: './request-report.component.html',
  styleUrls: ['./request-report.component.scss']
})
export class RequestReportComponent implements OnInit, AfterViewInit {

  constructor(public infoModalService: InfoModalsService,private router: Router, private userApiService: UserAPIsService, private keycloakService: KeycloakService, private requestedReportAPIsService: RequestedReportAPIsService) { }

  // servicesWaterBodyRolesGetDTOs: ServicesWaterBodyRolesGetDTO[] = []
  servicesWaterBodyRolesGetDTOs!: ServicesWaterBodyRolesGetDTO[];

  selectableServices: ServicesWaterBodyRolesGetProductTypeDTO[] = []
  tokenUser: string = "";
  private map: any;
  servicesWaterBodyRolesGetDTOSelectedWaterBody:ServicesWaterBodyRolesGetDTO | any;
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;


  insertRequestedReportDTO: InsertRequestedReportDTO = {localIdWaterBody:"", serviceNames:[], startDate:"", endDate:"", email: ""};

  public async ngOnInit() {
    this.keycloakService.getToken().then(token => {
      this.tokenUser = token;
      this.userApiService.getWaterBodiesServicesByRoleApiV1UserServicesWaterbodiesRoleGet(token).subscribe(data => {        
        this.servicesWaterBodyRolesGetDTOs = data;
        this.initMap()
      }, error => {
        console.error(error)
      })
    });

    this.isLoggedIn = await this.keycloakService.isLoggedIn();

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloakService.loadUserProfile();
    }
    console.log(this.userProfile);
  }

  ngAfterViewInit(): void {
    // this.initMap()
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [ 51.90683463077498, 20.319029203944183 ],
      zoom: 4
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
    this.map.attributionControl.setPrefix(false);
  }

  changeMapCenter(lat: number, lon: number) {
    this.map.panTo(new L.LatLng(lon, lat));
    this.map.setZoom(14)
  }


  onChangeWaterBodySelected(event: any) {
    this.changeMapCenter(this.servicesWaterBodyRolesGetDTOSelectedWaterBody?.waterBodyCoordinates[0] as number, this.servicesWaterBodyRolesGetDTOSelectedWaterBody?.waterBodyCoordinates[1] as number)
    console.log(this.servicesWaterBodyRolesGetDTOSelectedWaterBody)
    this.insertRequestedReportDTO.localIdWaterBody = this.servicesWaterBodyRolesGetDTOSelectedWaterBody?.waterBodyLocalId as string
    let waterBodyLocalId = this.insertRequestedReportDTO.localIdWaterBody
    let serviceNames: ServicesWaterBodyRolesGetProductTypeDTO[];
    serviceNames = this.servicesWaterBodyRolesGetDTOs.filter(
      function(data){ return data.waterBodyLocalId == waterBodyLocalId })[0].productTypes as ServicesWaterBodyRolesGetProductTypeDTO[];
    this.selectableServices =serviceNames

  }

  onClickConfirm() {
    console.log(this.insertRequestedReportDTO)
    if (this.insertRequestedReportDTO.serviceNames.length==0 || this.insertRequestedReportDTO.localIdWaterBody == null || this.insertRequestedReportDTO.startDate == null || this.insertRequestedReportDTO.endDate == null || this.insertRequestedReportDTO.email == null) {
      alert("Please, insert all data");
    } else {
      this.requestedReportAPIsService.insertNewRequestedReportApiV1RequestedReportPost(this.insertRequestedReportDTO, this.tokenUser).subscribe(data => {
        this.infoModalService.openInfoModal("Success!", "Request submitted. The report will be sent to the specified email shortly.")
        this.router.navigateByUrl("home")
      }, error => {
        this.infoModalService.openInfoModal("Error!", "An error occurred, please try again later.")
        this.router.navigateByUrl('home')
      })
    }
  }

  // insertAlertConfigDTO.nameSocialAlertTypes
  updateSelectedService(event: any) {
    if (event.target.checked) {
      if (this.insertRequestedReportDTO!.serviceNames!.indexOf(event.target.name) < 0) { 
        this.insertRequestedReportDTO!.serviceNames!.push(event.target.name);
      }
     } else {
        if (this.insertRequestedReportDTO!.serviceNames!.indexOf(event.target.name) > -1) 
        {
          this.insertRequestedReportDTO!.serviceNames!.splice(this.insertRequestedReportDTO!.serviceNames!.indexOf(event.target.name), 1);              
        }
    } 
    // console.log(this.insertRequestedReportDTO!.serviceNames!)
}


 
}
