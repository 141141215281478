/**
 * WQeMS: RestAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ServicesMeasureTypesWaterBodyRolesContinuousMonitoringGetMeasureTypeDTO { 
    name?: string;
    description?: string;
    minSuggested?: number;
    maxSuggested?: number;
    minPossible?: number;
    maxPossible?: number;
    stepValue?: number;
    unitOfMeasureAbbreviation?: string;
    humanReadableName?: string;
}