import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-copernicus-services',
  templateUrl: './about-copernicus-services.component.html',
  styleUrls: ['./about-copernicus-services.component.scss']
})
export class AboutCopernicusServicesComponent implements OnInit {

  buttonOpen:Array<boolean> = [false,false,false,false];

  constructor() { }

  ngOnInit(): void {
  }

  buttonOpenClick(id: number) {

    if(this.buttonOpen[id]) this.buttonOpen[id]=false;
    else this.buttonOpen[id]=true;

    //let y = document.getElementById(i).offsetTop + 40;
    /*let y = 0;
    if(i !== 0) {
      y = target['el'].offsetTop + 40;
    }
    this.content.scrollToPoint(0, y);*/
  }

}
