import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-crowdsourcing-mobile-app',
  templateUrl: './crowdsourcing-mobile-app.component.html',
  styleUrls: ['./crowdsourcing-mobile-app.component.scss'],
  providers: [NgbCarouselConfig]
})
export class CrowdsourcingMobileAppComponent implements OnInit {

  constructor(config: NgbCarouselConfig, public router: Router) {
    config.interval = 0;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.showNavigationIndicators = true;
  }

  images = [1, 2, 3, 4].map((n) => `../../assets/img/crowdsourcing_app_shot_${n}.jpg`);

  ngOnInit(): void {
  }

  onClickDownloadApp() {
    // window.location.href = 'https://socialmedia-server-m4d.iti.gr/WQeMS/WQeMS_crowdsourcing_app_v2.0.apk';
    return;
  }

}
