/**
 * WQeMS: RestAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Parameter } from './parameter';

export interface InsertProductDTO { 
    localId?: string;
    bbox?: Array<Array<number>>;
    dataFileName?: string;
    metadataFileName?: string;
    satellite?: string;
    serviceName?: string;
    startDate?: string;
    endDate?: string;
    pixelSize?: Array<number>;
    parameters?: Array<Parameter>;
    producedDate?: Date;
    crs?: string;
}