<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            Alert configuration for: <b>{{alertConfigCompleteGetDTO?.waterBody?.nameText}}</b>
        </h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
    </div>

    <div class="modal-body">
        <div id="map">        
            <div *ngIf="alertConfigCompleteGetDTO == undefined" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div *ngIf="alertConfigCompleteGetDTO != undefined">
            <h4>Rules</h4>

            <div class="table-wrapper">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Measure</th>
                            <th scope="col">Condition</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let alertConditions of alertConfigCompleteGetDTO?.alertConditions; index as index">
                            <tr>
                                <th scope="row">{{index+1}}</th>
                                <td>{{alertConditions?.measureType?.humanReadableName}}</td>
                                <td *ngIf='alertConditions.maxValue == undefined && alertConditions.minValue != undefined && alertConditions?.measureType?.unitOfMeasure?.abbreviation != "boolean"'>
                                    Greater than {{alertConditions.minValue}}
                                    {{alertConditions?.measureType?.unitOfMeasure?.abbreviation}}</td>
                                <td *ngIf='alertConditions.maxValue != undefined && alertConditions.minValue == undefined && alertConditions?.measureType?.unitOfMeasure?.abbreviation != "boolean"'>
                                    Less
                                    than {{alertConditions.maxValue}}</td>
                                <td *ngIf='alertConditions.maxValue != undefined && alertConditions.minValue != undefined && alertConditions?.measureType?.unitOfMeasure?.abbreviation != "boolean"'>
                                    Between {{alertConditions.maxValue}}
                                    {{alertConditions?.measureType?.unitOfMeasure?.abbreviation}} and
                                    {{alertConditions.minValue}}
                                    {{alertConditions?.measureType?.unitOfMeasure?.abbreviation}}
                                </td>
                                <!-- <td *ngIf='alertConditions.maxValue != undefined && alertConditions.minValue == undefined && alertConditions?.measureType?.unitOfMeasure?.abbreviation == "boolean"'> -->
                                <td *ngIf='alertConditions.minValue == 1 && alertConditions?.measureType?.unitOfMeasure?.abbreviation == "boolean"'>
                                    Occurence</td>
                                <!-- <td *ngIf='alertConditions.maxValue == undefined && alertConditions.minValue != undefined && alertConditions?.measureType?.unitOfMeasure?.abbreviation == "boolean"'> -->
                                <td *ngIf='alertConditions.minValue == 0 && alertConditions?.measureType?.unitOfMeasure?.abbreviation == "boolean"'>
                                    Not Occurrence</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <h4>Notification Methods</h4>

            <div class="table-wrapper">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Method</th>
                            <th scope="col">Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let notificationMethod of alertConfigCompleteGetDTO?.notificationMethod; index as index">
                            <tr>
                                <th scope="row">{{index+1}}</th>
                                <td>{{notificationMethod.type}}</td>
                                <td>{{notificationMethod.value}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="row">
                <div class="col text-center">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        (click)="onClickDeleteAlertConfig()">
                        <fa-icon icon="trash"></fa-icon> Delete
                    </button>
                </div>
                <!-- <div class="col-12 col-md-6">
                    <button type="button" class="btn btn-primary pt-2 pb-2 w-100 mt-4 mb-4" data-bs-dismiss="modal"
                        (click)="closeModal()">
                        <fa-icon icon="times" class="left"></fa-icon> Close
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</div>