import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-my-requested-services',
  templateUrl: './my-requested-services.component.html',
  styleUrls: ['./my-requested-services.component.scss']
})
export class MyRequestedServicesComponent implements OnInit {

  public keycloakProfile: KeycloakProfile = {};

  constructor(private keycloakService: KeycloakService) { }

  ngOnInit(): void {
    console.log("MyRequestedServicesComponent")
    this.keycloakService.getToken().then(token => {
      this.keycloakService.loadUserProfile().then(data => {
        this.keycloakProfile = data;
      })
    });
  }

}
